<style lang="scss">
  .card {
    margin: 0;
    text-transform: uppercase;
    $radius: 0;
    border-radius: $radius;
    color: #fff;
    background-color: #fff;
    //background-image: var(--image);
    background-position: center;
    background-size: cover;
    position: relative;
    display: grid;
    height: 100vh;
    margin-bottom: 2rem;
    grid-template-areas:
      '______'
      'title';
    grid-template-rows: 1fr auto 1fr;
    grid-template-columns: auto;
    align-items: flex-end;

    .title {
      word-break: break-all;
      grid-area: title;
      margin: 3rem;
      text-align: center;
    }
    .______ {
      grid-area: ______;
      min-height: 300px;
    }
  }
</style>

<script>
  import DivisoreOrizzontaleConIntestazione from ':components/divisore-orizzontale-con-intestazione.svelte'

  import { store_url_sgp1 } from ':stores/store_url_sgp1'

  import { Pagination } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/svelte'

  /**
   * @type {string}
   */
  export let titolo
  /**
   * @type {string}
   */
  export let didascalia
  /**
   * @type {number}
   */
  export let larghezza
  /**
   * @type {Array<import(':types').Offerta>}
   */
  export let offerte

  /**
   * @type {Record<string, import(':types').TagOfferta>}
   */
  const Tag = {}

  /**
   * @param {{offerte: Array<import(':types').Offerta>}} payload
   */
  function extract_tags_from_offerte({ offerte }) {
    /**
     * @type {Record<string, import(':types').TagOfferta>}
     */
    const tag = {}
    offerte.forEach(function pass(offerta) {
      return offerta.tags
        ? offerta.tags.itemTags.forEach(function pass(tag_local) {
            return (tag[tag_local.alias] = tag_local)
          })
        : false
    })
    /**
     * @type {Array<import(':types').TagOfferta>}
     */
    const ArrayTag = []
    Object.keys(tag).forEach(function pass(tag_local) {
      return ArrayTag.push(tag[tag_local])
    })
    return ArrayTag
  }

  /**
   * @param {{tag:import(':types').TagOfferta}} payload
   */
  function immagine({ tag }) {
    try {
      const immagine = JSON.parse(tag.images).image_intro || ''
      if ('' === immagine) return '/images/tematiche.webp'

      return immagine ? `${$store_url_sgp1}/${immagine}` : ''
    } catch (e) {
      console.warn(e)
      return ''
    }
  }

  /**
   * @param {{html:string}} payload
   */
  function converti_html_in_testo({ html }) {
    let tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }
</script>

{#if Object.keys(Tag).length > 0}
  <DivisoreOrizzontaleConIntestazione {titolo} {didascalia} />
  <div class="pt-4" />
  <Swiper
    modules={[Pagination]}
    pagination={{
      bulletActiveClass: 'swiper-pagination-bullet-active amber',
    }}
    width={larghezza}
    slidesPerView={1}
  >
    {#each extract_tags_from_offerte({ offerte }) as tag}
      <SwiperSlide>
        <div
          class="card m-1"
          style="background-image:url('{immagine({ tag })}');"
        >
          <div class="______" />
          <div class="title p-1">
            <h5 class="grid-x-center">
              <h1 class="mb-0">{tag.title || ''}</h1>
            </h5>

            <br />
            <div class="grid-x-center">
              {converti_html_in_testo({ html: tag.description || '' })}
            </div>
            <br />
            <br />
            <div class="grid-x-center">
              <button
                class="btn btn-ghost"
                on:click={function run() {
                  window.open(
                    `${$store_url_sgp1}/index.php/component/tags/tag/${tag.alias}`,
                  )
                }}
              >
                <span class="p-3">Scopri</span>
              </button>
            </div>
          </div>
        </div>
      </SwiperSlide>
    {/each}
  </Swiper>
{/if}
