<script>
  import MenuPrincipale from ':components/menu-principale.svelte'
  import Snackbar from ':components/snackbar.svelte'
  import Vista from ':components/vista.svelte'
  import { dayjs } from ':scripts/dayjs'
  import PaginaDashboard from ':pages/dashboard/index.svelte'
  import PaginaFirma from ':pages/firma/index.svelte'
  import PaginaHome from ':pages/home/index.svelte'
  import PaginaImpostazionePassword from ':pages/impostazione-password/index.svelte'
  import PaginaOnboard from ':pages/onboard/index.svelte'
  import PaginaPratica from ':pages/pratica/index.svelte'
  import PaginaPratiche from ':pages/pratiche/index.svelte'
  import PaginaProfilo from ':pages/profilo/index.svelte'
  import PaginaQuestionarioAccount from ':pages/questionario-account/index.svelte'
  import PaginaQuestionarioPratica from ':pages/questionario-pratica/index.svelte'
  import PaginaRegistrazione from ':pages/registrazione/index.svelte'
  import PaginaRichiestaEmailRecuperoPassword from ':pages/richiesta-email-recupero-password/index.svelte'
  import PaginaAutorizza from ':pages/autorizza/index.svelte'

  import { store_dominio_ok } from ':stores/store_dominio_ok'
  import { store_impostazioni_privacy_prese_di_vista } from ':stores/store_impostazioni_privacy_prese_di_vista'
  import { store_pathname } from ':stores/store_pathname'
  import { store_sessione_app } from ':stores/store_sessione_app'

  import { store_messaggio } from ':stores/store_messaggio'
  import { Route, Router } from 'svelte-routing'
  import { SvelteComponent } from 'svelte'

  /**
   * @type {false | import(':types').NomePagina}
   */
  let nome_pagine = false

  let visualizza_menu_principale = false

  $: {
    visualizza_menu_principale =
      $store_dominio_ok &&
      $store_sessione_app &&
      dayjs($store_sessione_app.Utente.DataScadenzaPassword).isAfter(
        Date.now(),
      ) &&
      $store_impostazioni_privacy_prese_di_vista
  }

  $: switch ($store_pathname) {
    case '/':
    case '/home':
      nome_pagine = 'HOME'
      break
    case '/dashboard':
      nome_pagine = 'DASHBOARD'
      break
    case '/pratiche':
      nome_pagine = 'PRATICHE'
      break
    default:
      nome_pagine = false
      break
  }

  window.addEventListener('beforeinstallprompt', function run(e) {
    e.preventDefault()
  })

  /**
   *
   * @param {{ componente: any }} payload
   * @returns {Promise<SvelteComponent>}
   */
  function page({ componente }) {
    return componente
  }
</script>

<Router>
  <!-- <MaterialApp style="display:grid"> -->
  <Vista>
    <Route path="/autorizza" let:location let:params>
      <!-- <LazyPage
          {location}
          {params}
          component={page(import(`./lib/pagine/autorizza/index.svelte`))}
        /> -->
      <PaginaAutorizza />
    </Route>
    <Route path="/firma/:numero_pratica/:id_allegato" let:location let:params>
      <!-- <LazyPage
          {location}
          {params}
          component={page(import(`./lib/pagine/firma/index.svelte`))}
        /> -->
      <PaginaFirma
        id_allegato={params.id_allegato}
        numero_pratica={params.numero_pratica}
      />
    </Route>
    <Route path="/questionario/pratica/:pratica_id" let:location let:params>
      <!-- <LazyPage
          {location}
          {params}
          component={page(import(`./lib/pagine/questionario-pratica/index.svelte`))}
        /> -->
      <PaginaQuestionarioPratica pratica_id={params.pratica_id} />
    </Route>
    <Route
      path="/impostazione-password/:access_token/:account_login_id"
      let:location
      let:params
    >
      <!-- <LazyPage
          {location}
          {params}
          component={page(import(`./lib/pagine/impostazione-password/index.svelte`))}
        /> -->
      <PaginaImpostazionePassword
        access_token={params.access_token}
        account_login_id={params.account_login_id}
      />
    </Route>
    <Route path="/richiesta-email-recupero-password" let:location let:params>
      <!-- <LazyPage
          {location}
          {params}
          component={page(
            import(`./lib/pagine/richiesta-email-recupero-password/index.svelte`)
          )}
        /> -->
      <PaginaRichiestaEmailRecuperoPassword />
    </Route>
    <Route path="/questionario" let:location let:params>
      <!-- <LazyPage
          {location}
          {params}
          component={page(import(`./lib/pagine/questionario-account/index.svelte`))}
        /> -->
      <PaginaQuestionarioAccount />
    </Route>
    <Route path="/pratica/:numero_pratica" let:location let:params>
      <!-- <LazyPage
          {location}
          {params}
          component={page(import(`./lib/pagine/pratica/index.svelte`))}
        /> -->
      <PaginaPratica numero_pratica={params.numero_pratica} />
    </Route>

    <Route path="/registrazione" let:location let:params>
      <!-- <LazyPage
          {location}
          {params}
          component={page(import(`./lib/pagine/registrazione/index.svelte`))}
        /> -->
      <PaginaRegistrazione />
    </Route>

    <Route path="/home" let:location let:params>
      <!-- <LazyPage
          {location}
          {params}
          component={page(import(`./lib/pagine/home/index.svelte`))}
        /> -->
      <PaginaHome />
    </Route>
    <Route path="/dashboard" let:location let:params>
      <!-- <LazyPage
          {location}
          {params}
          component={page(import(`./lib/pagine/dashboard/index.svelte`))}
        /> -->
      <PaginaDashboard />
    </Route>

    <Route path="/pratiche" let:location let:params>
      <!-- <LazyPage
          {location}
          {params}
          component={page(import(`./lib/pagine/pratiche/index.svelte`))}
        /> -->
      <PaginaPratiche />
    </Route>

    <Route path="/profilo" let:location let:params>
      <!-- <LazyPage
          {location}
          {params}
          component={page(import(`./lib/pagine/profilo/index.svelte`))}
        /> -->
      <PaginaProfilo />
    </Route>
    <Route path="/onboard/:cat_account_id" let:location let:params>
      <!-- <LazyPage
          {location}
          {params}
          component={page(import(`./lib/pagine/onboard/index.svelte`))}
        /> -->
      <PaginaOnboard cat_account_id={params.cat_account_id} />
    </Route>

    <Route path="*" let:location let:params>
      <!-- <LazyPage
          {location}
          {params}
          component={page(import(`./lib/pagine/home/index.svelte`))}
        /> -->
      <PaginaHome />
    </Route>
  </Vista>

  {#if $store_sessione_app && nome_pagine && visualizza_menu_principale}
    <MenuPrincipale {nome_pagine} sessione={$store_sessione_app} />
  {/if}
  <!-- </MaterialApp> -->

  {#if $store_messaggio}
    <Snackbar messaggio={$store_messaggio} />
  {/if}
</Router>
