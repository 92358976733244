<style lang="scss">
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 16px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
</style>

<script>
  import { fade } from 'svelte/transition'

  export let globale = false
</script>

{#if globale}
  <div
    transition:fade
    class="
      fixed
      left-0
      right-0
      top-0
      bottom-0
      bg-white
      z-40
    "
  />
  <div
    class="
      fixed
      left-0
      right-0
      top-0
      bottom-0
      grid
      justify-content
      justify-items-center
      content-center
    "
    style="z-index: 41"
  >
    <div class="lds-ellipsis">
      <div class="bg-primary-content" />
      <div class="bg-primary-content" />
      <div class="bg-primary-content" />
      <div class="bg-primary-content" />
    </div>
  </div>
{:else}
  <div class="lds-ellipsis">
    <div class="bg-primary-content" />
    <div class="bg-primary-content" />
    <div class="bg-primary-content" />
    <div class="bg-primary-content" />
  </div>
{/if}

<!-- <progress class="progress w-56" /> -->
