<style lang="scss">
  .not-found {
    display: grid;
    justify-items: center;
    align-items: center;
  }
</style>

<script>
  import DivisoreOrizzontaleConIntestazione from ':components/divisore-orizzontale-con-intestazione.svelte'
  import ComponentePartenza from ':components/partenza.svelte'

  import { store_ultime_partenze } from ':stores/store_ultime_partenze'

  import { scale } from 'svelte/transition'
  import { Pagination } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/svelte'
  import { pratiche } from ':scripts/pratiche'
  import Progresso from ':components/progresso.svelte'

  /**
   * @type {string}
   */
  export let titolo
  /**
   * @type {string}
   */
  export let didascalia
  /**
   * @type {number}
   */
  export let larghezza
  /**
   * @type {import(':types').Sessione}
   */
  export let sessione

  async function UltimePartenze() {
    if ($store_ultime_partenze.length > 0) {
      return $store_ultime_partenze
    }

    $store_ultime_partenze = await pratiche({
      sessione,
      filtro: {
        CognomeNomeCliente: '',
        DataCreazione: [false, false],
        DataRientro: [false, false],
        DataPartenza: [false, false],
        DataVendita: [false, false],
        Descrizione: '',
        Destinazione: '',
        NumeroAllegati: [0, 99999],
        NumeroPratica: '',
      },
      numero_righe_totali: 4,
      offset: 0,
      limite_righe: 4,
      recenti: true,
    })
    return $store_ultime_partenze
  }
</script>

<DivisoreOrizzontaleConIntestazione {titolo} {didascalia} />
<div class="pt-4" />
{#await UltimePartenze()}
  <div
    class="p-4 grid w-full h-full justify-center content-center text-center"
    transition:scale
  >
    <Progresso />
  </div>
{:then pratiche}
  {#if pratiche.length === 0}
    <div class="p-2">
      <div class="w-full bg-base-200 p-4 rounded-xl text-center">
        Nessun viaggio trovato.
      </div>
    </div>
  {:else}
    <Swiper
      modules={[Pagination]}
      pagination={{
        bulletActiveClass: 'swiper-pagination-bullet-active amber',
      }}
      spaceBetween={30}
      width={larghezza}
      slidesPerView={larghezza < 400 ? 1 : Math.floor(larghezza / 400)}
    >
      {#each pratiche as pratica}
        <SwiperSlide>
          <ComponentePartenza {pratica} />
        </SwiperSlide>
      {/each}
    </Swiper>
  {/if}
{:catch errore}
  <div class="card not-found pt-3 pb-3 amber lighten-5 grey-text text-darken-1">
    <div class="grid grid-x-center">
      {errore}
    </div>
  </div>
{/await}
