<script>
  import Accesso from ':components/accesso.svelte'
  import AppBarPrincipale from ':components/app-bar-principale.svelte'
  import DettaglioContrattoFirma from ':components/dettaglio-contratto-firma/dettaglio-contratto-firma.svelte'
  import Progresso from ':components/progresso.svelte'
  import Scrollabile from ':components/scrollabile.svelte'
  import { store_otp } from ':stores/store_otp'
  import { allegati, allegati_sviluppo } from ':scripts/allegati'
  import { dominio } from ':scripts/dominio'
  import { pratica } from ':scripts/pratica'

  /**
   * @type {string}
   */
  export let numero_pratica
  /**
   * @type {string}
   */
  export let id_allegato

  let visualizza_app_bar_principale = true
  // $: console.log({ visualizza_app_bar_principale })

  let errore_proposta_firma = ''
  let firma_proposta = false
  let email_firma_proposta = ''

  /**
   *
   * @param {{allegati:Array<import(':types').AllegatoPratica>}} payload
   */
  function allegati_richiesti({ allegati }) {
    return allegati
      .filter(function pass(allegato) {
        return allegato.AllegatoID === id_allegato
      })
      .filter(function pass(allegato) {
        return allegato.DataFirma === 'Da firmare'
      })
    // return Allegati.filter(Allegato => Allegato.AllegatoID === IDAllegato)
  }
</script>

{#await dominio()}
  <Progresso globale />
{:then dominio}
  <Accesso {dominio}>
    <svelte:fragment let:sessione>
      {#if visualizza_app_bar_principale}
        <AppBarPrincipale
          titolo="Firma contratto (pratica {numero_pratica})"
          NascondiMenu={$store_otp ? true : false}
        />
      {/if}
      <Scrollabile let:disabilita_pull_to_refresh let:abilita_pull_to_refresh>
        <div class="pt-8" />
        {#if errore_proposta_firma}
          <div class="p-4 w-full justify-center text-center">
            <span>{errore_proposta_firma}</span>
          </div>
        {:else if firma_proposta}
          <div class="p-4 w-full justify-center text-center">
            <span>
              <!---->
              Abbiamo inviato un messaggio a {email_firma_proposta} per autorizzare
              l'operazione.
              <!---->
            </span>
          </div>
        {:else}
          {#await pratica({ sessione, numero_pratica })}
            <div class="p-4 w-full justify-center text-center">
              <Progresso />
            </div>
          {:then [pratica_locale, errore_pratica]}
            {#if errore_pratica}
              <div class="grid-x-center mt-6">
                {errore_pratica.message}
              </div>
            {:else}
              {#await allegati_sviluppo( { allegati_promise: allegati( { sessione, pratica: pratica_locale }, ) }, )}
                <div class="p-4 w-full justify-center text-center">
                  <Progresso />
                </div>
              {:then allegati}
                {@const allegati_da_firmare = allegati_richiesti({
                  allegati: allegati,
                })}
                {#each allegati_da_firmare as allegato}
                  <DettaglioContrattoFirma
                    bind:visualizza_app_bar_principale
                    {dominio}
                    {sessione}
                    pratica={pratica_locale}
                    {allegato}
                    {disabilita_pull_to_refresh}
                    {abilita_pull_to_refresh}
                    otp_invio_proposta_firma={$store_otp}
                    on:proposta-firma={function run(e) {
                      email_firma_proposta = e.detail
                      firma_proposta = true
                      errore_proposta_firma = ''
                    }}
                    on:errore-proposta-firma={function run({ detail }) {
                      errore_proposta_firma = detail
                    }}
                  />
                {:else}
                  <div class="grid-x-center mt-6">
                    Non ci sono allegati da firmare
                  </div>
                {/each}
              {:catch errore}
                <div
                  class="grid grid-x-center grid-y-center full-height full-width"
                >
                  {errore}
                </div>
              {/await}
            {/if}
          {:catch errore}
            <div
              class="grid grid-x-center grid-y-center full-height full-width"
            >
              {errore}
            </div>
          {/await}
        {/if}
      </Scrollabile>
    </svelte:fragment>
  </Accesso>
{:catch errore}
  <div
    class="p-4 grid fixed h-full w-full justify-center content-center text-center"
  >
    {errore}
  </div>
{/await}
