<style lang="scss">
  .card {
    height: 400px;
    $radius: 10px;
    text-transform: uppercase;
    border-radius: $radius;
    color: #fff;
    background-color: #fff;
    //background-image: var(--image);
    background-position: center;
    background-size: cover;
    position: relative;
    display: grid;
    //max-width: 100px;
    margin-bottom: 2rem;
    grid-template-areas:
      'titolo     titolo      titolo'
      'prezzo     prezzo      prezzo'
      'idea       tipo_p      tipo_p'
      'destin     data_p      durata';
    grid-template-rows: 2fr 6fr 1fr 1.5fr;
    grid-template-columns: 1fr 1fr 1fr;

    .titolo {
      grid-area: titolo;
    }
    .prezzo {
      grid-area: prezzo;
    }
    //.open-idea{ grid-area: idea; }
    .tipo-prezzo {
      grid-area: tipo_p;
    }
    .destinazione {
      grid-area: destin;
    }
    .data-partenza {
      grid-area: data_p;
    }
    .durata {
      grid-area: durata;
    }

    .prezzo {
      font-size: 2rem;
      font-weight: bold;
      text-shadow: 0 0 1rem rgba(0, 0, 0, 0.9);
    }
    .titolo,
    .data-partenza,
    .destinazione,
    .durata {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .data-partenza,
    .destinazione,
    .durata {
      display: grid;
      align-content: center;
      justify-content: center;
      font-size: 0.8rem;
    }

    .tipo-prezzo {
      text-align: right;
      font-weight: bold;
    }

    .titolo {
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
    }
    .destinazione {
      border-bottom-left-radius: $radius;
    }
    .durata {
      border-bottom-right-radius: $radius;
    }
  }
</style>

<script>
  import { store_url_sgp1 } from ':stores/store_url_sgp1'
  import { valuta } from ':scripts/valuta'
  /**
   * @type {import(':types').Offerta}
   */
  export let offerta

  /**
   * @param {{tipo_prezzo:string | number}} payload
   */
  function formattazionePrezzo({ tipo_prezzo }) {
    let res = ''
    switch (tipo_prezzo) {
      case 1:
      case '1':
        res = 'Per persona'
        break
      case 2:
      case '2':
        res = 'Per ??? (2)'
        break
    }
    return res
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="card m-1 pointer"
  style="background-image:url('{offerta.imageso.image_intro}');"
  on:click={function run() {
    window.open(`${$store_url_sgp1}/${offerta.url}`)
  }}
>
  <div class="titolo p-1">{offerta.titolo}</div>
  <div class="prezzo pr-1 pl-1 grid-x-end grid-y-end">
    {valuta.euro({ valore: offerta?.prezzo ?? 0 })}
  </div>
  <!-- <div class="open-idea pl-1 pr-1">Open idea</div> -->
  <div class="tipo-prezzo pl-1 pr-1">
    {formattazionePrezzo({ tipo_prezzo: offerta?.tipo_prezzo ?? 0 })}
  </div>
  <div class="destinazione">
    {offerta.tipo_title}
  </div>
  <div class="data-partenza">
    <!-- {offerta.dataPartenza} -->
  </div>
  <div class="durata">
    <span>{offerta.giorni} GG / {offerta.notti} NTS</span>
  </div>
</div>
