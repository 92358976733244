import { Colori } from ':constants'
import { hex } from ':scripts/hex'

const COLORS_KEYS = Object.keys(Colori)
const REVERSABLE_ACCENT = [
  'deepPurple',
  'orange',
  'yellow',
  'amber',
  'lime',
  'lightBlue',
  'pink',
  'teal',
]
const EXCLUDED_COLORS = ['cyan', 'lime']
const EXCLUDED_VARIANTS = ['accent-4', 'accent-5']

/**
 * @param {{pratica:import(':types').Pratica}} payload
 */
export function applica_tema_pratica({ pratica }) {
  let nome_colore =
    COLORS_KEYS[Math.floor(Math.random() * Math.floor(COLORS_KEYS.length))]
  while (EXCLUDED_COLORS.includes(nome_colore)) {
    nome_colore =
      COLORS_KEYS[Math.floor(Math.random() * Math.floor(COLORS_KEYS.length))]
  }
  /**
   * @type {string}
   */
  // @ts-ignore
  const COLOR = Colori[nome_colore]
  /**
   * @type {Array<string>}
   */
  /** @ts-ignore */
  const VARIANTS_KEYS = Object.keys(COLOR)

  let bg_key =
    VARIANTS_KEYS[Math.floor(Math.random() * Math.floor(VARIANTS_KEYS.length))]
  while (EXCLUDED_VARIANTS.includes(bg_key))
    bg_key =
      VARIANTS_KEYS[
        Math.floor(Math.random() * Math.floor(VARIANTS_KEYS.length))
      ]

  let text_key = 'darken-4'

  switch (bg_key) {
    case 'lighten-1':
      text_key = 'darken-4'
      break
    case 'lighten-2':
      text_key = 'darken-3'
      break
    case 'lighten-3':
      text_key = 'darken-2'
      break
    case 'lighten-4':
      text_key = 'darken-1'
      break
    case 'darken-1':
      text_key = 'lighten-4'
      break
    case 'darken-2':
      text_key = 'lighten-3'
      break
    case 'darken-3':
      text_key = 'lighten-2'
      break
    case 'darken-4':
      text_key = 'lighten-1'
      break
    case 'accent-1':
      text_key = REVERSABLE_ACCENT.includes(nome_colore)
        ? 'lighten-4'
        : 'darken-4'
      break
    case 'accent-2':
      text_key = REVERSABLE_ACCENT.includes(nome_colore)
        ? 'lighten-3'
        : 'darken-3'
      break
    case 'accent-3':
      text_key = REVERSABLE_ACCENT.includes(nome_colore)
        ? 'lighten-2'
        : 'darken-2'
      break
    case 'accent-4':
      text_key = REVERSABLE_ACCENT.includes(nome_colore)
        ? 'lighten-1'
        : 'darken-1'
      break
    case 'black':
      text_key = 'white'
      break
    case 'white':
      text_key = 'black'
      break
    case 'base':
      text_key = 'darken-4'
      break
  }

  const bg = hex({
    valore:
      bg_key === 'black'
        ? '#000000'
        : bg_key === 'white'
        ? '#ffffff'
        : // @ts-ignore
          COLOR[bg_key],
  }).toRgb()
  const text =
    bg_key === 'black'
      ? [255, 255, 255]
      : bg_key === 'white'
      ? [0, 0, 0]
      : hex({
          valore:
            text_key === 'black'
              ? '#000000'
              : bg_key === 'white'
              ? '#ffffff'
              : /** @ts-ignore */
                COLOR[text_key],
        }).toRgb()

  pratica.ColoreSfondo = bg ? bg : [255, 255, 255]

  if (!bg) {
    pratica.ColoreTesto = [0, 0, 0]
  } else {
    pratica.ColoreTesto = text ? text : [0, 0, 0]
  }
  return pratica
}
