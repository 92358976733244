import { canvas_2d } from ':scripts/canvas_2d'

/**
 * Taglia un'immagine da un canvas
 * @param {{
 *  immagine: ImageData,
 *  larghezza: number,
 *  altezza: number,
 *  taglia_lato: {
 *    sinistro: boolean,
 *    destro: boolean,
 *    alto: boolean,
 *    basso: boolean,
 *  }
 * }} payload
 * @returns {{
 *  ok:boolean,
 *  errore:string,
 *  dimensioni:import(':types').DimensioniImmagine,
 *  immagine:ImageData,
 * }}
 */
export function taglia_immagine({ immagine, larghezza, altezza, taglia_lato }) {
  /**
   * @type {import(':types').DimensioniImmagine}
   */
  const dimensioni = {
    x: 0,
    y: 0,
    larghezza: 0,
    altezza: 0,
  }

  const c = document.createElement('canvas')
  c.width = immagine.width
  c.height = immagine.height
  const contesto = c.getContext('2d')
  /** @ts-ignore */
  contesto.putImageData(immagine, 0, 0)

  /** @ts-ignore */
  const immagine_locale = contesto.getImageData(0, 0, larghezza, altezza)
  const limiti_posizioni = {
    sinistra: -1,
    destra: -1,
    alto: -1,
    basso: -1,
  }

  let i = 0
  let x = 0
  let y = 0

  for (i = 0; i < immagine_locale.data.length; i += 4) {
    if (immagine_locale.data[i + 3] !== 0) {
      x = (i / 4) % larghezza
      y = ~~(i / 4 / larghezza)

      if (limiti_posizioni.alto < 0) {
        limiti_posizioni.alto = y
      }

      if (limiti_posizioni.sinistra < 0) {
        limiti_posizioni.sinistra = x
      } else if (x < limiti_posizioni.sinistra) {
        limiti_posizioni.sinistra = x
      }

      if (limiti_posizioni.destra < 0) {
        limiti_posizioni.destra = x
      } else if (limiti_posizioni.destra < x) {
        limiti_posizioni.destra = x
      }

      if (limiti_posizioni.basso < 0) {
        limiti_posizioni.basso = y
      } else if (limiti_posizioni.basso < y) {
        limiti_posizioni.basso = y
      }
    }
  }

  if (
    limiti_posizioni.sinistra < 0 ||
    limiti_posizioni.destra < 0 ||
    limiti_posizioni.alto < 0 ||
    limiti_posizioni.basso < 0
  ) {
    const { contesto } = canvas_2d({
      larghezza: 1,
      altezza: 1,
    })

    return {
      ok: true,
      errore: '',
      dimensioni,
      immagine: contesto.getImageData(0, 0, 1, 1),
    }
  }

  limiti_posizioni.destra += 1
  limiti_posizioni.basso += 1

  let LarghezzaRisultato = limiti_posizioni.destra - limiti_posizioni.sinistra
  let AltezzaRisultato = limiti_posizioni.basso - limiti_posizioni.alto

  if (!taglia_lato.sinistro) {
    LarghezzaRisultato += limiti_posizioni.sinistra
    limiti_posizioni.sinistra = 0
  }
  if (!taglia_lato.alto) {
    AltezzaRisultato += limiti_posizioni.alto
    limiti_posizioni.alto = 0
  }
  if (!taglia_lato.destro) LarghezzaRisultato = larghezza
  if (!taglia_lato.basso) AltezzaRisultato = altezza

  dimensioni.x = limiti_posizioni.sinistra
  dimensioni.y = limiti_posizioni.alto
  dimensioni.larghezza = LarghezzaRisultato
  dimensioni.altezza = AltezzaRisultato

  return {
    ok: true,
    errore: '',
    dimensioni,
    /** @ts-ignore */
    immagine: contesto.getImageData(
      limiti_posizioni.sinistra,
      limiti_posizioni.alto,
      LarghezzaRisultato,
      AltezzaRisultato,
    ),
  }
}
