<script>
  import { mdiAccount } from '@mdi/js'
  import { passeggeri } from ':scripts/passeggeri'

  import { scale } from 'svelte/transition'
  import Icona from ':components/icona.svelte'
  import Progresso from ':components/progresso.svelte'
  /**
   * @type {import(':types').Sessione}
   */
  export let sessione
  /**
   * @type {import(':types').Pratica}
   */
  export let pratica
</script>

<div class="grid relative w-full">
  {#await passeggeri({ sessione, pratica })}
    <div
      class="p-4 grid w-full h-full justify-center content-center text-center"
      transition:scale
    >
      <Progresso />
    </div>
  {:then passeggeri}
    <div class="p-4 w-full" transition:scale>
      {#if passeggeri.length === 0}
        <span
          class="p-4 w-full grid justify-center text-center"
          transition:scale
        >
          <span>Nessun passeggero trovato</span>
        </span>
      {:else}
        {#each passeggeri as passeggero}
          <div class="grid" style="grid-template-columns: auto 1fr;">
            <Icona valore={mdiAccount} />
            <p>
              <span>{passeggero.CognomeACCAccountRecord}</span>
              <span>{passeggero.NomeACCAccountRecord}</span><br />
              <span>{passeggero.EmailComunicazioniACCAccountRecord}</span>
            </p>
          </div>
        {:else}
          <span
            class="p-4 w-full grid justify-center text-center"
            transition:scale
          >
            <span>Nessun passeggero trovato</span>
          </span>
        {/each}
      {/if}
    </div>
  {:catch errore}
    <span class="p-4 w-full grid justify-center text-center" transition:scale>
      <span>{errore}</span>
    </span>
  {/await}
</div>
