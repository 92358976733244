/**
 *
 * @param {{pagliaio:string, ago:string}} payload
 * @returns
 */
export function stringa_contiene({ pagliaio, ago }) {
  if (ago === pagliaio) return true
  if ('' === ago) return false
  const chunks = ago.toLowerCase().split(' ')

  pagliaio = pagliaio.toLowerCase()
  for (let i = 0; i < chunks.length; i++)
    if (pagliaio.includes(chunks[i])) return true

  return false
}
