<script>
  import DivisoreOrizzontaleConIntestazione from ':components/divisore-orizzontale-con-intestazione.svelte'
  import FiltroSemplice from ':components/filtro-semplice.svelte'
  import ComponenteOfferta from ':components/offerta.svelte'
  import { dayjs } from ':scripts/dayjs'

  import { onMount, tick } from 'svelte'
  import { Pagination } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/svelte'

  /**
   * @type {string}
   */
  export let titolo
  /**
   * @type {string}
   */
  export let didascalia
  /**
   * @type {number}
   */
  export let larghezza
  /**
   * @type {Array<import(':types').Offerta>}
   */
  export let offerte = []
  export let stagione_selezionata = ''

  const PRIMAVERA = 'Primavera'
  const ESTATE = 'Estate'
  const AUTUNNO = 'Autunno'
  const INVERNO = 'Inverno'

  const GENNAIO = 'Gennaio'
  const FEBBRAIO = 'Febbraio'
  const MARZO = 'Marzo'
  const APRILE = 'Aprile'
  const MAGGIO = 'Maggio'
  const GIUGNO = 'Giugno'
  const LUGLIO = 'Luglio'
  const AGOSTO = 'Agosto'
  const SETTEMBRE = 'Settembre'
  const OTTOBRE = 'Ottobre'
  const NOVEMBRE = 'Novembre'
  const DICEMBRE = 'Dicembre'

  let stagioni = {
    0: INVERNO,
    1: INVERNO,
    2: PRIMAVERA,
    3: PRIMAVERA,
    4: ESTATE,
    5: ESTATE,
    6: ESTATE,
    7: AUTUNNO,
    8: AUTUNNO,
    9: INVERNO,
    10: INVERNO,
    11: INVERNO,
  }

  let visibile = false
  let filtro = true

  let mesi_disponibili = {
    Gennaio: false,
    Febbraio: false,
    Marzo: false,
    Aprile: false,
    Maggio: false,
    Giugno: false,
    Luglio: false,
    Agosto: false,
    Settembre: false,
    Ottobre: false,
    Novembre: false,
    Dicembre: false,
  }

  let nomi_mesi_disponibili = Object.keys(mesi_disponibili)

  $: stagioni_disponibili = {
    Primavera:
      mesi_disponibili[MARZO] ||
      mesi_disponibili[APRILE] ||
      mesi_disponibili[MAGGIO],
    Estate:
      mesi_disponibili[GIUGNO] ||
      mesi_disponibili[LUGLIO] ||
      mesi_disponibili[AGOSTO],
    Autunno:
      mesi_disponibili[SETTEMBRE] ||
      mesi_disponibili[OTTOBRE] ||
      mesi_disponibili[NOVEMBRE],
    Inverno:
      mesi_disponibili[DICEMBRE] ||
      mesi_disponibili[GENNAIO] ||
      mesi_disponibili[FEBBRAIO],
  }

  $: nomi_stagioni = Object.keys(stagioni_disponibili).filter(function pass(
    nome_stagione,
  ) {
    // @ts-ignore
    return stagioni_disponibili[nome_stagione]
  })

  $: aggiornamento_offerte({ offerte })
  /**
   * @param {{offerte:Array<import(':types').Offerta>}} payload
   */
  function aggiornamento_offerte({ offerte }) {
    for (const offerta of offerte) {
      const KEY =
        nomi_mesi_disponibili[
          dayjs(offerta.periodo_inizio, 'YYYY-MM-DD').toDate().getMonth()
        ]
      /** @ts-ignore */
      if (!mesi_disponibili[KEY]) mesi_disponibili[KEY] = true
    }
    mesi_disponibili = mesi_disponibili
  }

  $: aggiornamento_stagioni_disponibili({ stagioni_disponibili })
  /**
   * @param {{stagioni_disponibili: typeof stagioni_disponibili}} payload
   */
  async function aggiornamento_stagioni_disponibili({ stagioni_disponibili }) {
    visibile = false
    filtro = false
    await tick()
    visibile = true
    filtro = true
  }

  $: aggiornamento_stagione_selezionata({
    stagione_selezionata,
    nomi_stagioni,
  })
  /**
   * @param {{stagione_selezionata: string, nomi_stagioni: Array<string>}} payload
   */
  async function aggiornamento_stagione_selezionata({
    stagione_selezionata,
    nomi_stagioni,
  }) {
    visibile = false
    await tick()
    visibile = true
  }

  onMount(function run() {
    stagione_selezionata = nomi_stagioni[0] ?? stagione_selezionata
  })

  /**
   *
   * @param {{offerte: Array<import(':types').Offerta>}} payload
   */
  function offerte_stagionali({ offerte }) {
    return offerte.filter(function pass(offerta) {
      const mese = dayjs(offerta.periodo_inizio, 'YYYY-MM-DD')
        .toDate()
        .getMonth()
      return (
        // @ts-ignore
        stagioni[mese] === stagione_selezionata
      )
    })
  }
</script>

{#if offerte.length > 0}
  <DivisoreOrizzontaleConIntestazione {titolo} {didascalia} />
  <div class="pt-2" />
  {#if filtro}
    <div class="ml-3 mr-3">
      <FiltroSemplice
        bind:selezione={stagione_selezionata}
        nomi={nomi_stagioni}
      />
    </div>
  {/if}
  <div class="pt-2" />
  {#if visibile}
    <Swiper
      modules={[Pagination]}
      pagination={{
        bulletActiveClass: 'swiper-pagination-bullet-active amber',
      }}
      spaceBetween={30}
      watchOverflow={true}
      slidesPerView={larghezza < 400 ? 1 : Math.floor(larghezza / 400)}
      width={larghezza}
    >
      {#each offerte_stagionali({ offerte }) as offerta}
        <SwiperSlide>
          <ComponenteOfferta {offerta} />
        </SwiperSlide>
      {:else}
        <SwiperSlide>
          <div class="card w-96 bg-base-100 shadow-xl">
            <div class="card-body">
              <p>
                Ci dispiace non ci sono proposte per la categoria selezionata
              </p>
            </div>
          </div>
        </SwiperSlide>
      {/each}
    </Swiper>
  {/if}
{/if}
