import { uri } from ':scripts/uri'
import { get, post, del, put } from './http'

const AUTENTICAZIONE = 'https://accounts.google.com/o/oauth2/v2/auth'
const DATI_PROFILO = 'https://www.googleapis.com/oauth2/v3/userinfo'
const ID_APP =
  '116846151622-h2b83suce2tfohvljvgnjuoh510tgdrf.apps.googleusercontent.com'

/**
 *
 * @param {{destinazione:string}} payload
 */
function redirect({ destinazione }) {
  const options = {
    response_type: 'token',
    scope:
      'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
    redirect_uri: destinazione,
    client_id: ID_APP,
    state: 'google',
  }

  location.href = `${AUTENTICAZIONE}?${uri({ valore: options }).encode()}`
}

/**
 *
 * @param {{access_token:string}} payload
 * @returns
 */
async function info({ access_token }) {
  const options = {
    access_token,
  }

  /**
   * @type {import(':types').AxiosUnsafe<import(':types').AxiosResponse<import(':types').ProfiloGoogle>>}
   */
  const [risposta, errore] = await get({
    indirizzo: `${DATI_PROFILO}?${uri({ valore: options }).encode()}`,
  })

  if (errore) {
    return false
  }

  return risposta.data
}

export const google = {
  redirect,
  info,
}
