const Data = new Intl.DateTimeFormat('it-IT')

/**
 *
 * @param {{valore:string}} payload
 * @returns
 */
function italiana({ valore }) {
  return Data.format(new Date(valore))
}

export const data = {
  italiana,
}
