<script>
  import { mdiPaperclip } from '@mdi/js'
  import { applica_tema_pratica } from ':scripts/applica_tema_pratica'
  import { createEventDispatcher } from 'svelte'
  import Icona from ':components/icona.svelte'

  /**
   * @type {Array<import(':types').Pratica>}
   */
  export let elenco_pratiche = []
  /**
   * @type {import(':types').Sessione}
   */
  export let sessione

  const emit = createEventDispatcher()

  /**
   * @param {{valore:string}} payload
   */
  function resolve_partenza({ valore }) {
    let pieces = valore.split('-')
    if (pieces.length < 3) return ''
    return `${pieces[2]}/${pieces[1]}/${pieces[0]}`
  }

  /**
   * @param {{elenco_pratiche: Array<import(':types').Pratica>}} payload
   */
  function PraticheDisponibili({ elenco_pratiche }) {
    return elenco_pratiche.filter(function pass(pratica) {
      return !pratica.Eliminata
    })
  }
</script>

{#each PraticheDisponibili({ elenco_pratiche }) as pratica}
  <div class="pt-2" />
  {#await applica_tema_pratica({ pratica }) then pratica}
    <button
      class="btn btn-ghost w-full rounded-xl p-8 pt-12 pb-12 grid justify-start content-center"
      style="grid-template-columns: auto 1fr;"
      on:click={function run() {
        emit('apertura-pratica', pratica.NumeroPratica)
      }}
    >
      <div class="avatar">
        <div class="w-12 rounded-full">
          <!-- <img src="https://placeimg.com/192/192/people" /> -->
          <div
            style="
                    background-color: rgb({pratica.ColoreSfondo});
                    color: rgb({pratica.ColoreTesto});
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    font-weight: bolder;
                    font-size: 2rem;
                    text-transform: capitalie;
                "
            class="grid justify-self-center justify-items-center content-center"
          >
            {pratica.CognomeCliente && pratica.CognomeCliente.length > 0
              ? pratica.CognomeCliente[0]
              : pratica.NomeCliente && pratica.NomeCliente.length > 0
              ? pratica.NomeCliente[0]
              : 'P'}
          </div>
        </div>
      </div>
      <div class="p-2 grid w-full h-full justify-start content-center">
        <div class="flex flex-wrap font-bold">
          <span>{pratica.NumeroPratica}</span>
          <span class="pl-2">{pratica.CognomeCliente}</span>
          <span class="pl-2">{pratica.NomeCliente}</span>
          {#if sessione.Utente.IsUtenteApplicativo}
            {#if pratica.NumeroAllegati > 0}
              <span style="margin-top:-7px"
                ><Icona valore={mdiPaperclip} /></span
              >
              <span>[{pratica.NumeroAllegati}]</span>
            {/if}
          {:else if pratica.NumeroAllegatiPubblici > 0}
            <span style="margin-top:-7px"><Icona valore={mdiPaperclip} /></span>
            <span>[{pratica.NumeroAllegati}]</span>
          {/if}
        </div>
        <div class="flex flex-wrap text-sx font-thin text-start">
          Partenza: {pratica && pratica.DataPartenzaCliente
            ? resolve_partenza({ valore: pratica.DataPartenzaCliente })
            : ''}
        </div>
        <div class="flex flex-wrap text-xs font-thin text-start">
          {pratica.Descrizione || ''}
          <div class="pt-2" />
          {pratica.DescrizioneDestinazioneSintetica ||
            'Destinazione non indicata'}
        </div>
      </div>
    </button>
  {:catch errore}
    <div class="grid grid-x-center grid-y-center full-height full-width">
      {errore}
    </div>
  {/await}
{:else}
  <div class="p-8 w-full grid justify-center">
    <span>Nessuna pratica trovata</span>
  </div>
{/each}
