<script>
  import { mdiFile } from '@mdi/js'
  import Icona from ':components/icona.svelte'
  import Spunta from ':components/spunta.svelte'

  let informativa_privacy = false
  let informativa_cookie = false
  let non_inviare_offerte = false
</script>

<slot name="Nome" /><br />
<slot name="Cognome" /><br />
<slot name="Email" /><br />

<p>
  Per poter procedere è necessario leggere ed accettare la nostra politica sulla
  privacy e sui cookie.
</p>
<br />

<button
  class="btn btn-ghost"
  on:click={function run() {
    window.open('https://exactagroup.it/informativa-privacy')
  }}
>
  <Icona valore={mdiFile} />
  <span class="pl-2">Leggi la politica sulla privacy</span>
</button>

<div class="pt-2" />
<button
  class="btn btn-ghost"
  on:click={function run() {
    window.open('https://exactagroup.it/informativa-cookie')
  }}
>
  <Icona valore={mdiFile} />
  <span class="pl-2">Leggi la politica sui cookie</span>
</button>

<div class="pt-2" />
<Spunta
  bind:attiva={informativa_privacy}
  testo="Ho letto ed accettato la politica sulla privacy."
/>

<div class="pt-2" />
<Spunta
  bind:attiva={informativa_cookie}
  testo="Ho letto ed accettato la politica sui cookie."
/>

<div class="pt-2" />
<Spunta
  bind:attiva={non_inviare_offerte}
  testo="Non voglio ricevere Email su nuovi prodotti, best practice e offerte speciali."
/>
<div class="pt-4" />
<div class="grid grid-x-center">
  <slot
    name="Invia"
    NonInviareOfferte={non_inviare_offerte}
    InformativaPrivacy={informativa_privacy}
    InformativaCookie={informativa_cookie}
  />
</div>
<div class="pt-4" />
