import axios from 'axios'
import { sferanetqbe } from ':scripts/sferanetqbe'
import { IOTNULL } from ':constants'
import { store_messaggio } from ':stores/store_messaggio'
import { store_url_sferanet } from ':stores/store_url_sferanet'
import { get } from 'svelte/store'

/**
 * Ottiene i biglietti della pratica
 * @param {{sessione:import(':types').Sessione,pratica:import(':types').Pratica}} payload
 */
export async function biglietti({ sessione, pratica }) {
  try {
    const $store_url_sferanet = get(store_url_sferanet)

    const query = sferanetqbe()
      .uguale({ chiave: 'PraticaID', valore: pratica.PraticaID })
      .toString()
    /**
     * @type {import(':types').AxiosResponse<{ PRTBiglietto: Array<import(':types').BigliettoPratica> }>}
     */
    const response = await axios.get(
      `${$store_url_sferanet}/CompFacileAccounts/PRTBiglietto.json?${query}`,
      {
        headers: {
          codiceagenzia: sessione.Utente.CodiceAgenzia,
          accesstoken: sessione.Utente.AccessToken,
        },
      },
    )

    // @ts-ignore
    if (IOTNULL === response.data) {
      store_messaggio.set({ testo: IOTNULL })
      return []
    }

    return response.data.PRTBiglietto || []
  } catch (e) {
    console.warn(e)
    return []
  }
}
