<script>
  import LineaOrizzontale from ':components/linea-orizzontale.svelte'

  import Spunta from ':components/spunta.svelte'

  /**
   * @type {import(':types').MetadatiDominio}
   */
  export let dominio

  let non_inviare_offerte = false
</script>

<slot name="Nome" /><br />
<slot name="Cognome" /><br />
<slot name="Email" /><br />

<small>
  Selezionando <b>Accetta e continua</b> di seguito, accetto i
  <a target="_blank" href="/">Termini del Servizio</a>, l'<a
    target="_blank"
    href="https://exactagroup.it/informativa-privacy"
  >
    <span> Informativa sulla Privacy </span>
  </a>
  e l'
  <a target="_blank" href="https://exactagroup.it/informativa-cookie">
    <span> Informativa sui Cookie </span>
  </a>
  <span>di {dominio.Nome}</span>
</small>
<br /><br /><br />

<div class="grid grid-x-center">
  <slot name="Invia" NonInviareOfferte={non_inviare_offerte} />
</div>

<br />
<br />

<LineaOrizzontale />

<br />

<small>
  {dominio.Nome} potrà inviare offerte di viaggio riservate agli iscritti, Email
  di marketing e notifiche push. Puoi decidere in qualunque momento di non ricevere
  più tali comunicazioni. Non dovrai fare altro che gestire le notifiche dalle impostazioni
  del tuo account.
  <div class="pt-4" />
  <Spunta bind:attiva={non_inviare_offerte}>
    Non voglio ricevere messaggi promozionali da
    <span class="text-base-content font-semibold">{dominio.Nome}</span>
  </Spunta>
</small>

<div class="pt-4" />
