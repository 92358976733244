<style>
  .charts-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }
</style>

<script>
  import Accesso from ':components/accesso.svelte'
  import AppBarPrincipale from ':components/app-bar-principale.svelte'
  import DivisoreOrizzontaleConIntestazione from ':components/divisore-orizzontale-con-intestazione.svelte'
  import GraficoBarreOrizzontali from ':components/grafico-barre-orizzontali.svelte'
  import GraficoBarreSovrapposteOrizzontali from ':components/grafico-barre-sovraposte-orizzontali.svelte'
  import GraficoTorta from ':components/grafico-torta.svelte'
  import Progresso from ':components/progresso.svelte'
  import Scrollabile from ':components/scrollabile.svelte'

  import { store_url_facilews3 } from ':stores/store_url_facilews3'
  import axios from 'axios'
  import { Colori } from ':constants'
  import { dominio } from ':scripts/dominio'

  /**
   *
   * @param {{elementi:Array<import(':types').PartitoMeseAnno | import(':types').VendutoMeseAnno>}} payload
   */
  function estrai_label({ elementi }) {
    /**
     * @type {Array<string>}
     */
    const valori = elementi.map(function pass(elemento) {
      return `${elemento.Anno} / ${elemento.Mese}`
    })
    return valori
  }

  /**
   *
   * @param {{elementi:Array<import(':types').PartitoMeseAnno | import(':types').VendutoMeseAnno>}} payload
   */
  function estrai_valori({ elementi }) {
    const valori = elementi.map(function pass(elemento) {
      return parseInt(elemento.Valore)
    })
    return valori
  }

  const anno_attuale = new Date().getFullYear()
  const anno_precedente = anno_attuale - 1
  const anno_selezionato_2 = anno_precedente - 1

  let anno_selezionato_1 = anno_attuale

  /**
   *
   * @param {{
   *  sessione:import(':types').Sessione,
   *  anno: number,
   * }} payload
   */
  async function trova_dati({ sessione, anno }) {
    /**
     * @type {import(':types').AxiosResponse<import(':types').RispostaFacileWS3<Array<import(':types').PartitoMeseAnno>>>}
     */
    const partito = await axios.get(
      `${$store_url_facilews3}/Api/Rest/${sessione.Utente.CodiceAgenzia}/PartitoMeseAnno/${anno}?Token=${sessione.Utente.TokenFacileWS}`,
    )

    /**
     * @type {import(':types').AxiosResponse<import(':types').RispostaFacileWS3<Array<import(':types').VendutoMeseAnno>>>}
     */
    const venduto = await axios.get(
      `${$store_url_facilews3}/Api/Rest/${sessione.Utente.CodiceAgenzia}/VendutoMeseAnno/${anno}?Token=${sessione.Utente.TokenFacileWS}`,
    )

    /** @ts-ignore */
    const partito_mese_anno_label = estrai_label({
      elementi: partito.data.data ?? [],
    })
    /** @ts-ignore */
    const partito_mese_anno_valori = estrai_valori({
      elementi: partito.data.data ?? [],
    })
    /** @ts-ignore */
    const venduto_mese_anno_label = estrai_label({
      elementi: venduto.data.data ?? [],
    })
    /** @ts-ignore */
    const venduto_mese_anno_valori = estrai_valori({
      elementi: venduto.data.data ?? [],
    })

    return {
      partito_mese_anno_label,
      partito_mese_anno_valori,
      venduto_mese_anno_label,
      venduto_mese_anno_valori,
    }
  }

  /**
   * @type {number}
   */
  let trigger_ridisegno
  /**
   * @type {number}
   */
  let larghezza
  /**
   * @type {number}
   */
  let altezza
  /**
   * @type {HTMLDivElement}
   */
  let contenuto
  /**
   * @type {string}
   */
  let larghezza_grafico

  let data_array_1 = [
    ['Mese', 'TotaleVenduto', 'TotalePartito'],

    ['2020 / 12', 2580, 1670],
    ['2020 / 11', 640, 70],
    ['2020 / 10', 1690, 600],
    ['2020 / 09', 960, 700],
    ['2020 / 08', 3840, 1000],
    ['2020 / 07', 1440, 2560],
    ['2020 / 06', 7200, 1780],
    ['2020 / 05', 5600, 4000],
    ['2020 / 04', 3770, 2000],
    ['2020 / 03', 1440, 800],
    ['2020 / 02', 3500, 1200],
    ['2020 / 01', 125, 80],
  ]

  let colors_1 = ['#feca16', '#717172']

  let title_1 =
    'Riepilogo mensile (comparazione tra TotaleVenduto e TotalePartito)'

  let data_array_2 = [
    ['Mese', 'TotalePartito (2020)', 'TotalePartito (2016)'],

    ['2020 vs 2016 / 12', 2580, 1670],
    ['2020 vs 2016 / 11', 640, 70],
    ['2020 vs 2016 / 10', 1690, 600],
    ['2020 vs 2016 / 09', 960, 700],
    ['2020 vs 2016 / 08', 3840, 1000],
    ['2020 vs 2016 / 07', 1440, 2560],
    ['2020 vs 2016 / 06', 7200, 1780],
    ['2020 vs 2016 / 05', 5600, 4000],
    ['2020 vs 2016 / 04', 3770, 2000],
    ['2020 vs 2016 / 03', 1440, 800],
    ['2020 vs 2016 / 02', 3500, 1200],
    ['2020 vs 2016 / 01', 125, 80],
  ]

  let colors_2 = colors_1

  let title_2 =
    'Riepilogo mensile (comparazione di TotalePartito tra 2 anni distinti)'

  let data_array_3 = [
    ['Mese', 'TotaleVenduto (2020)', 'TotaleVenduto (2016)'],

    ['2020 vs 2016 / 12', 2580, 1670],
    ['2020 vs 2016 / 11', 640, 70],
    ['2020 vs 2016 / 10', 1690, 600],
    ['2020 vs 2016 / 09', 960, 700],
    ['2020 vs 2016 / 08', 3840, 1000],
    ['2020 vs 2016 / 07', 1440, 2560],
    ['2020 vs 2016 / 06', 7200, 1780],
    ['2020 vs 2016 / 05', 5600, 4000],
    ['2020 vs 2016 / 04', 3770, 2000],
    ['2020 vs 2016 / 03', 1440, 800],
    ['2020 vs 2016 / 02', 3500, 1200],
    ['2020 vs 2016 / 01', 125, 80],
  ]

  let colors_3 = colors_1

  let title_3 =
    'Riepilogo mensile (comparazione di TotaleVenduto tra 2 anni distinti)'

  let data_array_4 = [
    ['Mese', 'TotaleRicavi', 'TotaleCosti', 'DifferenzaRicaviCosti'],

    ['2020 / 12', 2580, 1670, 910],
    ['2020 / 11', 640, 70, 570],
    ['2020 / 10', 1690, 600, 1090],
    ['2020 / 09', 960, 700, 260],
    ['2020 / 08', 3840, 1000, 2840],
    ['2020 / 07', 1440, 2560, -1120],
    ['2020 / 06', 7200, 1780, 5420],
    ['2020 / 05', 5600, 4000, 1600],
    ['2020 / 04', 3770, 2000, 1770],
    ['2020 / 03', 1440, 800, 640],
    ['2020 / 02', 3500, 1200, 2300],
    ['2020 / 01', 125, 80, 45],
  ]

  let colors_4 = ['#feca16', '#717172', '#34a5eb']

  let title_4 =
    'Riepilogo mensile (comparazione tra TotaleRicavi e TotaleCosti e differenza tra di essi)'

  let data_array_6 = [
    ['Mese', 'TotaleVenduto'],
    ['2020-01', 860],
    ['2020-02', 1344],
    ['2020-03', 4600],
    ['2020-04', 490],
    ['2020-05', 640],
    ['2020-06', 1440],
    ['2020-07', 3770],
    ['2020-08', 1500],
    ['2020-09', 740],
    ['2020-10', 165],
    ['2020-11', 2500],
    ['2020-12', 4000],
  ]

  let title_6 = 'Redditività (Totale Venduto anno 2020)'

  let data_array_7 = [
    ['Mese', 'TotalePartito'],
    ['2020-01', 1500],
    ['2020-02', 1920],
    ['2020-03', 139],
    ['2020-04', 155],
    ['2020-05', 360],
    ['2020-06', 2560],
    ['2020-07', 260],
    ['2020-08', 700],
    ['2020-09', 90],
    ['2020-10', 470],
    ['2020-11', 3600],
    ['2020-12', 1250],
  ]

  let title_7 = 'Redditività (TotalePartito anno 2020)'

  let colors_kpi = ['#feca16', '#717172']

  let data_array_kpi_1 = [
    ['Anno', 'ValoreRedditività'],
    [2021, 1280],
    [2020, 2560],
  ]

  let title_kpi_1 =
    'Comparazione redditività generale (AnnoCorrente vs AnnoPrecedente)'

  let data_array_kpi_2 = [
    ['', ''],
    ['NrPraticheAnnoCorrente', 78],
    ['NrPraticheAnnoPrecedente', 39],
    ['ValoreRedditivitàAnnoPrecedente', 2560],
  ]

  let title_kpi_2 =
    'Informazioni su pratiche e redditività (AnnoCorrente e AnnoPrecedente)'

  let data_array_kpi_3 = [
    ['Anno', 'NumeroPasseggeri'],
    [2021, 1390],
    [2020, 780],
  ]

  let title_kpi_3 =
    'Comparazione numero passeggeri (AnnoCorrente vs AnnoPrecedente)'

  let data_array_kpi_4 = [
    ['Anno', 'TotaleVenduto'],
    [2021, 1280],
    [2020, 720],
  ]

  let title_kpi_4 =
    'Comparazione totale venduto (AnnoCorrente vs AnnoPrecedente)'

  function update() {
    if (!contenuto) {
      setTimeout(function run() {
        requestAnimationFrame(update)
      }, 1000)
      return
    }

    if (larghezza !== contenuto.offsetWidth) {
      larghezza = contenuto.offsetWidth
      trigger_ridisegno = Date.now()
    }

    if (altezza !== contenuto.offsetHeight) {
      altezza = contenuto.offsetHeight
      trigger_ridisegno = Date.now()
    }

    if (larghezza_grafico !== '100%' && larghezza < 1280)
      larghezza_grafico = '100%'
    else if (larghezza_grafico !== '50%' && larghezza >= 1280)
      larghezza_grafico = '50%'

    setTimeout(function run() {
      requestAnimationFrame(update)
    }, 1000)
  }
</script>

{#await dominio()}
  <Progresso globale />
{:then dominio}
  <Accesso {dominio}>
    <svelte:fragment let:sessione>
      <AppBarPrincipale
        titolo="{sessione.Utente.CodiceAgenzia.toLocaleUpperCase()} - {sessione
          .Utente.Username}"
      />
      <Scrollabile>
        {#if sessione.Utente.VisualizzaDashboard}
          {#await trova_dati({ sessione: sessione, anno: anno_selezionato_1 })}
            <Progresso globale />
          {:then { partito_mese_anno_label, partito_mese_anno_valori, venduto_mese_anno_label, venduto_mese_anno_valori }}
            <div bind:this={contenuto} class="contenuto">
              <div class="pt-6" />
              <div class="grid-x-center grid-cols-3-auto">
                <button
                  class:btn-primary={anno_selezionato_1 === anno_selezionato_2}
                  class="btn btn-ghost"
                  on:click={function run() {
                    anno_selezionato_1 = anno_selezionato_2
                  }}
                >
                  <span>{anno_selezionato_2}</span>
                </button>

                <button
                  class:btn-primary={anno_selezionato_1 === anno_precedente}
                  class="btn btn-ghost"
                  on:click={function run() {
                    anno_selezionato_1 = anno_precedente
                  }}
                >
                  <span>{anno_precedente}</span>
                </button>

                <button
                  class:btn-primary={anno_selezionato_1 === anno_attuale}
                  class="btn btn-ghost"
                  on:click={function run() {
                    anno_selezionato_1 = anno_attuale
                  }}
                >
                  <span>{anno_attuale}</span>
                </button>
              </div>
              <div class="grid-x-center">
                Selezionato di default anno in corso
              </div>
              <br />
              <br />

              <div class="charts-wrapper">
                <!-- (Riepilogo mensile) Totale Venduto & Totale Partito -->
                <DivisoreOrizzontaleConIntestazione
                  titolo="Riepilogo Mensile"
                  didascalia="Totale Venduto & Totale Partito"
                />
                <GraficoBarreSovrapposteOrizzontali
                  {trigger_ridisegno}
                  larghezza="100%"
                  altezza="30rem"
                  etichette={partito_mese_anno_label}
                  dataset={[
                    {
                      label: 'Totale Venduto',
                      backgroundColor: Colori.red['darken-4'],
                      stack: '1',
                      data: venduto_mese_anno_valori,
                    },
                    {
                      label: 'Totale Partito',
                      backgroundColor: Colori.red['lighten-3'],
                      stack: '1',
                      data: partito_mese_anno_valori,
                    },
                  ]}
                />

                <!-- (Riepilogo mensile) Totale Partito 2020 & 2016 -->
                <DivisoreOrizzontaleConIntestazione
                  titolo="Riepilogo Mensile"
                  didascalia="Totale Partito 2020 & 2016"
                />
                <GraficoBarreOrizzontali
                  {trigger_ridisegno}
                  larghezza={larghezza_grafico}
                  altezza="30rem"
                  etichette={[
                    '2020 vs 2016 / 12',
                    '2020 vs 2016 / 11',
                    '2020 vs 2016 / 10',
                    '2020 vs 2016 / 9',
                    '2020 vs 2016 / 8',
                    '2020 vs 2016 / 7',
                    '2020 vs 2016 / 6',
                    '2020 vs 2016 / 5',
                    '2020 vs 2016 / 4',
                    '2020 vs 2016 / 3',
                    '2020 vs 2016 / 2',
                    '2020 vs 2016 / 1',
                  ]}
                  dataset={[
                    {
                      label: 'Totale Partito (2020)',
                      backgroundColor: Colori.red['darken-4'],
                      stack: '1',
                      data: [
                        2580, 640, 1690, 960, 3840, 1440, 7200, 5600, 3770,
                        1440, 3500, 125,
                      ],
                    },
                    {
                      label: 'Totale Partito (2016)',
                      backgroundColor: Colori.red['lighten-3'],
                      stack: '2',
                      data: [
                        1670, 70, 600, 700, 1000, 2560, 1780, 4000, 2000, 800,
                        1200, 80,
                      ],
                    },
                  ]}
                />

                <!-- (Riepilogo mensile) Totale Venduto 2020 & 2016 -->

                <DivisoreOrizzontaleConIntestazione
                  titolo="Riepilogo Mensile"
                  didascalia="Totale Venduto 2020 & 2016"
                />
                <GraficoBarreOrizzontali
                  {trigger_ridisegno}
                  larghezza={larghezza_grafico}
                  altezza="30rem"
                  etichette={[
                    '2020 vs 2016 / 12',
                    '2020 vs 2016 / 11',
                    '2020 vs 2016 / 10',
                    '2020 vs 2016 / 9',
                    '2020 vs 2016 / 8',
                    '2020 vs 2016 / 7',
                    '2020 vs 2016 / 6',
                    '2020 vs 2016 / 5',
                    '2020 vs 2016 / 4',
                    '2020 vs 2016 / 3',
                    '2020 vs 2016 / 2',
                    '2020 vs 2016 / 1',
                  ]}
                  dataset={[
                    {
                      label: 'Totale Venduto (2020)',
                      backgroundColor: Colori.red['darken-4'],
                      stack: '1',
                      data: [
                        2580, 640, 1690, 960, 3840, 1440, 7200, 5600, 3770,
                        1440, 3500, 125,
                      ],
                    },
                    {
                      label: 'Totale Venduto (2016)',
                      backgroundColor: Colori.red['lighten-3'],
                      stack: '2',
                      data: [
                        1670, 70, 600, 700, 1000, 2560, 1780, 4000, 2000, 800,
                        1200, 80,
                      ],
                    },
                  ]}
                />

                <!-- (Riepilogo mensile) Totale Costi & Totale Ricavi -->

                <DivisoreOrizzontaleConIntestazione
                  titolo="Riepilogo Mensile"
                  didascalia="Totale Costi & Totale Ricavi"
                />
                <GraficoBarreOrizzontali
                  {trigger_ridisegno}
                  larghezza="100%"
                  altezza="40rem"
                  etichette={[
                    '2020 / 12',
                    '2020 / 11',
                    '2020 / 10',
                    '2020 / 9',
                    '2020 / 8',
                    '2020 / 7',
                    '2020 / 6',
                    '2020 / 5',
                    '2020 / 4',
                    '2020 / 3',
                    '2020 / 2',
                    '2020 / 1',
                  ]}
                  dataset={[
                    {
                      label: 'Totale Ricavi',
                      backgroundColor: Colori.red['darken-4'],
                      stack: '1',
                      data: [
                        2580, 640, 1690, 960, 3840, 1440, 7200, 5600, 3770,
                        1440, 3500, 125,
                      ],
                    },
                    {
                      label: 'Totale Totale Costi',
                      backgroundColor: Colori.red['lighten-3'],
                      stack: '2',
                      data: [
                        1670, 70, 600, 700, 1000, 2560, 1780, 4000, 2000, 800,
                        1200, 80,
                      ],
                    },
                    {
                      label: 'Totale Totale Costi',
                      backgroundColor: Colori.blue['lighten-3'],
                      stack: '3',
                      data: [
                        910, 570, 1090, 260, 2840, -1120, 5420, 1600, 1770, 640,
                        2300, 45,
                      ],
                    },
                  ]}
                />

                <!-- (Redditività) Totale Venduto 2020 -->
                <DivisoreOrizzontaleConIntestazione
                  titolo="Redditività"
                  didascalia="Totale Venduto 2020"
                />
                <GraficoTorta
                  {trigger_ridisegno}
                  larghezza={larghezza_grafico}
                  altezza="30rem"
                  etichette={[
                    '2020 / 12',
                    '2020 / 11',
                    '2020 / 10',
                    '2020 / 9',
                    '2020 / 8',
                    '2020 / 7',
                    '2020 / 6',
                    '2020 / 5',
                    '2020 / 4',
                    '2020 / 3',
                    '2020 / 2',
                    '2020 / 1',
                  ]}
                  dataset={[
                    {
                      label: 'Totale Venduto',
                      backgroundColor: [
                        Colori.red['darken-4'],
                        Colori.yellow['darken-4'],
                        Colori.blue['darken-4'],
                        Colori.amber['darken-4'],
                        Colori.brown['darken-4'],
                        Colori.green['darken-4'],
                        Colori.purple['darken-4'],
                        Colori.yellow['darken-4'],
                        Colori.blueGrey['darken-4'],
                        Colori.cyan['darken-4'],
                        Colori.deepOrange['darken-4'],
                        Colori.deepPurple['darken-4'],
                      ],
                      data: [
                        1500, 1920, 139, 155, 360, 2560, 260, 700, 90, 470,
                        3600, 1250,
                      ],
                    },
                  ]}
                />

                <!-- (Redditività) Totale Partito 2020 -->
                <DivisoreOrizzontaleConIntestazione
                  titolo="Redditività"
                  didascalia="Totale Partito 2020"
                />
                <GraficoTorta
                  {trigger_ridisegno}
                  larghezza={larghezza_grafico}
                  altezza="30rem"
                  etichette={[
                    '2020 / 12',
                    '2020 / 11',
                    '2020 / 10',
                    '2020 / 9',
                    '2020 / 8',
                    '2020 / 7',
                    '2020 / 6',
                    '2020 / 5',
                    '2020 / 4',
                    '2020 / 3',
                    '2020 / 2',
                    '2020 / 1',
                  ]}
                  dataset={[
                    {
                      label: 'Totale Partito',
                      backgroundColor: [
                        Colori.red['darken-4'],
                        Colori.yellow['darken-4'],
                        Colori.blue['darken-4'],
                        Colori.amber['darken-4'],
                        Colori.brown['darken-4'],
                        Colori.green['darken-4'],
                        Colori.purple['darken-4'],
                        Colori.yellow['darken-4'],
                        Colori.blueGrey['darken-4'],
                        Colori.cyan['darken-4'],
                        Colori.deepOrange['darken-4'],
                        Colori.deepPurple['darken-4'],
                      ],
                      data: [
                        1500, 1920, 139, 155, 360, 2560, 260, 700, 90, 470,
                        3600, 1250,
                      ],
                    },
                  ]}
                />

                <DivisoreOrizzontaleConIntestazione
                  titolo="KPI"
                  didascalia="Key Performance Indicator"
                />

                <!-- Redditività generale anno corrente & anno precedente -->
                <DivisoreOrizzontaleConIntestazione
                  titolo="Comparazione"
                  didascalia="Redditività Generale 2020"
                />
                <GraficoBarreOrizzontali
                  larghezza={larghezza_grafico}
                  altezza="10rem"
                  etichette={['2021', '2020']}
                  dataset={[
                    {
                      label: 'Redditività',
                      backgroundColor: Colori.purple['darken-4'],
                      data: [1280, 2560],
                    },
                  ]}
                />

                <!-- Informazioni su pratiche e redditività anno corrente & anno precedente -->
                <DivisoreOrizzontaleConIntestazione
                  titolo="Informazioni"
                  didascalia="Su Pratiche e Redditività 2020"
                />
                <GraficoBarreOrizzontali
                  larghezza={larghezza_grafico}
                  altezza="15rem"
                  etichette={[
                    'N° pratiche 2021',
                    'N° pratiche 2020',
                    'Redditività 2020',
                  ]}
                  dataset={[
                    {
                      label: 'Valore',
                      backgroundColor: Colori.purple['darken-4'],
                      data: [78, 39, 2560],
                    },
                  ]}
                />

                <!-- Comparazione  numero passeggeri anno corrente & anno precedente-->
                <DivisoreOrizzontaleConIntestazione
                  titolo="Comparazione"
                  didascalia="Numero Passeggeri"
                />
                <GraficoBarreOrizzontali
                  larghezza={larghezza_grafico}
                  altezza="10rem"
                  etichette={['2021', '2020']}
                  dataset={[
                    {
                      label: 'Valore',
                      backgroundColor: Colori.purple['darken-4'],
                      data: [780, 1390],
                    },
                  ]}
                />

                <!-- Comparazione totale venduto anno corrente & anno precedente-->

                <DivisoreOrizzontaleConIntestazione
                  titolo="Comparazione"
                  didascalia="Totale Venduto"
                />
                <GraficoBarreOrizzontali
                  larghezza={larghezza_grafico}
                  altezza="10rem"
                  etichette={['2021', '2020']}
                  dataset={[
                    {
                      label: 'Valore',
                      backgroundColor: Colori.purple['darken-4'],
                      data: [1280, 720],
                    },
                  ]}
                />
              </div>
            </div>
            <br />
            <br />
          {/await}
        {:else}
          <div class="grid grid-x-center grid-y-center">Pagina non trovata</div>
        {/if}
      </Scrollabile>
    </svelte:fragment>
  </Accesso>
{:catch errore}
  <div
    class="p-4 grid fixed h-full w-full justify-center content-center text-center"
  >
    {errore}
  </div>
{/await}
