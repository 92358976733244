<style lang="scss">
  .contenitore {
    input {
      border-width: 1px;
      transition: border-color 100ms;
    }
    .label {
      transition: color 100ms;
    }
  }
</style>

<script>
  import ComponenteIcona from ':components/icona.svelte'
  import { mdiClose, mdiEye, mdiEyeOff } from '@mdi/js'
  import { tweened } from 'svelte/motion'
  import { scale } from 'svelte/transition'
  export let valore = ''
  export let indizio = ''
  export let icona = ''
  export let password = false
  export let data = false
  export let ora = false
  export let disabilitato = false
  /**
   * @type {false|number}
   */
  export let focalizzato = false

  $: tipo = 'text'

  $: if (password) tipo = 'password'
  $: if (data) tipo = 'date'
  $: if (ora) tipo = 'time'

  /**
   * @type {HTMLInputElement}
   */
  let campo
  let focalizzato_veramente = false

  const PSIZIONE_PREDEFINITA = { X: 20, Y: 8 }

  $: if (focalizzato && campo) {
    campo.focus()
  }
  $: posizione = tweened(PSIZIONE_PREDEFINITA, { duration: 100 })
  $: scala = tweened(1, { duration: 100 })
  $: if (focalizzato_veramente || valore) {
    $posizione = { X: 10, Y: -20 }
    $scala = 0.8
  } else {
    $posizione = PSIZIONE_PREDEFINITA
    $scala = 1
  }
</script>

<div class="pt-2" />
<div
  class="
    contenitore
    relative
    w-full
    h-14
  "
>
  {#if tipo === 'password'}
    <input
      disabled={disabilitato}
      bind:this={campo}
      on:focus={function run() {
        focalizzato_veramente = true
      }}
      on:blur={function run() {
        focalizzato_veramente = false
      }}
      type="password"
      class="
      w-full
      absolute
      left-0
      right-0
      top-0
      bottom-0
      p-4
      bg-transparent
      rounded-3xl
      border-solid
      {focalizzato_veramente ? 'border-violet-700' : 'border-gray-400'}
    "
      bind:value={valore}
    />
  {:else if tipo === 'date'}
    <input
      disabled={disabilitato}
      bind:this={campo}
      on:focus={function run() {
        focalizzato_veramente = true
      }}
      on:blur={function run() {
        focalizzato_veramente = false
      }}
      type="date"
      class="
    w-full
    absolute
    left-0
    right-0
    top-0
    bottom-0
    p-4
    bg-transparent
    rounded-3xl
    border-solid
    {focalizzato_veramente ? 'border-violet-700' : 'border-gray-400'}
  "
      bind:value={valore}
    />
  {:else if tipo === 'time'}
    <input
      disabled={disabilitato}
      bind:this={campo}
      on:focus={function run() {
        focalizzato_veramente = true
      }}
      on:blur={function run() {
        focalizzato_veramente = false
      }}
      type="time"
      class="
    w-full
    absolute
    left-0
    right-0
    top-0
    bottom-0
    p-4
    bg-transparent
    rounded-3xl
    border-solid
    {focalizzato_veramente ? 'border-violet-700' : 'border-gray-400'}
  "
      bind:value={valore}
    />
  {:else}
    <input
      disabled={disabilitato}
      bind:this={campo}
      on:focus={function run() {
        focalizzato_veramente = true
      }}
      on:blur={function run() {
        focalizzato_veramente = false
      }}
      type="text"
      class="
        w-full
        absolute
        left-0
        right-0
        top-0
        bottom-0
        p-4
        bg-transparent
        rounded-3xl
        border-solid
        {focalizzato_veramente ? 'border-violet-700' : 'border-gray-400'}
      "
      bind:value={valore}
    />
  {/if}

  <div
    class="
    label
    absolute
    pointer-events-none
    p-2
    rounded-3xl
    bg-white
    {focalizzato_veramente ? 'text-violet-700' : 'text-gray-400'}
    "
    style="
    left:{$posizione.X}px;
    top:{$posizione.Y}px;
    transform: scale({$scala});
    "
  >
    {#if icona}
      <span transition:scale={{ duration: 100 }}>
        <ComponenteIcona valore={icona} />
      </span>
    {/if}
    <span class="pl-1">{indizio}</span>
  </div>

  {#if password}
    {#if valore}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div
        class="
          absolute
          top-4
          right-14
          {focalizzato_veramente ? 'text-violet-700' : 'text-gray-400'}
          cursor-pointer
        "
        transition:scale={{ duration: 200 }}
        on:click={function run() {
          tipo = tipo === 'password' ? 'text' : 'password'
        }}
      >
        <ComponenteIcona valore={tipo !== 'password' ? mdiEyeOff : mdiEye} />
      </div>
    {:else}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div
        class="
          absolute
          top-4
          right-5
          {focalizzato_veramente ? 'text-violet-700' : 'text-gray-400'}
          cursor-pointer
        "
        transition:scale={{ duration: 200 }}
        on:click={function run() {
          tipo = tipo === 'password' ? 'text' : 'password'
        }}
      >
        <ComponenteIcona valore={tipo !== 'password' ? mdiEyeOff : mdiEye} />
      </div>
    {/if}
  {/if}

  {#if valore}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div
      class="
      absolute
      top-4
      right-5
      {focalizzato_veramente ? 'text-violet-700' : 'text-gray-400'}
      cursor-pointer
    "
      transition:scale={{ duration: 200 }}
      on:click={function run() {
        valore = ''
      }}
    >
      <ComponenteIcona valore={mdiClose} />
    </div>
  {/if}
</div>
<div class="pt-2" />
