<style lang="scss">
  .card {
    --radius: 10px;
    text-transform: uppercase;
    border-radius: var(--radius);
    color: #fff;
    background-color: #fff;
    //background-image: var(--image);
    background-position: center;
    background-size: auto 100%;
    position: relative;
    display: grid;
    margin-bottom: 2rem;
    max-height: 470px;
    grid-template-areas:
      '______'
      'titolo';
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    align-items: flex-end;

    .titolo {
      grid-area: titolo;
      margin: 3rem;
      font-weight: bold;
      font-size: 2rem;
      text-align: left;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);

      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(165, 113, 113, 0.3) 49%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    .______ {
      grid-area: ______;
      min-height: 300px;
    }
  }
</style>

<script>
  import DivisoreOrizzontaleConIntestazione from ':components/divisore-orizzontale-con-intestazione.svelte'
  import { Swiper, SwiperSlide } from 'swiper/svelte'
  import { Pagination } from 'swiper'
  import { store_url_sgp1 } from ':stores/store_url_sgp1'

  /**
   * @type {string}
   */
  export let titolo
  /**
   * @type {string}
   */
  export let didascalia
  /**
   * @type {number}
   */
  export let larghezza
  /**
   * @type {Array<import(':types').Offerta>}
   */
  export let offerte = []
</script>

{#if offerte.length > 0}
  <DivisoreOrizzontaleConIntestazione {titolo} {didascalia} />
  <div class="pt-4" />
  <Swiper
    modules={[Pagination]}
    pagination={{
      bulletActiveClass: 'swiper-pagination-bullet-active amber',
    }}
    spaceBetween={30}
    width={larghezza}
    slidesPerView={larghezza < 400 ? 1 : Math.floor(larghezza / 400)}
  >
    {#each offerte as Offerta}
      <SwiperSlide>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div
          on:click={function run() {
            window.open(
              !Offerta.url
                ? '#'
                : `${$store_url_sgp1}/index.php/component/offerte/?filter_category_id=${Offerta.destinazione_obj.id}`,
            )
          }}
          class="card m-1 pointer"
          style="background-image:url('{Offerta.destinazione_obj.params_obj
            ? `${$store_url_sgp1}/${Offerta.destinazione_obj.params_obj.image}`
            : ''}');"
        >
          <div class="______" />
          <div class="titolo p-1">{Offerta.destinazione_obj.title}</div>
        </div>
      </SwiperSlide>
    {/each}
  </Swiper>
{/if}
