<script>
  import AccessoNonRichiesto from ':components/accesso-non-richiesto.svelte'
  import DivisoreOrizzontaleConIntestazione from ':components/divisore-orizzontale-con-intestazione.svelte'
  import FormImpostazionePassword from ':components/form-impostazione-password.svelte'
  import Progresso from ':components/progresso.svelte'
  import Scrollabile from ':components/scrollabile.svelte'

  import { dominio } from ':scripts/dominio'
  import { fly } from 'svelte/transition'

  /**
   * @type {string}
   */
  export let access_token
  /**
   * @type {string}
   */
  export let account_login_id
  let access_token_scaduto = false
</script>

{#await dominio()}
  <Progresso globale />
{:then dominio}
  <AccessoNonRichiesto>
    <svelte:fragment>
      <Scrollabile let:larghezza>
        {#if access_token_scaduto}
          <div class="p-1 text-center box" transition:fly={{ y: 100 }}>
            <h3>Link scaduto</h3>
          </div>
        {:else}
          <DivisoreOrizzontaleConIntestazione
            titolo="Nuova password"
            didascalia="Minimo 8 caratteri, di cui 1 lettera maiuscola e 1 carattere speciale"
          />
          <div class="mt-3" />
          <div class="p-1 text-center box">
            <FormImpostazionePassword
              {dominio}
              {access_token}
              {account_login_id}
              bind:access_token_scaduto
            />
          </div>
        {/if}
      </Scrollabile>
    </svelte:fragment>
  </AccessoNonRichiesto>
{:catch errore}
  <div
    class="p-4 grid fixed h-full w-full justify-center content-center text-center"
  >
    {errore}
  </div>
{/await}
