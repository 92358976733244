<style lang="scss">
  .Attivo {
    font-weight: bolder;
  }
  .IndicatorePagina {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0.3rem;
    z-index: 99999;
  }
</style>

<script>
  import { mdiChartGantt, mdiFolderHome, mdiMagnify } from '@mdi/js'

  import Icona from ':components/icona.svelte'
  import { onDestroy, onMount } from 'svelte'
  import { navigate } from 'svelte-routing'
  import { spring } from 'svelte/motion'
  import { fly } from 'svelte/transition'
  /**
   * @type {import(':types').NomePagina}
   */
  export let nome_pagine
  /**
   * @type {import(':types').Sessione}
   */
  export let sessione

  /**
   * @type {HTMLButtonElement}
   */
  let pulsante_home
  /**
   * @type {HTMLButtonElement}
   */
  let pulsante_dashboard
  /**
   * @type {HTMLButtonElement}
   */
  let pulsante_pratiche

  /**
   * @type {false | HTMLButtonElement}
   */
  let pulsante_attivo = false

  let posizione_indicatore_pagine = spring(0, {
    damping: 0.3,
    precision: 0.1,
    stiffness: 0.1,
  })

  let larghezza_indicatore_pagina = 0

  $: pulsante_attivo =
    !pulsante_home || !pulsante_dashboard || !pulsante_pratiche
      ? false
      : 'HOME' === nome_pagine
      ? pulsante_home
      : 'DASHBOARD' === nome_pagine
      ? pulsante_dashboard
      : 'PRATICHE' === nome_pagine
      ? pulsante_pratiche
      : false

  $: aggionamento_nome_pagine({ pulsante_attivo })
  /**
   *
   * @param {{pulsante_attivo: false | HTMLButtonElement}} payload
   */
  function aggionamento_nome_pagine({ pulsante_attivo }) {
    if (!pulsante_attivo) {
      return
    }
    const Rettangolo = pulsante_attivo.getBoundingClientRect()
    const X = Rettangolo.x
    const Larghezza = Rettangolo.width
    posizione_indicatore_pagine.set(X)
    larghezza_indicatore_pagina = Larghezza
  }

  function SorvegliaLarghezzaIndicatorePagina() {
    if (pulsante_attivo) {
      larghezza_indicatore_pagina =
        pulsante_attivo.getBoundingClientRect().width
    }

    if (pronto) {
      setTimeout(SorvegliaLarghezzaIndicatorePagina, 1000)
    }
  }

  let pronto = false
  onMount(function run() {
    pronto = true
    SorvegliaLarghezzaIndicatorePagina()
  })
  onDestroy(function run() {
    pronto = false
  })
  $: NumeroColonne = [
    sessione.Utente.VisualizzaHome,
    sessione.Utente.VisualizzaDashboard,
    sessione.Utente.VisualizzaPratiche,
  ].filter(function pass(visualizza) {
    return visualizza
  }).length
</script>

{#if NumeroColonne > 1}
  <div
    class="z-20 fixed text-primary-content left-0 right-0 bottom-0 bg-primary grid grid-cols-{NumeroColonne}"
    transition:fly={{ y: 100 }}
  >
    <div
      class="
      IndicatorePagina
      rounded-full
      bg-primary-content
    "
      style="
      left:{$posizione_indicatore_pagine + 7}px;
      top: -4px;
      width: {larghezza_indicatore_pagina - 14}px;
      height:7px;
    "
    />
    {#if sessione.Utente.VisualizzaHome}
      <button
        bind:this={pulsante_home}
        class="h-16 rounded-none text-center {'HOME' !== nome_pagine ||
          'Attivo'}"
        on:click={async function run() {
          nome_pagine = 'HOME'
          navigate('/')
        }}
      >
        <div class="grid grid-rows-2 justify-items-center">
          <Icona valore={mdiFolderHome} />
          <span> Home </span>
        </div>
      </button>
    {/if}

    {#if sessione.Utente.VisualizzaDashboard}
      <button
        bind:this={pulsante_dashboard}
        class="h-16 rounded-none text-center {'DASHBOARD' !== nome_pagine ||
          'Attivo'}"
        on:click={async function run() {
          nome_pagine = 'DASHBOARD'
          navigate('/dashboard')
        }}
      >
        <div class="grid grid-rows-2 justify-items-center">
          <Icona valore={mdiChartGantt} />
          <span> Dashboard </span>
        </div>
      </button>
    {/if}

    {#if sessione.Utente.VisualizzaPratiche}
      <button
        bind:this={pulsante_pratiche}
        class="h-16 rounded-none text-center {'PRATICHE' !== nome_pagine ||
          'Attivo'}"
        on:click={async function run() {
          nome_pagine = 'PRATICHE'
          navigate('/pratiche')
        }}
      >
        <div class="grid grid-rows-2 justify-items-center">
          <Icona valore={mdiMagnify} />
          <span> Pratiche </span>
        </div>
      </button>
    {/if}
  </div>
{/if}
