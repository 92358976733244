<style>
  :root {
    --snack-bar-background: #333;
    --snack-bar-color: #f1f1f1;
  }
  .snack-bar {
    position: fixed;
    z-index: 9999999999;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    background: var(--snack-bar-background);
    color: var(--snack-bar-color);

    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);

    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.0178571429em;
    line-height: 1.25rem;
    margin-right: auto;
    padding: 7px 16px;
    text-align: initial;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr auto;
  }
  .left {
    align-self: center;
    text-align: initial;
  }
  .right {
    align-self: center;
  }
</style>

<script>
  import { uuid } from ':scripts/uuid'
  import { store_messaggio } from ':stores/store_messaggio'
  import { onDestroy, onMount } from 'svelte'
  import { fly } from 'svelte/transition'

  /**
   * @type {import(':types').MessaggioInterattivo}
   */
  export let messaggio

  let nuovo_id_callback = ''
  onMount(function run() {
    nuovo_id_callback = uuid({})
    let IDCallbackAttuale = nuovo_id_callback
    if (!messaggio.durata) messaggio.durata = 5000

    console.info('Snackbar durerà ', messaggio.durata / 1000, ' secondi.')
    setTimeout(function run() {
      if (IDCallbackAttuale === nuovo_id_callback) {
        //make sure this is not an old callback
        $store_messaggio = false
        if (messaggio.on_scadenza) {
          messaggio.on_scadenza()
        }
      }
    }, messaggio.durata)
  })
  onDestroy(function run() {
    nuovo_id_callback = ''
  })
</script>

<div class="snack-bar rounded-xl" transition:fly={{ y: 100, duration: 300 }}>
  <div class="grid">
    <div class="left">
      {#if Array.isArray(messaggio.testo)}
        {#each messaggio.testo as riga}
          {riga}<br />
        {/each}
      {:else}
        {messaggio.testo}
      {/if}
    </div>
    <div class="right grid grid-cols-10-auto">
      {#if messaggio.bottoni}
        {#each messaggio.bottoni as bottone}
          <button class="btn btn-ghost" on:click={bottone.azione}>
            <span>{bottone.testo}</span>
          </button>
        {/each}
      {:else}
        <button
          class="btn btn-ghost"
          on:click={function run() {
            store_messaggio.set(false)
          }}
        >
          <span>OK</span>
        </button>
      {/if}
    </div>
  </div>
</div>
