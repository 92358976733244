import { writable } from 'svelte/store'

function start() {
  /**
   * @type {import('svelte/store').Writable<false | import(':types').MessaggioInterattivo>}
   */
  const store = writable(false)

  /**
   * @type {false | NodeJS.Timeout}
   */
  let timeout = false

  store.subscribe(function watch($store_messaggio) {
    if (!$store_messaggio) return

    if (!$store_messaggio.durata) $store_messaggio.durata = 5000

    //Interrompo timer precedenti se il messaggio è stato sovrascritto prima della scadenza
    if (timeout) {
      clearInterval(timeout)
      timeout = false
    }

    console.info(
      'Snackbar durerà ',
      $store_messaggio.durata / 1000,
      ' secondi.',
    )
    timeout = setTimeout(function run() {
      store_messaggio.set(false)
      if ($store_messaggio.on_scadenza) $store_messaggio.on_scadenza()
      timeout = false
    }, $store_messaggio.durata)
  })

  return store
}

export const store_messaggio = start()
