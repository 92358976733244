<style>
  .wrapper {
    position: relative;
  }
  .before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  /* canvas{
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
    } */
</style>

<script>
  import { onMount } from 'svelte'
  import { Chart } from 'chart.js'
  import { store_rendering_dashboard } from ':stores/store_rendering_dashboard'

  let style = $$restProps
  delete $$restProps.style

  export let colore_testo = '#000'
  export let larghezza = '100%'
  export let altezza = '100%'
  export let leggenda = true
  export let barre_sovrapposte_x = false
  export let barre_sovrapposte_y = false
  /**
   * @type {'top' | 'bottom' | 'left' | 'right'}
   */
  export let posizione_leggenda = 'top'
  /**
   * @type {'line' | 'bar' | 'horizontalBar' | 'radar' | 'pie' | 'dohnut' | 'polarArea' | 'bubble' | 'scatter'}
   */
  export let tipo = 'bar'
  /**
   * @type {Array<string>}
   */
  export let etichette = []
  /**
   * @type {Array<import(':types').DatasetGrafici>}
   */
  export let dataset = []

  /**
   * @type {number}
   */
  let padding_in_alto
  /**
   * @type {number}
   */
  let padding_in_basso

  /**
   * @type {HTMLCanvasElement}
   */
  let canvas
  /**
   * @type {HTMLDivElement}
   */
  let wrapper
  /**
   * @type {Chart}
   */
  let grafico

  $: aggiornamento({
    colore_testo,
    larghezza,
    altezza,
    leggenda,
    barre_sovrapposte_x,
    barre_sovrapposte_y,
    posizione_leggenda,
    type: tipo,
    labels: etichette,
    datasets: dataset,
    style,
  })

  /**
   * 
   * @param {{
   *  colore_testo: string,
   *  larghezza: string,
   *  altezza: string,
   *  leggenda: boolean,
   *  barre_sovrapposte_x: boolean,
   *  barre_sovrapposte_y: boolean,
   *  posizione_leggenda: 'top' | 'bottom' | 'left' | 'right',
   *  type: 'line' | 'bar' | 'horizontalBar' | 'radar' | 'pie' | 'dohnut' | 'polarArea' | 'bubble' | 'scatter',
   *  labels: Array<string>,
   *  datasets: Array<import(':types').DatasetGrafici>,
   *  style: SvelteRestProps,
  }}payload
   */
  async function aggiornamento({
    colore_testo,
    larghezza,
    altezza,
    leggenda,
    barre_sovrapposte_x,
    barre_sovrapposte_y,
    posizione_leggenda,
    type,
    labels,
    datasets,
    style,
  }) {
    if ($store_rendering_dashboard) return
    $store_rendering_dashboard = true
    setTimeout(function run() {
      requestAnimationFrame(function run() {
        console.log('ridisegnato')
        grafico.update()
        $store_rendering_dashboard = false
      })
    }, 1000)
  }

  onMount(function run() {
    grafico = new Chart(canvas, {
      /** @ts-ignore */
      type: tipo,
      data: {
        labels: etichette,
        /** @ts-ignore */
        datasets: dataset,
      },
      options: {
        responsive: true,

        // Non impostare a "true", deve essere "false" per poter eseguire il resize manualmente.
        // Leggi di più su https://www.chartjs.org/docs/latest/general/responsive.html#important-note
        maintainAspectRatio: false,

        scales: {
          /** @ts-ignore */
          xAxes: [
            {
              stacked: barre_sovrapposte_x,
            },
          ],
          /** @ts-ignore */
          yAxes: [
            {
              stacked: barre_sovrapposte_y,
            },
          ],
        },
        legend: {
          display: leggenda,
          position: posizione_leggenda,
          labels: {
            fontColor: colore_testo,
          },
        },
      },
    })
  })
</script>

<div
  bind:this={wrapper}
  class="wrapper"
  style="width:{larghezza};height:{altezza};padding-top:{padding_in_alto}px;padding-bottom:{padding_in_basso}px;{style
    ? style
    : ''}"
  {...$$restProps}
>
  <div bind:offsetHeight={padding_in_alto} class="before">
    <slot name="before-chart" />
  </div>
  <canvas bind:this={canvas} />
  <div bind:offsetHeight={padding_in_basso} class="after">
    <slot name="after-chart" />
  </div>
</div>
