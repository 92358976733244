import { get, writable } from 'svelte/store'

function inizializza() {
  setInterval(function run() {
    const $store_modalita_pwa = get(store_modalita_pwa)
    if (
      window.matchMedia('(display-mode: standalone)').matches &&
      !$store_modalita_pwa
    ) {
      store_modalita_pwa.set(true)
    } else if (
      !window.matchMedia('(display-mode: standalone)').matches &&
      $store_modalita_pwa
    ) {
      store_modalita_pwa.set(false)
    }
  }, 100)

  return window.matchMedia('(display-mode: standalone)').matches
}

export const store_modalita_pwa = writable(inizializza())
