<script>
  import DivisoreOrizzontaleConIntestazione from ':components/divisore-orizzontale-con-intestazione.svelte'
  import RispostePredefiniteProfilazioneUtente from ':components/risposte-predefinite-profilazione-utente.svelte'
  import { mdiHeadQuestion } from '@mdi/js'

  import CampoDiTesto from ':components/campo-di-testo.svelte'

  /**
   * @type {import(':types').DomandaProfilazioneUtente}
   */
  export let domanda
  export let disabilitato = false
</script>

<RispostePredefiniteProfilazioneUtente Disabilitato={disabilitato} {domanda} />
disabilitato
<br />
<br />
{#if domanda.RispostaAltroAmmessa}
  <div class="p-2">
    <div class="card w-full bg-base-100 shadow-xl">
      <div class="card-body">
        <DivisoreOrizzontaleConIntestazione
          titolo="Oppure"
          didascalia="Aggiungi una risposta personalizzata"
        />
        <CampoDiTesto
          valore={domanda.RispostaAltro}
          indizio="Risposta personalizzata"
          icona={mdiHeadQuestion}
        />
      </div>
    </div>
  </div>
{/if}
