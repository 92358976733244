import axios from 'axios'
import { ok } from ':scripts/ok'
import { store_otp } from ':stores/store_otp'
import { store_url_facilews3 } from ':stores/store_url_facilews3'
import { error } from ':scripts/error'
import { get } from 'svelte/store'

/**
 *
 * @param {{
 *  dominio: import(':types').MetadatiDominio,
 *  sessione: import(':types').Sessione,
 * }} payload
 * @returns {Promise<import(":types").Unsafe<string>>}
 */
export async function crea_nuovo_mini_otp({ dominio, sessione }) {
  const $store_url_facilews3 = get(store_url_facilews3)
  const body = JSON.stringify({ AccountID: sessione.Utente.AccountId })
  console.log('Token:', sessione.Utente.TokenFacileWS)
  try {
    const otp = get(store_otp)
    const risposta = await axios.post(
      // `${$store_url_facilews3}/Api/Rest/${dominio.Codice}/MiniOTP/CreaNuovo?SendBy=mail`,
      `${$store_url_facilews3}/Api/Rest/${dominio.Codice}/MiniOTP/CreaNuovo?Token=${sessione.Utente.TokenFacileWS}&otp=${otp}&SendBy=mail`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      },
    )

    if (risposta.status >= 300) {
      return error(`Request failed with status ${risposta.status}.`)
    }

    const miniOtp = risposta.data

    return ok(miniOtp)
  } catch (e) {
    return error(e)
  }
}
