<script>
  /**
   * @type {any}
   */
  export let trigger_ridisegno = {}
  export let colore_testo = '#000'
  export let larghezza = '100%'
  export let altezza = '100%'
  export let leggenda = true
  export let barre_socrapposte_x = false
  export let barre_sovrapposte_y = false
  /**
   * @type {'top' | 'bottom' | 'left' | 'right'}
   */
  export let posizione_leggenda = 'top'
  /**
   * @type {Array<string>}
   */
  export let etichette
  /**
   * @type {Array<import(':types').DatasetGrafici>}
   */
  export let dataset
</script>

{#await import('./grafico-barre-orizzontali.svelte') then GraficoBarreOrizzontali}
  <GraficoBarreOrizzontali.default
    {trigger_ridisegno}
    {colore_testo}
    {larghezza}
    {altezza}
    {leggenda}
    barre_sovrapposte_x={barre_socrapposte_x}
    {barre_sovrapposte_y}
    {posizione_leggenda}
    {etichette}
    {dataset}
    {...$$restProps}
  >
    <div slot="before"><slot name="before" /></div>
    <div slot="after"><slot name="after" /></div>
  </GraficoBarreOrizzontali.default>
{/await}
