<script>
  import LineaOrizzontale from ':components/linea-orizzontale.svelte'
  import { ordina_per } from ':scripts/ordina_per'
  import { mdiDatabase } from '@mdi/js'
  import { salva_impostazioni_privacy } from ':scripts/salva_impostazioni_privacy'
  import { trova_impostazioni_privacy } from ':scripts/trova_impostazioni_privacy'
  import { createEventDispatcher } from 'svelte'
  import { scale } from 'svelte/transition'
  import Icona from ':components/icona.svelte'
  import Progresso from ':components/progresso.svelte'

  const emit = createEventDispatcher()

  /**
   * @type {Array<import(':types').ImpostazionePrivacy>}
   */
  let impostazioni = []
  /**
   * @type {Promise<Array<import(':types').ImpostazionePrivacy>>}
   */
  let promessa_ricerca = trova_impostazioni_privacy()

  $: console.log('Le impostazioni di privacy sono state cambiate:', {
    impostazioni,
  })

  let salvataggio_consensi_in_corso = false

  async function aggiorna() {
    salvataggio_consensi_in_corso = true
    let Ok = true
    for (let impostazione of impostazioni) {
      Ok = await salva_impostazioni_privacy({ impostazione })
      if (!Ok) {
        salvataggio_consensi_in_corso = false
        return
      }
    }
    if (Ok) emit('salvataggio')
    salvataggio_consensi_in_corso = false
  }

  function ApriInformativaPrivacy() {
    window.open('https://exactagroup.it/informativa-privacy')
  }
</script>

{#await promessa_ricerca}
  <div
    class="p-4 grid w-full h-full justify-center content-center text-center"
    transition:scale
  >
    <Progresso />
  </div>
{:then impostazioni_remote}
  {#if impostazioni_remote.length > 0}
    {#each [...impostazioni_remote].sort(ordina_per( { chiave: 'Ordine' }, )) as impostazione_remote}
      <div class="pt-2" />
      <div class="w-full p-4 bg-slate-100 rounded-xl">
        <div class="pt-2" />
        <div class="w-full text-center p-4 bg-slate-200 rounded-xl">
          <span>{impostazione_remote.Descrizione}</span>
        </div>
        <div class="pt-8" />
        <div class="w-full flex flex-wrap justify-center">
          <button
            class="btn"
            disabled={salvataggio_consensi_in_corso}
            class:btn-primary={impostazione_remote.ConsensoAccordato === 1}
            class:btn-ghost={impostazione_remote.ConsensoAccordato !== 1}
            on:click={function run() {
              impostazione_remote.ConsensoAccordato = 1
            }}
          >
            <span>Acconsento</span>
          </button>
          <div class="pl-2" />
          <button
            class="btn btn-ghost"
            disabled={salvataggio_consensi_in_corso}
            class:btn-primary={impostazione_remote.ConsensoAccordato === 0}
            class:btn-ghost={impostazione_remote.ConsensoAccordato !== 0}
            on:click={function run() {
              impostazione_remote.ConsensoAccordato = 0
            }}
          >
            <span>Non Acconsento</span>
          </button>
        </div>
        <div class="pt-2" />
      </div>
    {/each}
    <div class="pt-8" />
    <div class="w-full text-center">
      <button class="btn btn-primary" on:click={ApriInformativaPrivacy}>
        <span class="pr-2">Leggi la nostra informativa sulla privacy</span>
      </button>
    </div>

    <div class="pt-8" />
  {:else}
    <div class="w-full text-center">
      Questo account non dispone di alcune impostazione di privacy.
    </div>
    <div class="pt-8" />
  {/if}
  <LineaOrizzontale />
  <div class="w-full grid justify-center pt-4">
    {#if salvataggio_consensi_in_corso}
      <Progresso />
    {:else}
      <button
        class="btn btn-primary"
        disabled={salvataggio_consensi_in_corso}
        on:click={function run() {
          if (impostazioni_remote.length > 0) {
            ;[...impostazioni_remote]
              .sort(ordina_per({ chiave: 'Ordine' }))
              .forEach(function pass(impostazione_remote, i) {
                impostazioni[i] = impostazione_remote
              })
          }
          aggiorna()
        }}
      >
        <span class="pr-2">Salva</span>
        <Icona valore={mdiDatabase} />
      </button>
    {/if}
  </div>
{:catch errore}
  <div class="w-full text-center">
    {errore}
  </div>
{/await}
