<script>
  import Attivatore from ':components/attivatore.svelte'
  import CampoDiTesto from ':components/campo-di-testo.svelte'
  import Popup from ':components/popup.svelte'
  import { notifica } from ':scripts/notifica'
  import { store_notifiche_abilitate } from ':stores/store_notifiche_abilitate'
  import { mdiBell } from '@mdi/js'
  import { fly } from 'svelte/transition'
  export let visibile = false
  /**
   * @type {boolean}
   */
  export let visualizza_app_bar_principale

  let titolo_notifica_di_prova = 'Notifica di prova'
  let testo_notifica_di_prova = 'Questo è il corpo della notifica'

  function TestNotifica() {
    if ($store_notifiche_abilitate) {
      notifica({
        titolo: titolo_notifica_di_prova,
        testo: testo_notifica_di_prova,
      })
    }
  }
</script>

<Popup
  bind:visibile
  icona={mdiBell}
  titolo="Impostazioni notifiche"
  on:intenzione-apertura={function run() {
    visualizza_app_bar_principale = false
  }}
  on:intenzione-chiusura={function run() {
    visualizza_app_bar_principale = true
  }}
>
  <Attivatore
    bind:attivo={$store_notifiche_abilitate}
    testo="Abilita notifiche push"
  />

  {#if $store_notifiche_abilitate}
    <div transition:fly={{ x: -100 }}>
      <div class="pt-2" />
      <CampoDiTesto
        indizio="Titolo notifica"
        bind:valore={titolo_notifica_di_prova}
      />
      <div class="pt-2" />
      <CampoDiTesto
        indizio="Testo notifica"
        bind:valore={testo_notifica_di_prova}
      />
      <div class="pt-4" />
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div class="w-full btn btn-primary" on:click={TestNotifica}>
        <span>Invia notifica</span>
      </div>
      <div class="pt-4" />
    </div>
  {/if}
</Popup>
