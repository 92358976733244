import axios from 'axios'
import { store_otp } from ':stores/store_otp'
import { store_url_facilews3 } from ':stores/store_url_facilews3'
import { get } from 'svelte/store'
import { IN_SVILUPPO } from ':constants'

/**
 * Ottiene allegati pratica
 * @param {{sessione:import(':types').Sessione,pratica:import(':types').Pratica}} payload
 */
export async function allegati({ sessione, pratica }) {
  try {
    const $store_url_facilews3 = get(store_url_facilews3)
    const $store_otp = get(store_otp)
    let token = `&Token=${sessione.Utente.TokenFacileWS}`
    let otp_stringified = `otp=${$store_otp}`
    if (token.toLowerCase() === '&token=') {
      token = ''
    }

    if (otp_stringified.toLowerCase() === '&otp=') {
      otp_stringified = ''
    }

    /**
     * @type {import(':types').AxiosResponse<import(':types').RispostaFacileWS3<Array<import(':types').AllegatoPratica>>>}
     */
    const risposta = await axios.get(
      `${$store_url_facilews3}/Api/Rest/${sessione.Utente.CodiceAgenzia}/Pratica/${pratica.PraticaID}/Allegati?${otp_stringified}${token}`,
      {
        headers: {
          codiceagenzia: sessione.Utente.CodiceAgenzia,
          accesstoken: sessione.Utente.AccessToken,
        },
      },
    )

    const link_allegati =
      risposta.data.data?.filter(function pass(allegato) {
        return (
          allegato.LinkAllegato &&
          '' !== allegato.LinkAllegato &&
          '' !== allegato.LinkAllegato.trim()
        )
      }) || []

    if (0 !== link_allegati.length) {
      return link_allegati
    }

    return (
      risposta.data.data?.filter(function pass(allegato) {
        return (
          allegato.NomeFile.startsWith('http:') ||
          allegato.NomeFile.startsWith('https:') ||
          allegato.NomeFile.includes('/')
        )
      }) || []
    )
  } catch (e) {
    console.warn(e)
    return []
  }
}

/**
 * @param {{allegati_promise: Promise<Array<import(':types').AllegatoPratica>>}} payload
 */
export async function allegati_sviluppo({ allegati_promise }) {
  const allegati = await allegati_promise
  if (0 === allegati.length && IN_SVILUPPO) {
    const elenco = [
      {
        AgenziaID: '',
        AllegatoID: '1',
        AllegatoTipoID: '',
        AnnoPeriodicita: 1,
        ApriAllegato: '',
        DataFirma: 'Da firmare',
        DataInserimento: '',
        Descrizione: '',
        DescrizioneParent: '',
        FlagAnnullato: 0,
        Idrecord: '',
        IsLink: 1,
        MesePeriodicita: 1,
        NomeFile: '',
        NomeTabella: '',
        Pubblico: 1,
        SubIdrecord: '',
        Suffisso: '',
        TipoParent: '',
        VisibileInGestioneDocumentale: 1,
        Vistato: 1,
        LinkAllegato: '',
      },
    ]
    return elenco
  } else {
    return allegati
  }
}
