<script>
  import Popup from ':components/popup.svelte'
  import { mdiFormatColorFill } from '@mdi/js'

  export let visibile = false
  /**
   * @type {import(':types').MetadatiDominio}
   */
  export let dominio
  /**
   * @type {boolean}
   */
  export let visualizza_app_bar_principale

  // export let colore_primario = ''
  // export let colore_secondario = ''
  // export let colore_tinta = ''
  // export let colore_testo = ''

  function logo() {
    if ('' == dominio.Immagine) {
      return `/static/assets/images/splash.jpg`
    } else if (
      dominio.Immagine.startsWith('http') ||
      dominio.Immagine.startsWith('/')
    ) {
      return `${dominio.Immagine}`
    } else {
      return `data:image/jpeg;base64,${dominio.Immagine}`
    }
  }
</script>

<Popup
  bind:visibile
  icona={mdiFormatColorFill}
  titolo="Personalizza"
  on:intenzione-apertura={function run() {
    visualizza_app_bar_principale = false
  }}
  on:intenzione-chiusura={function run() {
    visualizza_app_bar_principale = true
  }}
>
  <div
    class="p-4 relative h-full w-full justify-center content-center text-center"
  >
    In sviluppo
  </div>
</Popup>
