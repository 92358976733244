import { get, writable } from 'svelte/store'

function inizializza() {
  const store = writable(location.pathname)

  const update = function run() {
    const $store = get(store)
    if ($store !== location.pathname) store.set(location.pathname)
    setTimeout(function run() {
      requestAnimationFrame(update)
    }, 1000)
  }

  update()

  return store
}

export const store_pathname = inizializza()
