import axios from 'axios'
import { IOTNULL } from ':constants'
import { store_messaggio } from ':stores/store_messaggio'
import { store_url_sferanet } from ':stores/store_url_sferanet'
import { get } from 'svelte/store'

/**
 *
 * @param {{
 *  dominio: import(':types').MetadatiDominio,
 *  nome_utente: string,
 *  cognome: string,
 *  nome: string,
 * }} payload
 * @returns
 */
export async function crea_account({ dominio, nome_utente, cognome, nome }) {
  try {
    const $store_messaggio = get(store_messaggio)
    const $store_url_sferanet = get(store_url_sferanet)
    // store_accesso_in_corso.set(true)

    switch ('') {
      case nome_utente.trim():
        if (!$store_messaggio || !$store_messaggio.priorita)
          store_messaggio.set({ testo: 'Inserire Utente' })
        // store_accesso_in_corso.set(false)
        return false
      case cognome.trim():
        if (!$store_messaggio || !$store_messaggio.priorita)
          store_messaggio.set({
            testo: 'Inserire Cognome',
          })
        // store_accesso_in_corso.set(false)
        return false
    }

    /**
     * @type {import(':types').AxiosResponse<{ data: string; code: number }>}
     */
    const risposta = await axios.post(
      `${$store_url_sferanet}/CompFacileLogin/Auth.json`,
      JSON.stringify({
        email: nome_utente,
        cognome: cognome,
        nome: nome,
        indirizzo1: '',
        indirizzo2: '',
        telefonoCasa: '',
        telefonoCellulare: '',
      }),
      {
        headers: {
          'codiceagenzia': dominio.Codice,
          'x-http-method-override': 'Create',
          'content-type': 'application/json',
        },
      },
    )

    // @ts-ignore
    if (IOTNULL === risposta.data) {
      store_messaggio.set({ testo: IOTNULL })
      return false
    }

    if (risposta.status >= 300 || risposta.data.code >= 300) return false

    return true
  } catch (errore) {
    store_messaggio.set({ testo: `${errore}` })
    console.warn(errore)
    return false
  }
}
