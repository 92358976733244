<style lang="scss">
  .card {
    $radius: 10px;
    text-transform: uppercase;
    border-radius: $radius;
    color: #fff;
    background-color: #fff;
    //background-image: var(--image);
    background-position: center;
    background-size: cover;
    position: relative;
    display: grid;
    margin-bottom: 2rem;
    max-height: 470px;
    justify-items: center;

    .titolo {
      font-weight: bold;
      // font-size: 1.5rem;
      text-align: left;
      display: grid;
      align-items: flex-end;
      width: calc(100% - 2rem);
      text-align: center;

      overflow: hidden;

      .contenuto-titolo {
        // border-top: 1px solid rgba(255, 255, 255, 0.3);
        // border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        // background: linear-gradient(
        //   90deg,
        //   rgba(255, 255, 255, 0) 0%,
        //   rgba(165, 113, 113, 0.3) 49%,
        //   rgba(255, 255, 255, 0) 100%
        // );
        // margin-bottom: 0.5rem;

        background-color: #ffc107d6;
        border-color: #ffc107d6;
        border-radius: 20px;
        font-size: 1.2rem;
        text-overflow: ellipsis;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>

<script>
  import { navigate } from 'svelte-routing'

  /**
   * @type {import(':types').Pratica}
   */
  export let pratica

  function testo_aggiuntivo() {
    return pratica.DescrizioneDestinazioneSintetica
      ? `- ${pratica.DescrizioneDestinazioneSintetica}`
      : pratica.Descrizione
      ? `- ${pratica.Descrizione}`
      : ``
  }
  function immagine() {
    if (
      pratica.ImmagineDiCopertina != '' &&
      pratica.ImmagineDiCopertina != null
    ) {
      return pratica.ImmagineDiCopertina
    } else {
      return '/images/viaggi.webp'
    }
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  on:click={function run() {
    // f7router.navigate(`/pratica/${pratica.NumeroPratica}`)
    navigate(`/pratica/${pratica.NumeroPratica}`)
  }}
  class="card pointer m-1 {'' === testo_aggiuntivo() ? 'text-center' : ''}"
  style="background-image:url({immagine()});"
>
  <div class="titolo" style="height:400px">
    <div class="contenuto-titolo p-1 mb-10">
      {pratica.NumeroPratica}
      {testo_aggiuntivo().length > 40
        ? `${testo_aggiuntivo()}...`
        : testo_aggiuntivo()}
    </div>
  </div>
</div>
