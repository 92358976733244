/**
 *
 * @param {{chiave:string | number, inverti?:boolean}} payload
 * @returns
 */
export function ordina_per({ chiave, inverti = false }) {
  const sposta_il_minore = inverti ? 1 : -1
  const sposta_il_maggiore = inverti ? -1 : 1

  /**
   * @param {any} a
   * @param {any} b
   */
  return function run(a, b) {
    if (a === null || b === null) return 0
    if (a[chiave] < b[chiave]) {
      return sposta_il_minore
    }
    if (a[chiave] > b[chiave]) {
      return sposta_il_maggiore
    }
    return 0
  }
}
