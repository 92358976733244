export function sferanetqbe() {
  /**
   * @type {Record<string, import(":types").SferaNetQBE>}
   */
  const query = {}

  const opzioni = {
    /**
     * @param {{chiave:string,valore:string}} payload
     */
    like({ chiave, valore }) {
      return opzioni.uguale({ chiave, valore: `%${valore}%` })
    },
    /**
     * @param {{chiave:string,valore:string|number}} payload
     */
    uguale({ chiave, valore }) {
      if (!query[chiave + '']) query[chiave + ''] = {}
      if (!query[chiave + ''].uguale) query[chiave + ''].uguale = []
      /** @ts-ignore */
      query[chiave + ''].uguale.push(`${valore}`)
      return opzioni
    },
    /**
     * @param {{chiave:string,valore:string|number}} payload
     */
    non_uguale({ chiave, valore }) {
      if (!query[chiave + '']) query[chiave + ''] = {}
      if (!query[chiave + ''].non_uguale) query[chiave + ''].non_uguale = []
      /** @ts-ignore */
      query[chiave + ''].non_uguale.push(`${valore}`)
      return opzioni
    },
    /**
     * @param {{chiave:string,valore:number}} payload
     */
    maggiore_id({ chiave, valore }) {
      if (!query[chiave + '']) query[chiave + ''] = {}
      if (!query[chiave + ''].maggiore_di) query[chiave + ''].maggiore_di = []
      /** @ts-ignore */
      query[chiave + ''].maggiore_di.push(`${valore}`)
      /** @ts-ignore */
      return opzioni
    },
    /**
     * @param {{chiave:string,valore:number}} payload
     */
    minore_di({ chiave, valore }) {
      if (!query[chiave + '']) query[chiave + ''] = {}
      if (!query[chiave + ''].minore_di) query[chiave + ''].minore_di = []
      /** @ts-ignore */
      query[chiave + ''].minore_di.push(`${valore}`)
      return opzioni
    },
    /**
     * @param {{chiave:string}} payload
     */
    vuoto({ chiave }) {
      if (!query[chiave + '']) query[chiave + ''] = {}
      if (!query[chiave + ''].vuoto) query[chiave + ''].vuoto = true
      return opzioni
    },
    /**
     * @param {{chiave:string}} payload
     */
    non_vuoto({ chiave }) {
      if (!query[chiave + '']) query[chiave + ''] = {}
      if (!query[chiave + ''].non_vuoto) query[chiave + ''].non_vuoto = true
      return opzioni
    },
    /**
     *
     * @param {{
     *  chiave: string,
     *  inizio: string|number,
     *  fine: string|number,
     * }} payload
     */
    tra({ chiave, inizio, fine }) {
      if (!query[chiave + '']) query[chiave + ''] = {}
      if (!query[chiave + ''].tra) query[chiave + ''].tra = []
      /** @ts-ignore */
      query[chiave + ''].tra.push({ inizio, fine })
      return opzioni
    },
    count() {
      return Object.keys(query).length
    },

    toString() {
      const qss = []
      for (const colmnName in query) {
        const operations = query[colmnName]
        let qs = ''
        let i = 0
        for (const operationName in operations) {
          switch (operationName) {
            case 'uguale':
              qs += encodeURIComponent(colmnName)
              qs += i > 0 ? ';' : '='
              /** @ts-ignore */
              for (let j = 0; j < operations[operationName].length; j++) {
                qs +=
                  (j > 0 ? ';=' : '') +
                  /** @ts-ignore */
                  encodeURIComponent(operations[operationName][j])
              }
              break
            case 'nonUguale':
              qs += encodeURIComponent(colmnName)
              qs += i > 0 ? ';' : '=#'
              /** @ts-ignore */
              for (let j = 0; j < operations[operationName].length; j++)
                qs +=
                  (j > 0 ? ';=#' : '') +
                  /** @ts-ignore */
                  encodeURIComponent(operations[operationName][j])
              break
            case 'maggioreDi':
              qs += encodeURIComponent(colmnName)
              qs += i > 0 ? ';' : '=>'
              /** @ts-ignore */
              for (let j = 0; j < operations[operationName].length; j++)
                qs +=
                  (j > 0 ? ';=>' : '') +
                  /** @ts-ignore */
                  encodeURIComponent(operations[operationName][j])
              break

            case 'minoreDi':
              qs += encodeURIComponent(colmnName)
              qs += i > 0 ? ';' : '=<'
              /** @ts-ignore */
              for (let j = 0; j < operations[operationName].length; j++)
                qs +=
                  (j > 0 ? ';=<' : '') +
                  /** @ts-ignore */
                  encodeURIComponent(operations[operationName][j])
              break

            case 'vuoto':
              qs += encodeURIComponent(colmnName)
              qs += i > 0 ? ';' : '='
              qs += operations[operationName] ? '!' : ''
              break

            case 'nonVuoto':
              qs += encodeURIComponent(colmnName)
              qs += i > 0 ? ';' : '='
              qs += operations[operationName] ? '.' : ''
              break

            case 'tra':
              qs += encodeURIComponent(colmnName)
              qs += i > 0 ? ';' : '='
              /** @ts-ignore */
              for (let j = 0; j < operations[operationName].length; j++)
                qs +=
                  (j > 0 ? ';' : '') +
                  /** @ts-ignore */
                  encodeURIComponent(operations[operationName][j].inizio) +
                  ':' +
                  /** @ts-ignore */
                  encodeURIComponent(operations[operationName][j].fine)
              break
          }
          i++
        }
        qss.push(qs)
      }

      return qss.join('&')
    },
  }

  return opzioni
}
