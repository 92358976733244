import { canvas_2d } from ':scripts/canvas_2d'

/**
 * Ridimensiona un image data
 * @param {{ immagine: ImageData, scala: number}} payload
 * @param Scala
 */
export async function scala_immagine({ immagine, scala }) {
  const larghezza_in_scala = (immagine.width * scala) >> 0
  const altezza_in_scala = (immagine.height * scala) >> 0
  const ibm = await window.createImageBitmap(
    immagine,
    0,
    0,
    immagine.width,
    immagine.height,
    {
      resizeWidth: larghezza_in_scala,
      resizeHeight: altezza_in_scala,
    },
  )

  const { contesto } = canvas_2d({
    larghezza: larghezza_in_scala,
    altezza: altezza_in_scala,
  })
  contesto.drawImage(ibm, 0, 0)
  return contesto.getImageData(0, 0, larghezza_in_scala, altezza_in_scala)
}
