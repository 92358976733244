import { pratiche } from ':scripts/pratiche'
import { error } from ':scripts/error'
import { ok } from ':scripts/ok'

/**
 *
 * @param {{
 *  sessione: import(':types').Sessione,
 *  numero_pratica: string,
 * }} payload
 * @returns {Promise<import(':types').Unsafe<import(':types').Pratica>>}
 */
export async function pratica({ sessione, numero_pratica }) {
  try {
    const [elenco_pratiche, errore] = await pratiche({
      sessione,
      filtro: {
        DataVendita: [false, false],
        DataPartenza: [false, false],
        DataRientro: [false, false],
        DataCreazione: [false, false],
        NumeroAllegati: [0, 99999],
        NumeroPratica: numero_pratica,
        Destinazione: '',
        Descrizione: '',
        CognomeNomeCliente: '',
      },
    })

    if (errore) {
      return error(errore)
    }

    if (0 === elenco_pratiche.length) {
      return error('Pratica non trovata.')
    }

    return ok(elenco_pratiche[0])
  } catch (e) {
    console.warn(e)
    return error(e)
  }
}
