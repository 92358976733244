<style>
  .grid {
    column-gap: 1rem;
  }

  @media only screen and (max-width: 900px) {
    .other-logins-full-width {
      grid-template-columns: 1fr;
      column-gap: 0.5rem;
      row-gap: 0.5rem;
    }
  }
</style>

<script>
  import ButtoneTerzeParti from ':components/bottone-terze-parti.svelte'
  import { mdiFacebook, mdiGoogle } from '@mdi/js'

  import { facebook } from ':scripts/facebook'
  import { google } from ':scripts/google'
  import { uri } from ':scripts/uri'
  import { onMount } from 'svelte'
  /**
   * @type {import(':types').MetadatiDominio}
   */
  export let dominio
  /**
   * @type {false | string }
   */
  export let AccessTokenGoogle = false
  /**
   * @type {false | string}
   */
  export let AccessTokenFacebook = false

  onMount(function start() {
    if (dominio.AbilitaAccessoConGoogle) {
      /**
       * @type {{ state: string; access_token: string }}
       */
      const opzioni = uri({ valore: location.hash.substring(1) }).decode()
      if ('google' === opzioni.state && opzioni.access_token)
        AccessTokenGoogle = opzioni.access_token
      else if ('facebook' === opzioni.state && opzioni.access_token)
        AccessTokenFacebook = opzioni.access_token
    }
  })
</script>

{#if dominio.AbilitaAccessoConGoogle || dominio.AbilitaAccessoConFacebook}
  <div
    class="grid {dominio.AbilitaAccessoConGoogle &&
    dominio.AbilitaAccessoConFacebook
      ? 'grid-cols-2'
      : ''} full-width other-logins-full-width mb-2"
  >
    {#if dominio.AbilitaAccessoConGoogle}
      <ButtoneTerzeParti
        aspetto="rosso"
        testo="Accedi con Google"
        icona={mdiGoogle}
        on:click={function run() {
          google.redirect({
            destinazione: `${location.protocol}//${location.host}`,
          })
        }}
      />
    {/if}
    {#if dominio.AbilitaAccessoConFacebook}
      <ButtoneTerzeParti
        aspetto="blu"
        testo="Accedi con Facebook"
        icona={mdiFacebook}
        on:click={function run() {
          facebook.redirect({
            destinazione: `${location.protocol}//${location.host}`,
          })
        }}
      />
    {/if}
  </div>
{/if}
