<script>
  import AccessoNonRichiesto from ':components/accesso-non-richiesto.svelte'
  import AppBarVisitatore from ':components/app-bar-visitatore.svelte'
  import FormRegistrazioneEsplicito from ':components/form-registrazione-esplicito.svelte'
  import FormRegistrazioneImplicito from ':components/form-registrazione-implicito.svelte'
  import LineaOrizzontale from ':components/linea-orizzontale.svelte'
  import Popup from ':components/popup.svelte'
  import Scrollabile from ':components/scrollabile.svelte'
  import CampoDiTesto from ':components/campo-di-testo.svelte'
  import Icona from ':components/icona.svelte'
  import Progresso from ':components/progresso.svelte'
  import {
    mdiAccount,
    mdiAccountBoxMultiple,
    mdiEmail,
    mdiFaceMan,
    mdiPageNext,
    mdiSend,
  } from '@mdi/js'
  import { crea_lead } from ':scripts/crea_lead'
  import { dominio } from ':scripts/dominio'
  import { esci } from ':scripts/esci'
  import { navigate } from 'svelte-routing'
  import { IN_SVILUPPO } from ':constants'

  export let nome = ''
  export let cognome = ''
  export let email = ''

  let invio_in_corso = false
  let visualizza_app_bar_visitatore = true

  const regole_email = [
    /**
     * @param {{valore:string}} payload
     */
    function pass({ valore }) {
      return !!valore || 'Richiesto'
    },
    /**
     * @param {{valore:string}} payload
     */
    function pass({ valore }) {
      return valore.length <= 256 || 'Massimo 256 caratteri'
    },
    /**
     * @param {{valore:string}} payload
     */
    function run({ valore }) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(valore) || 'Indirizzo e-mail non valido'
    },
  ]

  let conferma_visibile = false
</script>

{#await dominio()}
  <Progresso globale />
{:then dominio}
  <AccessoNonRichiesto>
    <svelte:fragment>
      {#if visualizza_app_bar_visitatore}
        <AppBarVisitatore titolo="Registrazione" />
      {/if}
      <Scrollabile>
        <div class="pt-6" />
        <div class="pl-3 pr-3">
          <div class="text-lg w-full grid justify-start">
            <div class="bg-slate-100 rounded-full p-4">
              <div class="flex flex-wrap">
                <Icona valore={mdiAccountBoxMultiple} />
                <span class="pl-2">Registrazione nuovo utente</span>
              </div>
            </div>
          </div>
          <br />
          {#if 'esplicito' === dominio.TipoFormRegistrazione}
            <FormRegistrazioneEsplicito>
              <svelte:fragment slot="Nome">
                <CampoDiTesto
                  indizio="Nome"
                  bind:valore={nome}
                  icona={mdiFaceMan}
                />
              </svelte:fragment>
              <svelte:fragment slot="Cognome">
                <CampoDiTesto
                  indizio="Cognome"
                  bind:valore={cognome}
                  icona={mdiFaceMan}
                />
              </svelte:fragment>
              <svelte:fragment slot="Email">
                <CampoDiTesto
                  indizio="Email"
                  bind:valore={email}
                  icona={mdiEmail}
                />
              </svelte:fragment>
              <svelte:fragment
                slot="Invia"
                let:NonInviareOfferte
                let:InformativaPrivacy
                let:InformativaCookie
              >
                <button
                  class="btn btn-primary"
                  disabled={conferma_visibile ||
                    invio_in_corso ||
                    !InformativaPrivacy ||
                    !InformativaCookie ||
                    regole_email.filter(function pass(regola) {
                      return true === regola({ valore: email })
                    }).length !== regole_email.length}
                  on:click={async function run() {
                    invio_in_corso = true
                    conferma_visibile = await crea_lead({
                      dominio,
                      email,
                      cognome,
                      nome,
                      stato_consenso_marketing_profilazione: NonInviareOfferte
                        ? 'NOACC'
                        : 'ACC',
                      stato_consenso_marketing_contatto: NonInviareOfferte
                        ? 'NOACC'
                        : 'ACC',
                    })
                    invio_in_corso = false
                  }}
                >
                  <span>Continua {IN_SVILUPPO ? '(Esplicito)' : ''}&nbsp;</span>
                  <Icona valore={mdiSend} />
                </button>
              </svelte:fragment>
            </FormRegistrazioneEsplicito>
          {:else}
            <FormRegistrazioneImplicito {dominio}>
              <svelte:fragment slot="Nome">
                <CampoDiTesto
                  indizio="Nome"
                  bind:valore={nome}
                  icona={mdiFaceMan}
                />
              </svelte:fragment>
              <svelte:fragment slot="Cognome">
                <CampoDiTesto
                  indizio="Cognome"
                  bind:valore={cognome}
                  icona={mdiFaceMan}
                />
              </svelte:fragment>
              <svelte:fragment slot="Email">
                <CampoDiTesto
                  indizio="Email"
                  bind:valore={email}
                  icona={mdiEmail}
                />
              </svelte:fragment>
              <svelte:fragment slot="Invia" let:NonInviareOfferte>
                <button
                  class="btn btn-primary"
                  disabled={conferma_visibile ||
                    invio_in_corso ||
                    regole_email.filter(function pass(regola) {
                      return true === regola({ valore: email })
                    }).length !== regole_email.length}
                  on:click={async function run() {
                    invio_in_corso = true
                    conferma_visibile = await crea_lead({
                      dominio,
                      email,
                      cognome,
                      nome,
                      stato_consenso_marketing_profilazione: NonInviareOfferte
                        ? 'NOACC'
                        : 'ACC',
                      stato_consenso_marketing_contatto: NonInviareOfferte
                        ? 'NOACC'
                        : 'ACC',
                    })
                    invio_in_corso = false
                  }}
                >
                  {#if invio_in_corso}
                    <Progresso />
                  {:else}
                    <span class="pr-2">
                      <span>Continua {IN_SVILUPPO ? '(Implicito)' : ''}</span>
                    </span>
                    <Icona valore={mdiPageNext} />
                  {/if}
                </button>
              </svelte:fragment>
            </FormRegistrazioneImplicito>
          {/if}

          <Popup
            bind:visibile={conferma_visibile}
            icona={mdiAccount}
            titolo="Account registrato"
            mostra_pulsante_chiusura={false}
            on:intenzione-apertura={function run() {
              visualizza_app_bar_visitatore = false
            }}
            on:intenzione-chiusura={function run() {
              visualizza_app_bar_visitatore = true
            }}
          >
            <p class="grid grid-x-center">
              Il tuo account è stato registrato con successo.<br />
              Abbiamo inviato un'email con istruzioni per attivare il tuo account
              all'indirizzo
              {email}.<br />
            </p>
            <LineaOrizzontale />
            <small class="grid grid-x-center mt-3 mb-3">
              Ricordati di controllare lo spam.
            </small>

            <div class="grid grid-x-center">
              <button
                class="btn btn-primary"
                on:click={function run() {
                  esci()
                  navigate('/')
                }}
              >
                <span>Torna al login</span>
              </button>
            </div>
          </Popup>
        </div>
      </Scrollabile>
    </svelte:fragment>
  </AccessoNonRichiesto>
{:catch errore}
  <div
    class="p-4 grid fixed h-full w-full justify-center content-center text-center"
  >
    {errore}
  </div>
{/await}
