import { writable } from 'svelte/store'
import { IN_SVILUPPO } from ':constants'

// export const store_indirizzo_app = writable(
//   IN_SVILUPPO
//     ? 'https://sferanetapp.it'
//     : `${location.protocol}//${location.host}`, //PROD
// )
export const store_indirizzo_app = writable(
  IN_SVILUPPO
    ? 'https://sferanetappalpha.partnersolution.it'
    : `${location.protocol}//${location.host}`, //PROD
)
