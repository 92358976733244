<script>
  import AccessoNonRichiesto from ':components/accesso-non-richiesto.svelte'
  import Scrollabile from ':components/scrollabile.svelte'
  import axios from 'axios'
  import { store_url_sferanet } from ':stores/store_url_sferanet'
  import { dominio } from ':scripts/dominio'
  import { navigate } from 'svelte-routing'

  import Progresso from ':components/progresso.svelte'

  /**
   * @type {string}
   */
  export let cat_account_id

  /**
   *
   * @param {{dominio:import(':types').MetadatiDominio}} payload
   */
  function importa({ dominio }) {
    return axios.post(
      `${$store_url_sferanet}/CompFacileLogin/CRMLeadService.json`,
      JSON.stringify({ CATAccountID: cat_account_id }),
      {
        headers: {
          'codiceagenzia': dominio.Codice,
          'x-http-method-override': 'Import',
          'Content-Type': 'application/json',
        },
      },
    )
  }
</script>

{#await dominio()}
  <Progresso globale />
{:then dominio}
  <AccessoNonRichiesto>
    <svelte:fragment>
      <Scrollabile>
        <div class="grid grid-x-center">
          <img src="/static/assets/images/onboard.png" alt="" />
          <br />
          {#await importa({ dominio })}
            <Progresso />
          {:then value}
            <h5 style="text-align:center">Lieti di averti a bordo!</h5>
            <div class="pt-2" />
            <button
              class="btn btn-primary"
              on:click={function run() {
                navigate('/')
              }}
            >
              <span>Esegui l'accesso</span>
            </button>
          {:catch error}
            <h5 style="text-align:center">
              Non è stato possibile confermare l'account.
            </h5>
            <code>{error}</code><br />
            <button
              class="btn btn-primary"
              on:click={function run() {
                navigate('/')
              }}
            >
              <span>Torna alla pagina principale</span>
            </button>
          {/await}
        </div>
      </Scrollabile>
    </svelte:fragment>
  </AccessoNonRichiesto>
{:catch errore}
  <div
    class="p-4 grid fixed h-full w-full justify-center content-center text-center"
  >
    {errore}
  </div>
{/await}
