import axios from 'axios'
import { get } from 'svelte/store'
import { IOTNULL, Status } from ':constants'
import { store_messaggio } from ':stores/store_messaggio'
import { store_url_sferanet } from ':stores/store_url_sferanet'

/**
 *
 * @param {{
 *  dominio: import(':types').MetadatiDominio,
 *  access_token: string,
 *  account_login_id: string,
 *  password: string,
 * }} payload
 * @returns
 */
export async function imposta_password({
  dominio,
  access_token,
  account_login_id,
  password,
}) {
  try {
    const $store_url_sferanet = get(store_url_sferanet)
    /**
     * @type {import(':types').AxiosResponse<import(':types').RispostaSferaNet<string>>}
     */
    const risposta = await axios.post(
      `${$store_url_sferanet}/CompFacileLogin/Auth.json`,
      JSON.stringify({
        AccountLoginID: account_login_id,
        store_password: password,
      }),
      {
        headers: {
          'x-http-method-override': 'SetNewPassword',
          'content-type': 'application/json',
          'codiceagenzia': dominio.Codice,
          'accesstoken': access_token,
        },
      },
    )

    // @ts-ignore
    if (IOTNULL === risposta.data) {
      store_messaggio.set({ testo: IOTNULL })
      return { ok: false, access_token_valido: true }
    }

    if (risposta.data.code >= 300) {
      store_messaggio.set({ testo: risposta.data.message, durata: 10000 })
      return { ok: false, access_token_valido: true }
    }

    return { ok: true, access_token_valido: true }
  } catch (e) {
    // @ts-ignore
    if (e.response?.status === Status.PaymentRequired) {
      return { ok: false, access_token_valido: false }
    }
    return { ok: false, access_token_valido: true }
  }
}
