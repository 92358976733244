<script>
  import Accesso from ':components/accesso.svelte'
  import AllegatiPratica from ':components/allegati-pratica.svelte'
  import AppBarPrincipale from ':components/app-bar-principale.svelte'
  import BigliettiPratica from ':components/biglietti-pratica.svelte'
  import DettaglioPratica from ':components/dettaglio-pratica.svelte'
  import ElencoFirmeContratto from ':components/elenco-firme-contratto.svelte'
  import LineaOrizzontale from ':components/linea-orizzontale.svelte'
  import PasseggeriPratica from ':components/passeggeri-pratica.svelte'
  import Popup from ':components/popup.svelte'
  import Scrollabile from ':components/scrollabile.svelte'
  import ServiziPratica from ':components/servizi-pratica.svelte'

  import Espansione from ':components/espansione.svelte'
  import Icona from ':components/icona.svelte'
  import Progresso from ':components/progresso.svelte'
  import { mdiFileDocument, mdiInformation } from '@mdi/js'
  import { store_messaggio } from ':stores/store_messaggio'
  import { allegati } from ':scripts/allegati'
  import { dettaglio_pratica } from ':scripts/dettaglio_pratica'
  import { dominio } from ':scripts/dominio'
  import { pratica } from ':scripts/pratica'

  import { fly } from 'svelte/transition'

  /**
   * @type {string}
   */
  export let numero_pratica
  let visualizza_app_bar_principale = true

  let documenti_legali_visibili = false

  let tab_selezionato = 0
  $: AggiornamentoTabSelezionato({ tab_selezionato })
  /**
   * @param {{tab_selezionato:number}} payload
   */
  function AggiornamentoTabSelezionato({ tab_selezionato }) {
    return
  }

  /**
   * @param {{allegati:Array<import(':types').AllegatoPratica>}} payload
   */
  function filtra_allegati_da_firmare({ allegati }) {
    return allegati.filter(function pass(allegato) {
      return 'Da firmare' === allegato.DataFirma
    })
  }
</script>

{#await dominio()}
  <Progresso globale />
{:then dominio}
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <Accesso {dominio}>
    <svelte:fragment let:sessione>
      {#if visualizza_app_bar_principale}
        <AppBarPrincipale titolo="Pratica {numero_pratica}" />
      {/if}

      {#await pratica({ sessione, numero_pratica })}
        <Progresso globale />
      {:then [pratica_locale, errore_pratica]}
        <Scrollabile
          let:larghezza
          let:abilita_pull_to_refresh
          let:disabilita_pull_to_refresh
        >
          <div class="pt-6" />
          {#if errore_pratica}
            <div class="grid-x-center mt-6">
              <span>{errore_pratica.message}</span>
            </div>
          {:else}
            {#await Promise.all( [dettaglio_pratica( { sessione, pratica: pratica_locale }, ), allegati( { sessione, pratica: pratica_locale }, )], )}
              <Progresso globale />
            {:then [dettaglio, allegati]}
              {#if larghezza > 900}
                {#if 1 === tab_selezionato}
                  <div
                    class="grid"
                    in:fly={{ y: 100, duration: 100, delay: 350 }}
                    out:fly={{ y: -100, duration: 100 }}
                  >
                    <h5 class="text-center pt-5 pb-5">
                      <span>Servizi</span>
                      <div style="padding-bottom:2px" />
                    </h5>
                    <LineaOrizzontale />
                    <ServiziPratica {sessione} pratica={pratica_locale} />
                  </div>
                {:else if 2 === tab_selezionato}
                  <div
                    class="grid"
                    in:fly={{ y: 100, duration: 100, delay: 350 }}
                    out:fly={{ y: -100, duration: 100 }}
                  >
                    <h5 class="text-center pt-5 pb-5">
                      <span>Passeggeri</span>
                      <div style="padding-bottom:2px" />
                    </h5>
                    <LineaOrizzontale />
                    <PasseggeriPratica {sessione} pratica={pratica_locale} />
                  </div>
                {:else if 3 === tab_selezionato}
                  <div
                    class="grid"
                    in:fly={{ y: 100, duration: 100, delay: 350 }}
                    out:fly={{ y: -100, duration: 100 }}
                  >
                    <h5 class="text-center pt-5 pb-5">
                      <span>Biglietti</span>
                      <div style="padding-bottom:2px" />
                    </h5>
                    <LineaOrizzontale />
                    <BigliettiPratica {sessione} pratica={pratica_locale} />
                  </div>
                {:else if 4 === tab_selezionato}
                  <div
                    class="grid"
                    in:fly={{ y: 100, duration: 100, delay: 350 }}
                    out:fly={{ y: -100, duration: 100 }}
                  >
                    <h5 class="text-center pt-5 pb-5">
                      <span>Allegati</span>
                      <div style="padding-bottom:2px" />
                    </h5>
                    <LineaOrizzontale />
                    <AllegatiPratica {sessione} pratica={pratica_locale} />
                  </div>
                {:else if dettaglio}
                  <div
                    class="grid"
                    in:fly={{ y: 100, duration: 100, delay: 350 }}
                    out:fly={{ y: -100, duration: 100 }}
                  >
                    <DettaglioPratica
                      {sessione}
                      pratica={pratica_locale}
                      {dettaglio}
                    />
                    <LineaOrizzontale />
                    <div class="pt-2" />
                    {#if filtra_allegati_da_firmare({ allegati }).length > 0}
                      <div class="grid justify-center">
                        <div class="pt-6" />
                        <button
                          class="btn btn-primary"
                          on:click={function run() {
                            documenti_legali_visibili = true
                          }}
                        >
                          <Icona valore={mdiFileDocument} />
                          <span class="pl-2">Firma documenti</span>
                        </button>
                        <div class="pt-2" />
                      </div>
                      <br />
                    {/if}
                  </div>
                {:else}
                  <div
                    class="grid"
                    in:fly={{ y: 100, duration: 100, delay: 350 }}
                    out:fly={{ y: -100, duration: 100 }}
                  >
                    <div class="grid-x-center full-height pt-1">
                      Dettaglio pratica non trovato
                    </div>
                  </div>
                {/if}

                <div class="fixed left right bottom z-4">
                  <div class="tabs tabs-boxed p-4 m-2">
                    <div
                      class="tab"
                      class:tab-active={![1, 2, 3, 4].includes(tab_selezionato)}
                      on:mouseup={function run() {
                        tab_selezionato = 0
                      }}
                    >
                      <span>Dettaglio</span>
                    </div>
                    <div
                      class="tab"
                      class:tab-active={1 === tab_selezionato}
                      on:mouseup={function run() {
                        tab_selezionato = 1
                      }}
                    >
                      <span>Servizi</span>
                    </div>
                    <div
                      class="tab"
                      class:tab-active={2 === tab_selezionato}
                      on:mouseup={function run() {
                        tab_selezionato = 2
                      }}
                    >
                      <span>Passeggeri</span>
                    </div>
                    <div
                      class="tab"
                      class:tab-active={3 === tab_selezionato}
                      on:mouseup={function run() {
                        tab_selezionato = 3
                      }}
                    >
                      <span>Biglietti</span>
                    </div>
                    <div
                      class="tab"
                      class:tab-active={4 === tab_selezionato}
                      on:mouseup={function run() {
                        tab_selezionato = 4
                      }}
                    >
                      <span>Allegati</span>
                    </div>
                  </div>
                </div>
              {:else if dettaglio}
                <DettaglioPratica
                  {sessione}
                  pratica={pratica_locale}
                  {dettaglio}
                />
                <div class="pt-4" />
                <LineaOrizzontale />
                {#if filtra_allegati_da_firmare({ allegati }).length > 0}
                  <div class="grid justify-center">
                    <div class="pt-6" />
                    <button
                      class="btn btn-primary"
                      on:click={function run() {
                        documenti_legali_visibili = true
                      }}
                    >
                      <Icona valore={mdiFileDocument} />
                      <span>Firma documenti</span>
                    </button>
                    <div class="pt-2" />
                  </div>
                {/if}

                <div class="p-2">
                  <Espansione icona={mdiInformation} titolo="Servizi">
                    <ServiziPratica {sessione} pratica={pratica_locale} />
                  </Espansione>
                  <div class="pt-2" />
                  <Espansione icona={mdiInformation} titolo="Passeggeri">
                    <PasseggeriPratica {sessione} pratica={pratica_locale} />
                  </Espansione>
                  <div class="pt-2" />
                  <Espansione icona={mdiInformation} titolo="Biglietti">
                    <BigliettiPratica {sessione} pratica={pratica_locale} />
                  </Espansione>
                  <div class="pt-2" />
                  <Espansione icona={mdiInformation} titolo="Allegati">
                    <AllegatiPratica {sessione} pratica={pratica_locale} />
                  </Espansione>
                </div>
                <div class="pt-2" />
              {:else}
                <div class="pt-2" />
                <div class="grid w-full justify-center">
                  <span>Dettaglio pratica non trovato</span>
                </div>
              {/if}

              {#if pratica_locale}
                <Popup
                  icona={mdiFileDocument}
                  titolo="Documenti legali"
                  bind:visibile={documenti_legali_visibili}
                  on:intenzione-apertura={function run() {
                    visualizza_app_bar_principale = false
                    disabilita_pull_to_refresh()
                  }}
                  on:intenzione-chiusura={function run() {
                    visualizza_app_bar_principale = true
                    abilita_pull_to_refresh()
                  }}
                >
                  <ElencoFirmeContratto
                    allegati={filtra_allegati_da_firmare({ allegati })}
                    {sessione}
                    pratica={pratica_locale}
                    on:firma-richiesta={function run() {
                      $store_messaggio = {
                        testo: 'Richiesta firma inviata con successo',
                      }
                    }}
                  />

                  <div class="pt-8" />
                </Popup>
              {/if}
            {:catch errore}
              <div
                class="grid grid-x-center grid-y-center full-height full-width"
              >
                <span>{errore}</span>
              </div>
            {/await}
          {/if}
        </Scrollabile>
      {:catch errore}
        <div class="grid grid-x-center grid-y-center full-height full-width">
          {errore}
        </div>
      {/await}
    </svelte:fragment>
  </Accesso>
{:catch errore}
  <div
    class="p-4 grid fixed h-full w-full justify-center content-center text-center"
  >
    {errore}
  </div>
{/await}
