<style lang="scss">
  .card {
    height: 400px;
    $radius: 10px;
    text-transform: uppercase;
    border-radius: $radius;
    color: #fff;
    background-color: #fff;
    //background-image: var(--image);
    background-position: center;
    //background-size: auto 100%;
    background-size: cover;
    position: relative;
    display: grid;
    margin-bottom: 2rem;
    grid-template-areas:
      'titolo     titolo      titolo'
      'prezzo     prezzo      prezzo'
      'idea       tipo_p      tipo_p'
      'destin     data_p      durata';
    grid-template-rows: 2fr 6fr 1fr 1.5fr;
    grid-template-columns: 1fr 1fr 1fr;

    .titolo {
      grid-area: titolo;
    }
    .prezzo {
      grid-area: prezzo;
    }
    .destinazione {
      grid-area: destin;
    }
    .data-partenza {
      grid-area: data_p;
    }
    .durata {
      grid-area: durata;
    }

    .prezzo {
      font-size: 2rem;
      font-weight: bold;
      text-shadow: 0 0 1rem rgba(0, 0, 0, 0.9);
    }
    .titolo,
    .data-partenza,
    .destinazione,
    .durata {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .data-partenza,
    .destinazione,
    .durata {
      display: grid;
      align-content: center;
      justify-content: center;
      font-size: 0.8rem;
    }

    .titolo {
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
    }
    .destinazione {
      border-bottom-left-radius: $radius;
    }
    .durata {
      border-bottom-right-radius: $radius;
    }
  }
</style>

<script>
  import { valuta } from ':scripts/valuta'
  /**
   * @type {import(':types').Preventivo}
   */
  export let preventivo

  $: immagine = preventivo.Immagine
    ? preventivo.Immagine
    : `https://source.unsplash.com/random/photos?${preventivo.DescrizioneDestinazione}`
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="card pointer m-1"
  style="background-image:url('{immagine}');"
  on:click={function run() {
    window.open(preventivo.Link)
  }}
>
  <div class="titolo p-2">{preventivo.TitoloPreventivo}</div>
  <div class="prezzo pr-2 pl-2 grid-x-end grid-y-end">
    {valuta.euro({ valore: preventivo.Prezzo })}
  </div>
  <!-- <div class="open-idea pl-2 pr-2"></div> -->
  <!-- <div class="tipo-prezzo pl-1 pr-1">{Preventivo.TipoApplicazionePrezzo}</div> -->
  <!-- <div class="tipo-prezzo pl-1 pr-2"><span /></div> -->
  <div class="destinazione">
    {preventivo.DescrizioneDestinazione}
  </div>
  <div class="data-partenza">
    {preventivo.DataPartenza}
  </div>
  <div class="durata">
    {preventivo.DurataGiorni} GG / {preventivo.DurataNotti} NTS
  </div>
</div>
