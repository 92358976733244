<style lang="scss">
  :root {
    --or-left-line-color: #c1c1c1;
    --or-right-line-color: #c1c1c1;
  }
  .left {
    width: 0;
    height: 1px;
    background-color: var(--or-left-line-color);
    align-self: start;
  }
  .right {
    width: 0;
    height: 1px;
    background-color: var(--or-right-line-color);
    align-self: start;
  }
  .Intestazione {
    align-self: center;
    justify-self: center;
    text-align: center;

    .Titolo {
      align-self: center;
      justify-self: center;
      font-size: 1.5rem;
      font-weight: bold;
    }

    .Didascalia {
      align-self: center;
      justify-self: center;
      font-size: 1rem;
      font-weight: normal;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    column-gap: 3rem;
  }

  @media only screen and (max-width: 900px) {
    .grid {
      column-gap: 1rem;
    }
  }
</style>

<script>
  /**
   * @type {string}
   */
  export let titolo
  /**
   * @type {string}
   */
  export let didascalia
</script>

<div class="grid w-full">
  <div class="left m-0 p-0 ml-3 relative top-4" />
  <div class="Intestazione m-0 p-0">
    <div class="Titolo m-0 p-0">{titolo}</div>
    <div class="Didascalia m-0 p-0">{didascalia}</div>
  </div>
  <div class="right m-0 p-0 mr-3 relative top-4" />
</div>
