<script>
  import Chart from ':components/grafico.svelte'

  /**
   * @type {any}
   */
  export let trigger_ridisegno = {}
  export let colore_testo = '#000'
  export let larghezza = '100%'
  export let altezza = '100%'
  export let leggenda = true
  export let barre_sovrapposte_x = false
  export let barre_sovrapposte_y = false
  /**
   * @type {'top' | 'bottom' | 'left' | 'right'}
   */
  export let posizione_leggenda = 'top'
  /**
   * @type {import(':types').TipoGrafico}
   */
  let tipo = 'pie'
  /**
   * @type {Array<string>}
   */
  export let etichette
  /**
   * @type {Array<import(':types').DatasetGrafici>}
   */
  export let dataset
</script>

<Chart
  redrawTrigger={trigger_ridisegno}
  {colore_testo}
  {larghezza}
  {altezza}
  {leggenda}
  {barre_sovrapposte_x}
  {barre_sovrapposte_y}
  {posizione_leggenda}
  {tipo}
  {etichette}
  {dataset}
  {...$$restProps}
>
  <div slot="before-chart"><slot name="before" /></div>
  <div slot="after-chart"><slot name="after" /></div>
</Chart>
