import axios from 'axios'
import { get } from 'svelte/store'
import { store_messaggio } from ':stores/store_messaggio'
import { store_url_facilews3 } from ':stores/store_url_facilews3'

/**
 *
 * @param {{sessione:import(':types').Sessione}} payload
 * @returns
 */
export async function preventivi({ sessione }) {
  try {
    const $store_url_facilews3 = get(store_url_facilews3)
    /**
     * @type {import(':types').AxiosResponse<import(':types').RispostaFacileWS3<Array<import(':types').Preventivo>>>}
     */
    const risposta = await axios.get(
      `${$store_url_facilews3}/Api/Rest/${sessione.Utente.CodiceAgenzia}/Preventivo/FindByAccountLoginID/${sessione.Utente.AccountLoginId}?Token=${sessione.Utente.TokenFacileWS}`,
    )

    return risposta.data.data ?? []
  } catch (e) {
    store_messaggio.set({ testo: `${e}` })
    // PreventiviInEvidenza.set([])
    return []
  }
}
