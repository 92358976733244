const Numero = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

/**
 *
 * @param {{valore: number | bigint | string}} payload
 * @returns
 */
function euro({ valore }) {
  return Numero.format(typeof valore === 'string' ? parseFloat(valore) : valore)
}

export const valuta = {
  euro,
}
