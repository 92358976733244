<style lang="scss">
  .Attenzione {
    background: rgba(100, 100, 100, 0.1);
    border-radius: 1rem;
    padding: 1rem;
  }
  .DescrizioneDestinatarioRichiesta {
    background: rgba(100, 100, 100, 0.1);
    border-radius: 1rem;
    padding: 1rem;
  }
</style>

<script>
  import Icona from ':components/icona.svelte'
  import Progresso from ':components/progresso.svelte'
  import { store_messaggio } from ':stores/store_messaggio'
  import { mdiEmail, mdiFileQuestion } from '@mdi/js'
  import { richiedi_firma_contratto } from ':scripts/richiedi_firma_contratto'

  /**
   * @type {import(':types').Sessione}
   */
  export let sessione
  /**
   * @type {import(':types').Pratica}
   */
  export let pratica
  /**
   * @type {import(':types').AllegatoPratica}
   */
  export let allegato
  /**
   * @type {import(':types').Account}
   */
  export let account

  let InvioInCorso = false
  let InvioRichiestaInCorso = false
</script>

<div class="p-4 w-full justify-center text-center">
  <div class="p-2">
    {#if allegato.Pubblico !== 1}
      <div class="text-center Attenzione">
        <!-- <Chip outlined class="ma-2 red-text">Attenzione!</Chip> -->
        <div class="badge badge-error p-4 font-semibold">
          <span>Attenzione!</span>
        </div>
        <p class="text-center pl-4 pr-4">
          Questo contratto non è visibile per l'utente finale!
        </p>
      </div>
    {/if}
    <div class="pt-2" />
    <div class="DescrizioneDestinatarioRichiesta">
      La richiesta di firma sarà inviata all'indirizzo email
      <div class="pt-1" />
      <div class="p-4 w-full justify-center text-center">
        <div class="badge badge-outline p-4">
          <Icona valore={mdiEmail} />
          <span class="pl-2">{account.Email}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-4" />
  <button
    class="btn btn-primary"
    disabled={InvioInCorso || InvioRichiestaInCorso}
    on:click={async function run() {
      InvioRichiestaInCorso = true
      await richiedi_firma_contratto({
        sessione,
        pratica,
        allegato_pratica: allegato,
        email_cliente: account.Email,
      })
      InvioRichiestaInCorso = false
      $store_messaggio = { testo: 'Richiesta firma inviata' }
    }}
  >
    {#if InvioInCorso || InvioRichiestaInCorso}
      <Progresso />
    {:else}
      <span class="pr-2">Richiedi firma cliente</span>
      <Icona valore={mdiFileQuestion} />
    {/if}
  </button>
</div>
