import { uri } from ':scripts/uri'
import axios from 'axios'

const AUTENTICAZIONE = 'https://www.facebook.com/v12.0/dialog/oauth'
const METADATI_UTENTE = 'https://graph.facebook.com/me'
const ID_APP = '489939865714742'

/**
 *
 * @param {{destinazione:string}} payload
 */
function redirect({ destinazione }) {
  const options = {
    response_type: 'token',
    scope: 'email',
    redirect_uri: destinazione,
    client_id: ID_APP,
    state: 'facebook',
  }

  const href = `${AUTENTICAZIONE}?${uri({ valore: options }).encode()}`
  location.href = href
}

/**
 *
 * @param {{access_token:string}} payload
 * @returns
 */
async function info({ access_token }) {
  const opzioni_utente = {
    access_token: access_token,
  }

  /**
   * @type {import(':types').AxiosResponse<{ email: string; id: string }>}
   */
  const utente = await axios.get(
    `${METADATI_UTENTE}?${uri({ valore: opzioni_utente }).encode()}`,
  )

  if (utente.status > 300) return false

  const opzioni_profilo = {
    access_token: access_token,
    fields: 'id,name,email,picture',
  }

  /**
   * @type {import(':types').AxiosResponse<import(':types').ProfiloFacebook>}
   */
  const profilo = await axios.get(
    `https://graph.facebook.com/${utente.data.id}?&${uri({
      valore: opzioni_profilo,
    }).encode()}`,
  )

  if (profilo.status > 300) return false

  return profilo.data
}

export const facebook = {
  redirect,
  info,
}
