<style lang="scss">
  @media only screen and (max-width: 900px) {
    .form {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      .card {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
</style>

<script>
  import ComponenteAccessoTerzeParti from ':components/accesso-terze-parti.svelte'
  import DivisoreOrizzontaleConTesto from ':components/divisore-orizzontale-con-testo.svelte'
  import LineaOrizzontale from ':components/linea-orizzontale.svelte'
  import CampoDiTesto from ':components/campo-di-testo.svelte'
  import { facebook } from ':scripts/facebook'
  import { google } from ':scripts/google'
  import { mdiCube, mdiEmail, mdiLock, mdiLockRemove } from '@mdi/js'
  import { accedi } from ':scripts/accedi'
  import { crea_account } from ':scripts/crea_account'
  import { esci } from ':scripts/esci'
  import { store_accesso_in_corso } from ':stores/store_accesso_in_corso'
  import { store_accesso_terze_parti } from ':stores/store_accesso_terze_parti'
  import { store_accesso_terze_parti_in_corso } from ':stores/store_accesso_terze_parti_in_corso'
  import { store_modalita_pwa } from ':stores/store_modalita_pwa'
  import { store_password } from ':stores/store_password'
  import { store_sessione_app } from ':stores/store_sessione_app'
  import { store_username } from ':stores/store_username'
  import { store_versione_locale } from ':stores/store_versione_locale'
  import { store_messaggio } from ':stores/store_messaggio'
  import { navigate } from 'svelte-routing'
  import Icona from ':components/icona.svelte'
  import { onMount } from 'svelte'

  /**
   * @type {import(':types').MetadatiDominio}
   */
  export let dominio
  export let accedi_automaticamente = false
  /**
   * @type {boolean}
   */
  export let mostra_versione

  let mostra_caricamento = false
  // let mostra_password = false

  onMount(function start() {
    $store_password = btoa('')
  })

  async function invia() {
    $store_accesso_in_corso = true
    mostra_caricamento = true
    $store_sessione_app = await accedi({ dominio })
    if (!$store_sessione_app) esci()
    mostra_caricamento = false
    $store_accesso_in_corso = false
  }

  if (accedi_automaticamente) {
    invia()
  }

  let password_chiara = atob($store_password)
  $: aggiornamento_password_chiara({ password_chiara })
  /**
   * @param {{password_chiara:string}} payload
   */
  function aggiornamento_password_chiara({ password_chiara }) {
    $store_password = btoa(password_chiara)
  }

  /**
   * @type {false | string}
   */
  let access_token_google = false
  $: aggiornamento_access_token_google({ access_token_google })
  /**
   *
   * @param {{access_token_google:false | string}} payload
   */
  async function aggiornamento_access_token_google({ access_token_google }) {
    if (!access_token_google || $store_sessione_app) return
    $store_accesso_terze_parti_in_corso = true
    const PasswordOriginale = password_chiara
    try {
      const InformazioniGoogle = await google.info({
        access_token: access_token_google,
      })
      if (!InformazioniGoogle) {
        $store_messaggio = {
          testo: 'Impossibile recuperare informazioni Google.',
        }
        $store_accesso_in_corso = false
        return
      }

      $store_accesso_in_corso = true
      if (
        !(await crea_account({
          dominio,
          nome_utente: InformazioniGoogle.email ?? '',
          nome: InformazioniGoogle.family_name ?? InformazioniGoogle.name ?? '',
          cognome: InformazioniGoogle.given_name ?? '',
        }))
      ) {
        $store_accesso_in_corso = false
        return ($store_messaggio = {
          testo: "Errore durante la creazione dell'account federato.",
        })
      }

      $store_username = InformazioniGoogle.email
      $store_password = btoa(access_token_google)
      $store_accesso_terze_parti = 'google'
      await invia()
    } catch (errore) {
      $store_messaggio = { testo: `${errore}` }
      $store_password = btoa(PasswordOriginale)
    }

    $store_password = btoa('')
    location.hash = ''
    $store_accesso_terze_parti_in_corso = false
    $store_accesso_in_corso = false
  }

  /**
   * @type {false | string}
   */
  let access_token_facebook = false
  $: aggiornamento_access_token_facebook({ access_token_facebook })
  /**
   *
   * @param {{access_token_facebook:false | string}} payload
   */
  async function aggiornamento_access_token_facebook({
    access_token_facebook,
  }) {
    if (!access_token_facebook || $store_sessione_app) return

    $store_accesso_terze_parti_in_corso = true
    const password_originale = atob($store_password)
    try {
      const informazioni_facebook = await facebook.info({
        access_token: access_token_facebook,
      })
      if (!informazioni_facebook) {
        $store_messaggio = {
          testo: 'Impossibile recuperare informazioni Facebook.',
        }
        $store_accesso_in_corso = false
        return
      }

      const pezzi = informazioni_facebook.name.split(/\s/)
      const cognome = pezzi[pezzi.length - 1] ?? ''
      const nome = (pezzi.slice(0, pezzi.length - 1) ?? []).join(' ').trim()

      $store_accesso_in_corso = true
      if (
        !(await crea_account({
          dominio,
          nome_utente: informazioni_facebook.email ?? '',
          cognome,
          nome,
        }))
      ) {
        $store_accesso_in_corso = false
        return ($store_messaggio = {
          testo: "Errore durante la creazione dell'account federato.",
        })
      }

      $store_username = informazioni_facebook.email
      $store_password = btoa(access_token_facebook)
      $store_accesso_terze_parti = 'facebook'
      await invia()
    } catch (errore) {
      $store_messaggio = { testo: `${errore}` }
      $store_password = btoa(password_originale)
    }
    location.hash = ''
    $store_accesso_terze_parti_in_corso = false
    $store_accesso_in_corso = false
  }
</script>

<div class="form relative">
  <div class="card bg-base-100 shadow-xl relative">
    <div class="card-body">
      {#if dominio.AbilitaAccessoConGoogle || dominio.AbilitaAccessoConFacebook}
        <div class="grid m-1">
          <ComponenteAccessoTerzeParti
            {dominio}
            bind:AccessTokenGoogle={access_token_google}
            bind:AccessTokenFacebook={access_token_facebook}
          />
          <div class="pt-2" />
          <DivisoreOrizzontaleConTesto testo="oppure" />
          <div class="pt-2" />
        </div>
      {:else}
        <div class="pt-2" />
      {/if}

      <form
        on:submit|preventDefault={function run() {
          if (!dominio || dominio.Codice === '' || mostra_caricamento) return

          $store_accesso_terze_parti = false
          invia()
        }}
      >
        <CampoDiTesto
          indizio="Nome utente o indirizzo email"
          bind:valore={$store_username}
          icona={mdiEmail}
        />
        <CampoDiTesto
          indizio="Password"
          bind:valore={password_chiara}
          password
          icona={mdiLock}
        />

        <div class="pt-2" />
        <button
          type="submit"
          class="btn btn-primary w-full"
          disabled={!dominio || dominio.Codice === '' || mostra_caricamento}
        >
          <span>Accedi</span>
        </button>
        <!-- {/if} -->
      </form>
      <div class="pt-2" />
      <div class="grid grid-cols-3">
        {#if !$store_modalita_pwa}
          <button class="btn btn-ghost justify-self-start">
            <div class="grid w-full" style="grid-template-columns: auto 1fr;">
              <Icona valore={mdiCube} />
              <div class="text-xs pl-2 grid content-center justify-start">
                Installa
              </div>
            </div>
          </button>
        {:else}
          <div />
        {/if}
        <button
          class="w-full btn btn-ghost justify-self-end col-span-2"
          on:click={function run() {
            navigate('/richiesta-email-recupero-password')
          }}
        >
          <div class="grid w-full" style="grid-template-columns: 1fr auto;">
            <div class="text-xs pr-2 grid content-center justify-end">
              Password dimenticata?
            </div>
            <Icona valore={mdiLockRemove} />
          </div>
        </button>
      </div>

      <div class="pt-4" />
      {#if dominio.AbilitaRegistrazione}
        <LineaOrizzontale />
        <div class="pt-4" />
        <div class="grid grid-x-center">
          <div style="margin-bottom:0.5rem">Non hai ancora un account?</div>
        </div>
        <button
          class="btn btn-ghost w-full"
          on:click={function run() {
            navigate('/registrazione')
          }}
        >
          <span>Registrati ora</span>
        </button>
      {/if}
    </div>
  </div>
  {#if mostra_versione}
    <div class="grid grid-x-end">
      <div class="absolute" style="top:-1rem;right:10px">
        <div class="p-4 badge badge-primary">Ver. {$store_versione_locale}</div>
      </div>
    </div>
  {/if}
</div>
