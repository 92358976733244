import { get } from 'svelte/store'
import { store_dominio_ok } from ':stores/store_dominio_ok'
import { store_indirizzo_app } from ':stores/store_indirizzo_app'
import { store_url_sgp1 } from ':stores/store_url_sgp1'
import { store_url_sferanet } from ':stores/store_url_sferanet'
import axios from 'axios'

let Occupato = false

/**
 * @type {import(':types').MetadatiDominio}
 */
const Dominio = {
  Nome: '',
  Immagine: '',
  Codice: '',
  AbilitaRecuperoPassword: false,
  AbilitaAccessoConGoogle: false,
  AbilitaAccessoConFacebook: false,
  AbilitaRegistrazione: false,
  TipoFormRegistrazione: 'implicito',
  DominioStargate: get(store_url_sgp1),
}

export async function dominio() {
  // ----------------------------------------------------
  // URLSGP1.set(Dominio.DominioStargate)
  // DominioOK.set(true)
  // return {
  //   Immagine: '',
  //   Nome: 'DEMO1',
  //   Codice: 'DEMO1',
  //   TipoFormRegistrazione: 'esplicito',
  //   AbilitaRegistrazione: true,
  //   AbilitaRecuperoPassword: true,
  //   AbilitaAccessoConGoogle: true,
  //   AbilitaAccessoConFacebook: true,
  //   DominioStargate: '',
  // }
  // ----------------------------------------------------

  if (Occupato) return Dominio
  Occupato = true
  if ('' !== Dominio.Codice) return Dominio

  const $store_url_sferanet = get(store_url_sferanet)
  const $store_indirizzo_app = get(store_indirizzo_app)

  /**
   * @type {import(':types').AxiosResponse<{
   *  ImmagineAgenzia: string
   *  NomeAgenzia?: string
   *  NomeAPP?: string
   *  CodiceAgenzia: string
   *  AbilitaRegistrazione: 0 | 1
   *  AbilitaRecuperoPassword: 0 | 1
   *  AbilitaLoginGoogle: 0 | 1
   *  AbilitaLoginFacebook: 0 | 1
   *  DominioStargate: string,
   *  Esito?: number,
   * }>}
   */
  const risposta = await axios.post(
    `${$store_url_sferanet}/CompFacileLogin/CustomizeAPP.json`,
    JSON.stringify({
      IndirizzoAPP: $store_indirizzo_app,
    }),
    {
      headers: {
        'x-http-method-override': 'GetInfoApp',
        'content-type': 'application/json',
      },
    },
  )

  if (404 == risposta.data.Esito) {
    Occupato = false
    throw 'Agenzia non trovata.'
  }

  Dominio.AbilitaAccessoConFacebook = risposta.data.AbilitaLoginFacebook === 1

  Dominio.AbilitaAccessoConGoogle = risposta.data.AbilitaLoginGoogle === 1

  Dominio.AbilitaRecuperoPassword = risposta.data.AbilitaRecuperoPassword === 1

  Dominio.AbilitaRegistrazione = risposta.data.AbilitaRegistrazione === 1

  Dominio.Codice = risposta.data.CodiceAgenzia

  Dominio.Immagine = risposta.data.ImmagineAgenzia

  Dominio.Nome =
    risposta.data.NomeAgenzia ?? risposta.data.NomeAPP ?? 'Agenzia sconosciuta'

  Dominio.DominioStargate = risposta.data.DominioStargate ?? ''

  if (
    !Dominio.DominioStargate.trim().startsWith('http://') &&
    !Dominio.DominioStargate.trim().startsWith('https://')
  )
    Dominio.DominioStargate = `https://${Dominio.DominioStargate}`

  Occupato = false

  store_url_sgp1.set(Dominio.DominioStargate)

  store_dominio_ok.set(true)
  return Dominio
}
