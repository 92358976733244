<style lang="scss">
  .PullToRefresh {
    margin: auto;
    width: 3rem;
    height: 3rem;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    opacity: 0;
  }

  @-webkit-keyframes RotazioneInfinita {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes RotazioneInfinita {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .RotazioneInfinita {
    -webkit-animation: RotazioneInfinita 500ms linear infinite;
    -moz-animation: RotazioneInfinita 500ms linear infinite;
    -ms-animation: RotazioneInfinita 500ms linear infinite;
    -o-animation: RotazioneInfinita 500ms linear infinite;
    animation: RotazioneInfinita 500ms linear infinite;
  }
</style>

<script>
  import { mdiRefresh } from '@mdi/js'
  import { spring, tweened } from 'svelte/motion'
  import Icona from ':components/icona.svelte'

  /**
   * @type {HTMLElement}
   */
  export let contenitore
  /**
   * @type {CallableFunction}
   */
  export let ricarica
  export let altezza_leva = 400

  export let occupato = false

  let margine_azione = 10

  $: aggiornamento_contenitore({ contenitore })
  /**
   *
   * @param {{contenitore:HTMLElement}} payload
   */
  function aggiornamento_contenitore({ contenitore }) {
    if (!contenitore) return
    contenitore.removeEventListener('touchstart', inizio_touch)
    contenitore.removeEventListener('touchmove', movimento_touch)
    contenitore.removeEventListener('touchend', fine_touch)

    contenitore.addEventListener('touchstart', inizio_touch)
    contenitore.addEventListener('touchmove', movimento_touch)
    contenitore.addEventListener('touchend', fine_touch)
  }
  let PullRichiesto = false
  let RotazioneInfinita = false
  let PosizioneStart = 0
  let direzione = 0
  const PaddingSpring = spring(0, {
    damping: 0.5,
    precision: 0.01,
    stiffness: 0.2,
  })
  let Rotazione = 0
  let Opacita = tweened(0, {
    duration: 100,
  })

  $: aggiorna_direzione({ direzione })
  /**
   * @param {{direzione:number}} payload
   */
  function aggiorna_direzione({ direzione }) {
    if (direzione < margine_azione) return
    PaddingSpring.set((direzione - margine_azione) * 0.9)
    Rotazione = (direzione - margine_azione) * 1.5
    Opacita.set(
      (direzione - margine_azione) / 10 > 1
        ? 1
        : (direzione - margine_azione) / 10,
    )
  }

  /**
   * @param {TouchEvent} e
   */
  function inizio_touch(e) {
    if (occupato || !contenitore || contenitore.scrollTop > 0) {
      return
    }
    PosizioneStart = e.touches[0].screenY
    PullRichiesto = true

    // console.log('Inizio', PosizioneStart, Contenitore.scrollTop)
  }

  /**
   * @param {TouchEvent} e
   */
  function movimento_touch(e) {
    if (
      !PullRichiesto ||
      !contenitore ||
      occupato ||
      contenitore.scrollTop > 0
    ) {
      return
    }
    direzione = e.touches[0].screenY - PosizioneStart
  }

  /**
   * @param {TouchEvent} e
   */
  async function fine_touch(e) {
    if (
      !PullRichiesto ||
      !contenitore ||
      occupato ||
      contenitore.scrollTop > 0
    ) {
      return
    }
    occupato = true
    console.log('Fine', $PaddingSpring)
    if ($PaddingSpring >= altezza_leva) {
      const overflowOriginale = contenitore.style.overflowY
      contenitore.style.overflowY = 'hidden'
      RotazioneInfinita = true
      PaddingSpring.set(altezza_leva)
      setTimeout(async function run() {
        await ricarica()
        RotazioneInfinita = false
        PullRichiesto = false
        direzione = 0
        occupato = false
        contenitore.style.overflowY = overflowOriginale
        Opacita.set(0)
      }, 1000)
    } else {
      PaddingSpring.set(0)
      Opacita.set(0)
      PullRichiesto = false
      occupato = false
      direzione = 0
    }
  }
</script>

<div
  class="relative left right top full-width"
  style="
    pointer-events: none;
    padding-top:{$PaddingSpring}px;
  "
>
  <div class="grid grid-x-center" style="transform: rotateZ({Rotazione}deg);">
    <div
      class:RotazioneInfinita
      class="PullToRefresh grid grid-x-center grid-y-center"
      style="opacity: {$Opacita};"
    >
      <Icona valore={mdiRefresh} />
    </div>
  </div>
</div>

<slot
  InizioTouch={inizio_touch}
  MovimentoTouch={movimento_touch}
  FineTouch={fine_touch}
/>
