/**
 *
 * Crea un canvas e il suo contesto 2d.
 * @param {{ larghezza:number, altezza:number }} payload
 * @returns
 */
export function canvas_2d({ larghezza, altezza }) {
  const canvas = document.createElement('canvas')
  canvas.width = larghezza
  canvas.height = altezza
  /**
   * @type {CanvasRenderingContext2D}
   */
  // @ts-ignore
  const contesto = canvas.getContext('2d')

  return { contesto, canvas }
}
