<script>
  import { mdiAttachment, mdiOpenInApp, mdiShare } from '@mdi/js'
  import { allegati } from ':scripts/allegati'
  import { store_messaggio } from ':stores/store_messaggio'

  import { scale } from 'svelte/transition'
  import Espansione from ':components/espansione.svelte'
  import Icona from ':components/icona.svelte'
  import Progresso from ':components/progresso.svelte'
  import { apri_allegato } from ':scripts/apri_allegato'
  /**
   * @type {import(':types').Pratica}
   */
  export let pratica
  /**
   * @type {import(':types').Sessione}
   */
  export let sessione

  /**
   *
   * @param {{allegato:import(':types').AllegatoPratica}} payload
   */
  async function share({ allegato }) {
    if (navigator.share) {
      navigator
        .share({
          title: allegato.Descrizione,
          text: pratica.NumeroPratica,
          url: allegato.LinkAllegato.replace(
            '{tokenUtente}',
            sessione.Utente.TokenFacileWS,
          ),
        })
        .then(function run() {
          $store_messaggio = { testo: 'allegato condiviso.' }
        })
        .catch(function run(error) {
          $store_messaggio = { testo: 'Errore durante la condivisione.' }
          console.log('Errore durante la condivisione allegato', error)
        })
    } else {
      $store_messaggio = {
        testo: 'Il tuo piattaforma non dispone di un sistema di condivisione.',
      }
    }
  }
</script>

{#await allegati({ sessione, pratica })}
  <div
    class="p-4 grid w-full h-full justify-center content-center text-center"
    transition:scale
  >
    <Progresso />
  </div>
{:then allegati}
  {#each allegati as allegato}
    <Espansione
      icona={mdiAttachment}
      titolo={allegato.Descrizione}
      sottotitolo={allegato.DataFirma === ''
        ? allegato.DataInserimento
        : allegato.DataFirma}
    >
      <span class="font-thin">
        {allegato.Descrizione}
      </span>
      <div class="pt-2" />
      <button
        class="w-full btn btn-primary"
        on:click={function run() {
          apri_allegato({ sessione, allegato })
        }}
      >
        <Icona valore={mdiOpenInApp} />
        <span class="pl-2">Apri</span>
      </button>
      <div class="pt-2" />
      <button
        class="w-full btn btn-primary"
        on:click={function run() {
          share({ allegato })
        }}
      >
        <Icona valore={mdiShare} />
        <span class="pl-2">Condividi</span>
      </button>
    </Espansione>
    <div class="pt-2" />
  {:else}
    <span class="p-4 w-full grid justify-center text-center" transition:scale>
      <span>Nessun allegato trovato</span>
    </span>
  {/each}
{:catch errore}
  <span class="p-4 w-full grid justify-center text-center" transition:scale>
    <span>{errore}</span>
  </span>
{/await}
