<script>
  import DivisoreOrizzontaleConIntestazione from ':components/divisore-orizzontale-con-intestazione.svelte'
  import ComponentePreventivo from ':components/preventivo.svelte'
  import { Pagination } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/svelte'
  /**
   * @type {string}
   */
  export let titolo
  /**
   * @type {string}
   */
  export let didascalia
  /**
   * @type {number}
   */
  export let larghezza
  /**
   * @type {Array<import(':types').Preventivo>}
   */
  export let preventivi
</script>

<DivisoreOrizzontaleConIntestazione {titolo} {didascalia} />
<div class="pt-4" />
{#if preventivi.length === 0}
  <div class="p-2">
    <div class="w-full bg-base-200 p-4 rounded-xl text-center">
      Nessun Preventivo trovato.
    </div>
  </div>
{:else}
  <Swiper
    modules={[Pagination]}
    pagination={{
      bulletActiveClass: 'swiper-pagination-bullet-active amber',
    }}
    spaceBetween={30}
    width={larghezza}
    slidesPerView={larghezza < 400 ? 1 : Math.floor(larghezza / 400)}
  >
    {#each preventivi as preventivo}
      <SwiperSlide>
        <ComponentePreventivo {preventivo} />
      </SwiperSlide>
    {/each}
  </Swiper>
{/if}
