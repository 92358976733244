<script>
  import { valuta } from ':scripts/valuta'
  import { servizi } from ':scripts/servizi'

  import { scale } from 'svelte/transition'
  import Espansione from ':components/espansione.svelte'
  import Progresso from ':components/progresso.svelte'

  /**
   * @type {import(':types').Sessione}
   */
  export let sessione
  /**
   * @type {import(':types').Pratica}
   */
  export let pratica
</script>

<div class="grid relative w-full">
  {#await servizi({ sessione, pratica })}
    <div class="justify-self-center p-2">
      <Progresso />
    </div>
  {:then servizi}
    <div
      class="p-4 w-full justify-self-center"
      transition:scale={{ duration: 200 }}
    >
      {#each servizi as servizio}
        <Espansione
          titolo={servizio.DenominazionePacchettoServizio ||
            'Denominazione pacchetto non trovata'}
          sottotitolo={valuta.euro({ valore: servizio.ImportoServizio })}
          immagine={servizio.ImmagineTipoServizio}
        >
          <span class="w-full grid p-4 text-center">
            {servizio.DenominazionePacchettoServizio ||
              'Denominazione pacchetto non trovata'}
          </span>
          {#each servizio.Quote ?? [] as Quota}
            <div
              class="grid w-full p-4 bg-slate-100 rounded-xl"
              style="grid-template-columns: 1fr auto;"
            >
              <div class="text-start">
                {Quota.DescrizioneQuota}
              </div>
              <div class="grid content-start text-end">
                <div class="bg-slate-200 p-2 rounded-xl text-sm">
                  <span>{valuta.euro({ valore: Quota.RicavoUnitario })}</span>
                  <span class="pl-2 font-bold pr-2">x{Quota.Quantita}</span>
                  <span>{valuta.euro({ valore: Quota.Ricavo })}</span>
                </div>
              </div>
            </div>
            <div class="pt-2" />
          {:else}
            <span
              class="p-4 w-full grid justify-center text-center"
              transition:scale
            >
              <span>Nessun quota trovata</span>
            </span>
          {/each}
        </Espansione>
        <div class="pt-2" />
      {:else}
        <span
          class="p-4 w-full grid justify-center text-center"
          transition:scale
        >
          <span>Nessun servizio trovato</span>
        </span>
      {/each}
    </div>
  {:catch errore}
    <span class="p-4 w-full grid justify-center text-center" transition:scale>
      <span>{errore}</span>
    </span>
  {/await}
</div>
