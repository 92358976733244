/**
 * @template T
 * @param {{valore:string}} payload
 * @returns
 */
function decode_uri_object({ valore }) {
  /**
   * @type {T}
   */
  // @ts-ignore
  const result = {}

  if ('' === valore) {
    return result
  }
  if (valore.startsWith('?')) valore = valore.substring(1)

  const groups = valore.split('&')

  for (const group of groups) {
    const pair = group.split('=')
    const key = decodeURIComponent(pair[0] ?? '')
    const value = decodeURIComponent(pair[1] ?? '1')
    if ('' === key) continue

    /** @ts-ignore */
    result[key] = value
  }

  return result
}

/**
 * @template T
 * @param {{valore: T}} payload
 * @returns {string}
 */
function encode_uri_object({ valore }) {
  const str = []
  for (const chiave in valore)
    if (valore[chiave]) {
      const properieta = `${valore[chiave]}`
      str.push(
        `${encodeURIComponent(chiave)}=${encodeURIComponent(properieta)}`,
      )
    }

  return str.join('&')
}

/**
 * @template T
 * @param {{valore:T|string}} payload
 * @returns
 */
export function uri({ valore }) {
  return {
    decode() {
      /**
       * @type {typeof decode_uri_object<T>}
       */
      // @ts-ignore
      return decode_uri_object({ valore })
    },
    encode() {
      /**
       * @type {typeof encode_uri_object<T>}
       */
      return encode_uri_object({ valore })
    },
  }
}
