<style>
  :root {
    --sfonto_rosso: #dd4b39;
    --sfonto_blu: #315baf;
    --testo_bianco: #ffffff;
  }
  .grid {
    column-gap: 0.5rem;
  }
</style>

<script>
  import ComponenteIcona from ':components/icona.svelte'
  /**
   * @type {'rosso' | 'blu'}
   */
  export let aspetto
  export let testo = ''
  export let icona = ''
  let tema_rosso = 'background:var(--sfonto_rosso);color:var(--testo_bianco);'
  let tema_blu = 'background:var(--sfonto_blu);color:var(--testo_bianco);'
</script>

<button
  class="btn btn-ghost rounded-full"
  style="height:4rem;{aspetto === 'rosso'
    ? tema_rosso
    : aspetto === 'blu'
    ? tema_blu
    : ''}"
  on:click
>
  <div class="grid grid-cols-2-auto grid-y-center">
    <ComponenteIcona valore={icona} />
    <span class="text-sm">{testo}</span>
  </div>
</button>
