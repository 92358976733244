<style lang="scss">
  .titolo {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<script>
  import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
  import { spring } from 'svelte/motion'
  import ComponenteIcona from ':components/icona.svelte'
  export let titolo = ''
  export let sottotitolo = ''
  /**
   * @type {import(':types').ColoreSottotitolo}
   */
  export let colore_sottotitolo = ''
  export let attivita = false
  export let icona = ''
  export let icona_secondaria = ''
  export let testo_icona_secondaria = ''
  export let immagine = ''
  export let azzera_arrotondamento_in_alto = false

  const configurazione_animazione = {
    damping: 0.3,
    precision: 0.1,
    stiffness: 0.04,
  }
  const SPAZIO_ORIZZIONTALE_LIERO_PREDEFINITO = 2
  const SPAZIO_VERTICALE_LIERO_PREDEFINITO = -0.5

  const spazio_orizzontale_libero = spring(
    SPAZIO_ORIZZIONTALE_LIERO_PREDEFINITO,
    configurazione_animazione,
  )
  const spazio_verticale_libero = spring(
    SPAZIO_VERTICALE_LIERO_PREDEFINITO,
    configurazione_animazione,
  )
  const altezza_animata = spring(100, configurazione_animazione)

  $: if (attivita) {
    $spazio_orizzontale_libero = 0
    $spazio_verticale_libero = 0.5
    $altezza_animata = 500
  } else {
    $spazio_orizzontale_libero = SPAZIO_ORIZZIONTALE_LIERO_PREDEFINITO
    $spazio_verticale_libero = SPAZIO_VERTICALE_LIERO_PREDEFINITO
    $altezza_animata = 0
  }
</script>

<div
  class="w-full"
  style="
  padding-left:{$spazio_orizzontale_libero}rem;
  padding-right:{$spazio_orizzontale_libero}rem;
  padding-top:{$spazio_verticale_libero}rem;
  "
>
  <div
    class="card w-full bg-base-100 shadow-xl relative"
    class:rounded-t-none={azzera_arrotondamento_in_alto}
    style="transition: border-radius 300ms;"
  >
    <div class="card-body p-0 pt-4 pb-2">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div
        class="attivatore absolute left-0 right-0 top-0 h-14 cursor-pointer"
        on:click={function run() {
          attivita = !attivita
        }}
      />
      <div class="titolo cursor-pointer pl-2 pr-2">
        <div
          class="w-full grid gap-2 content-center items-center"
          style="grid-template-columns: auto 1fr auto;"
        >
          <span class="pl-2 text-start font-semibold">
            {#if immagine}
              <img alt="" src={immagine} width="60" />
            {:else if icona}
              <ComponenteIcona valore={icona} />
            {:else}
              <span />
            {/if}
          </span>
          <div class="grid content-center">
            <span class="whitespace-nowrap overflow-hidden text-ellipsis"
              >{titolo}</span
            >
          </div>
          <div
            class="w-full grid content-center"
            style="grid-template-columns: auto 1fr;"
          >
            {#if sottotitolo}
              <div class="pt-2 pb-2">
                {#if colore_sottotitolo === 'error'}
                  <span
                    class="border-red-700 bg-red-300 text-red-700 p-2 rounded-3xl"
                    style="border: 1px solid"
                  >
                    {sottotitolo}
                  </span>
                {:else if colore_sottotitolo === 'info'}
                  <span
                    class="border-blue-700 bg-blue-300 text-blue-700 p-2 rounded-3xl"
                    style="border: 1px solid"
                  >
                    {sottotitolo}
                  </span>
                {:else if colore_sottotitolo === 'success'}
                  <span
                    class="border-green-700 bg-green-300 text-green-700 p-2 rounded-3xl"
                    style="border: 1px solid"
                  >
                    {sottotitolo}
                  </span>
                {:else if colore_sottotitolo === 'warning'}
                  <span
                    class="border-yellow-700 bg-yellow-200 text-yellow-700 p-2 rounded-3xl"
                    style="border: 1px solid"
                  >
                    {sottotitolo}
                  </span>
                {:else}
                  <span class="bg-slate-200 p-2 rounded-3xl">
                    {sottotitolo}
                  </span>
                {/if}
              </div>
            {:else}
              <span />
            {/if}
            <div class="grid w-full" style="grid-template-columns: 1fr auto;">
              <div class="grid w-full" style="grid-template-columns: 1fr auto;">
                {#if icona_secondaria}
                  <ComponenteIcona valore={icona_secondaria} />
                {:else}
                  <span />
                {/if}
                <div
                  class="grid w-full h-full justify-start content-center text-xs"
                >
                  {testo_icona_secondaria}
                </div>
              </div>
              <div class="grid w-full justify-end">
                <ComponenteIcona
                  valore={attivita ? mdiChevronUp : mdiChevronDown}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="corpo overflow-x-hidden overflow-y-auto pl-2 pr-2"
        style="max-height: {$altezza_animata}px;"
      >
        <slot />
      </div>
    </div>
  </div>
</div>
