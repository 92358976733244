<style lang="scss">
  .Scrollabile {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    padding-bottom: 5rem;
    width: 100%;
    height: 100%;
  }

  .verticale {
    overflow-y: auto;
  }

  .orizzontale {
    overflow-x: auto;
  }
</style>

<script>
  import PullToRefresh from ':components/pull-to-refresh.svelte'
  import { createEventDispatcher, onMount } from 'svelte'
  import { fly } from 'svelte/transition'
  export let verticale = true
  export let orizzontale = false
  export let larghezza = 0
  export let altezza = 0
  /**
   * @type {false | CallableFunction}
   */
  export let ricarica = false
  export let altezza_leva_ricarica = 70
  const emit = createEventDispatcher()

  let Caricamento = new Promise(function start(stop) {
    stop(0)
  })

  let PTROccupato = false
  function abilita_pull_to_refresh() {
    PTROccupato = false
  }
  function disabilita_pull_to_refresh() {
    PTROccupato = true
  }

  onMount(aggiornamento_dimensioni)

  /**
   * @type {HTMLDivElement}
   */
  let scrollabile
  function aggiornamento_dimensioni() {
    if (!scrollabile) {
      misura_piu_tardi()
      return
    }
    larghezza = scrollabile.offsetWidth
    altezza = scrollabile.offsetHeight
    misura_piu_tardi()
  }

  function misura_piu_tardi() {
    setTimeout(function run() {
      requestAnimationFrame(aggiornamento_dimensioni)
    }, 1000)
  }

  /**
   * @param {HTMLDivElement} elemento
   */
  function init(elemento) {
    scrollabile = elemento
  }
</script>

{#await Caricamento}
  <span><!-- caricamento --></span>
{:then _}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div
    use:init
    class="Scrollabile"
    class:verticale
    class:orizzontale
    on:click={function run() {
      emit('click')
    }}
    transition:fly={{ y: 30 }}
  >
    <PullToRefresh
      bind:occupato={PTROccupato}
      contenitore={scrollabile}
      altezza_leva={altezza_leva_ricarica}
      ricarica={async function run() {
        // eslint-disable-next-line no-async-promise-executor
        Caricamento = new Promise(async function start(stop) {
          if (ricarica) {
            await ricarica()
          }
          stop(0)
        })
      }}
    />
    <div class="m-2" />
    <slot
      {larghezza}
      {altezza}
      {abilita_pull_to_refresh}
      {disabilita_pull_to_refresh}
    />
  </div>
{:catch error}
  {error}
{/await}
