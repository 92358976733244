<script>
  import { mdiArrowLeft } from '@mdi/js'
  import { fly } from 'svelte/transition'
  import Icona from ':components/icona.svelte'
  /**
   * @type {false | (function():any)}
   */
  export let azione_indietro = false
  export let titolo = ''
  let cls = 'amber'
  export { cls as class }

  if ($$restProps.class) {
    cls += $$restProps.class
    delete $$restProps.class
  }
</script>

<div
  class="navbar fixed z-50 bg-primary text-primary-content w-full h-5"
  transition:fly={{ y: -30 }}
>
  <button
    class="btn btn-circle btn-ghost"
    on:click={function run() {
      azione_indietro ? azione_indietro() : history.back()
    }}
  >
    <Icona valore={mdiArrowLeft} />
  </button>
  <div class="pr-1" />
  <span class="text-xl">{titolo}</span>
</div>
