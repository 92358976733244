import { get } from 'svelte/store'
import { store_messaggio } from ':stores/store_messaggio'
import { store_registrazione_service_worker } from ':stores/store_registrazione_service_worker'

export async function richiediPermessoNotifiche() {
  return (await Notification.requestPermission()) === 'granted'
}

/**
 * @param {import(':types').PayloadNotifica} payload
 */
export async function notifica({
  titolo,
  testo,
  vibrazione = [200, 100, 200],
  icona = '/images/logo.png',
  tag = '',
}) {
  if (!(await richiediPermessoNotifiche())) {
    console.warn(
      'La notifica non verà inviata perché non si ha i permessi di inviare notifiche locali.',
    )
    store_messaggio.set({
      testo: "L'applicazione non ha il permesso di inviare notitiche.",
    })
    return
  }

  const $store_registrazione_service_worker = get(
    store_registrazione_service_worker,
  )

  if (!$store_registrazione_service_worker) {
    console.warn(
      'You need to install the main worker before sending a notification.',
    )
    store_messaggio.set({
      testo:
        'Il sistema di notifiche locali non è disponibile per il piattaforma attuale.',
    })
    return
  }
  /** @ts-ignore */
  $store_registrazione_service_worker.active.postMessage(
    JSON.stringify({
      action: 'send-notification',
      body: {
        title: titolo,
        body: testo,
        vibrate: vibrazione,
        icon: icona,
        tag: tag,
      },
    }),
  )
}
