import axios from 'axios'

/**
 * @template [TRisultato = any]
 * @param {import(':types').HttpPostPayload} payload
 * @returns {Promise<import(':types').AxiosUnsafe<TRisultato>>}
 */
export async function post({
  indirizzo: url,
  dati: data,
  configurazione: config = {},
}) {
  try {
    return [await axios.post(url, data, config), false]
  } catch (e) {
    //@ts-ignore
    return [false, e]
  }
}

/**
 * @template [TRisultato = any]
 * @param {import(':types').HttpPutPayload} payload
 * @returns {Promise<import(':types').AxiosUnsafe<TRisultato>>}
 */
export async function put({
  indirizzo: url,
  dati: data,
  configurazione: config = {},
}) {
  try {
    return [await axios.put(url, data, config), false]
  } catch (e) {
    //@ts-ignore
    return [false, e]
  }
}

/**
 * @template [TRisultato = any]
 * @param {import(':types').HttpGetPayload} payload
 * @returns {Promise<import(':types').AxiosUnsafe<TRisultato>>}
 */
export async function get({ indirizzo: url, configurazione: config = {} }) {
  try {
    return [await axios.get(url, config), false]
  } catch (e) {
    //@ts-ignore
    return [false, e]
  }
}

/**
 * @template [TRisultato = any]
 * @param {import(':types').HttpDeletePayload} payload
 * @returns {Promise<import(':types').AxiosUnsafe<TRisultato>>}
 */
export async function del({ indirizzo: url, configurazione: config = {} }) {
  try {
    return [await axios.delete(url, config), false]
  } catch (e) {
    //@ts-ignore
    return [false, e]
  }
}
