<script>
  import { mdiPlusCircle } from '@mdi/js'
  import { installa } from ':scripts/installa'
  import { fly } from 'svelte/transition'
  import Icona from ':components/icona.svelte'
  /**
   * @type {string}
   */
  export let testo
</script>

<div class="fixed left-4 right-4 bottom-4 z-10" transition:fly={{ y: 30 }}>
  <button
    class="btn btn-ghost bg-slate-200 w-full rounded-xl p-8 content-center"
    on:click={installa}
  >
    <Icona valore={mdiPlusCircle} />
    <span class="pl-2">{testo}</span>
  </button>
</div>
