<style lang="scss">
  .Ombra {
    background-color: rgba(0, 0, 0, 0.7);
  }
</style>

<script>
  import { mdiCloseCircle } from '@mdi/js'
  import { createEventDispatcher, onDestroy, onMount } from 'svelte'
  import { fade, fly } from 'svelte/transition'
  import ComponenteIcona from ':components/icona.svelte'

  export let visibile = false
  export let titolo = ''
  /**
   * @type {false | string}
   */
  export let icona
  export let osserva_dimensioni = false
  export let larghezza_corpo = 0
  export let altezza_corpo = 0
  export let mostra_pulsante_chiusura = true

  const emit = createEventDispatcher()

  let caricato = false
  onMount(function run() {
    caricato = true
    aggiorna_dimensioni()
  })
  onDestroy(function run() {
    caricato = false
  })

  /**
   * @type {HTMLDivElement}
   */
  let Corpo
  function aggiorna_dimensioni() {
    if (!osserva_dimensioni) return
    if (!caricato) return
    if (!Corpo) {
      misura_piu_tardi()
      return
    }

    larghezza_corpo = Corpo.offsetWidth
    altezza_corpo = Corpo.offsetHeight
    misura_piu_tardi()
  }

  function misura_piu_tardi() {
    setTimeout(function run() {
      requestAnimationFrame(aggiorna_dimensioni)
    }, 100)
  }

  $: aggiornamento_visibilita({ visibile })
  /**
   * @param {{visibile:boolean}} payload
   */
  function aggiornamento_visibilita({ visibile }) {
    if (!visibile) {
      emit('intenzione-chiusura')
    } else {
      emit('intenzione-apertura')
    }
  }
</script>

{#if visibile}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div
    class="Ombra p-4 fixed left-0 right-0 top-0 bottom-0 justify-center content-center text-center z-max"
    on:click={function run() {
      visibile = false
    }}
    transition:fade
  />

  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div
    class="fixed left-1 right-1 top-1 bottom-1 card bg-base-100 shadow-xl z-max"
    on:click|preventDefault
    transition:fly={{ y: 100 }}
  >
    <div class="card-body overflow-y-hidden">
      <div
        class="grid content-center"
        style="
          grid-template-columns: auto 1fr auto;
          grid-template-rows: auto 1fr;
        "
      >
        <span class="h-full">
          {#if icona}
            <ComponenteIcona valore={icona} />
          {/if}
        </span>
        <span class="h-full pl-2 font-semibold">{titolo}</span>
        <span class="h-full">
          {#if mostra_pulsante_chiusura}
            <span
              class="cursor-pointer"
              on:click={function run() {
                visibile = false
              }}
            >
              <ComponenteIcona valore={mdiCloseCircle} />
            </span>
          {/if}
        </span>
      </div>
      <div
        bind:this={Corpo}
        class="col-span-3 overflow-x-hidden overflow-y-auto"
      >
        <slot
          chiudi={function run() {
            visibile = false
          }}
        />
      </div>
    </div>
  </div>
{/if}
