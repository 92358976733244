import { uri } from ':scripts/uri'
import { writable } from 'svelte/store'

function start() {
  /**
   * @type {Record<string, string>}
   */
  const oggetto = uri({ valore: location.search }).decode()

  for (const chiave in oggetto)
    if (
      'token_facilews' === chiave.toLowerCase() ||
      'token' === chiave.toLowerCase()
    )
      return oggetto[chiave]

  return ''
}

export const store_token_facilews = writable(start())
