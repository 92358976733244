<style lang="scss">
  small {
    color: #c1c1c1;
  }
  .Sfondo {
    max-width: 200px;
    max-height: 200px;
    border-radius: 50%;
    background: url('/images/login-header.jpg');
    background-size: auto 100%;
    border-radius: 50%;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
  }
</style>

<script>
  import Accesso from ':components/accesso.svelte'
  import AppBarPrincipale from ':components/app-bar-principale.svelte'
  import BottoneAttivaDisattivaServiceWorker from ':components/bottone-attiva-disattiva-service-worker.svelte'
  import BottoneInstallazione from ':components/bottone-installazione.svelte'
  import FormImpostazionePassword from ':components/form-impostazione-password.svelte'
  import Icona from ':components/icona.svelte'
  import ImpostazioniNotifiche from ':components/impostazioni-notifiche.svelte'
  import ImpostazioniPrivacy from ':components/impostazioni-privacy.svelte'
  import LineaOrizzontale from ':components/linea-orizzontale.svelte'
  import PersonalizzaAspetto from ':components/personalizza-aspetto.svelte'
  import Popup from ':components/popup.svelte'
  import Progresso from ':components/progresso.svelte'
  import Scrollabile from ':components/scrollabile.svelte'
  import {
    mdiBell,
    mdiFileDocument,
    mdiFormatColorFill,
    mdiLock,
    mdiLogout,
  } from '@mdi/js'
  import { dominio } from ':scripts/dominio'
  import { esci } from ':scripts/esci'
  import { store_modalita_pwa } from ':stores/store_modalita_pwa'
  import { store_versione_locale } from ':stores/store_versione_locale'

  let nuova_password = false
  let notifiche = false
  let privacy = false
  let personalizzazioni = false
  let visualizza_app_bar_principale = true
</script>

{#await dominio()}
  <Progresso globale />
{:then dominio}
  <Accesso {dominio}>
    <svelte:fragment let:sessione>
      {#if visualizza_app_bar_principale}
        <AppBarPrincipale
          class="amber"
          titolo="Profilo - {sessione.Utente.Username}"
        />
      {/if}
      {#if !$store_modalita_pwa}
        <BottoneInstallazione testo="Installa" />
      {/if}
      <Scrollabile>
        <div class="pt-10" />
        <div class="grid-x-center">
          <div class="Sfondo w-40 h-40" />
        </div>

        <div class="grid p-0 mt-0">
          <div class="grid mt-0 mb-0 pb-0 pt-0">
            <small class="ml-1 mt-3">ACCOUNT</small>
          </div>
          <LineaOrizzontale />
          <button
            class="p-8 content-center justify-start w-full btn btn-ghost rounded-none"
            on:click={function run() {
              notifiche = true
            }}
          >
            <Icona valore={mdiBell} />
            <span class="pl-8">Notifiche</span>
          </button>

          <LineaOrizzontale />
          <button
            class="p-8 content-center justify-start w-full btn btn-ghost rounded-none"
            on:click={function run() {
              privacy = true
            }}
          >
            <Icona valore={mdiFileDocument} />
            <span class="pl-8">Privacy</span>
          </button>

          <LineaOrizzontale />
          <button
            class="p-8 content-center justify-start w-full btn btn-ghost rounded-none"
            on:click={function run() {
              nuova_password = true
            }}
          >
            <Icona valore={mdiLock} />
            <span class="pl-8">Cambia password</span>
          </button>

          <LineaOrizzontale />
          <button
            class="p-8 content-center justify-start w-full btn btn-ghost rounded-none"
            on:click={function run() {
              personalizzazioni = true
            }}
          >
            <Icona valore={mdiFormatColorFill} />
            <span class="pl-8">Modifica il tema dell'app</span>
          </button>

          <LineaOrizzontale />
          <div class="pt-8" />
          <div class="grid mt-0 mb-0 pb-0 pt-0">
            <small class="ml-1 mt-1">SFERANET APP</small>
          </div>

          <LineaOrizzontale />
          <BottoneAttivaDisattivaServiceWorker />

          <LineaOrizzontale />
          <button
            class="p-8 content-center justify-start w-full btn btn-ghost rounded-none"
            on:click={esci}
          >
            <Icona valore={mdiLogout} />
            <span class="pl-8">Logout</span>
          </button>

          <LineaOrizzontale />
          <div class="pt-8" />

          <div class="p-2">
            Vers. {$store_versione_locale
              ? $store_versione_locale
              : 'Sconosciuta'}
            -
            {sessione.Utente.CodiceAgenzia}
          </div>
        </div>
      </Scrollabile>

      <Popup
        bind:visibile={nuova_password}
        icona={mdiLock}
        titolo="Nuova password"
        on:intenzione-apertura={function run() {
          visualizza_app_bar_principale = false
        }}
        on:intenzione-chiusura={function run() {
          visualizza_app_bar_principale = true
        }}
      >
        <FormImpostazionePassword
          {dominio}
          access_token={sessione.Utente.AccessToken}
          account_login_id={sessione.Utente.AccountLoginId}
          on:password-cambiata={function run() {
            nuova_password = false
          }}
        />
      </Popup>

      <ImpostazioniNotifiche
        bind:visualizza_app_bar_principale
        bind:visibile={notifiche}
        on:intenzione-chiusura={function run() {
          notifiche = false
        }}
      />

      <Popup
        bind:visibile={privacy}
        icona={mdiFileDocument}
        titolo="Impostazioni Privacy"
        on:intenzione-apertura={function run() {
          visualizza_app_bar_principale = false
        }}
        on:intenzione-chiusura={function run() {
          visualizza_app_bar_principale = true
        }}
      >
        <ImpostazioniPrivacy
          on:salvataggio={function run() {
            privacy = false
          }}
        />
      </Popup>

      <PersonalizzaAspetto
        bind:visibile={personalizzazioni}
        {dominio}
        bind:visualizza_app_bar_principale
      />
    </svelte:fragment>
  </Accesso>
{:catch errore}
  <div
    class="p-4 grid fixed h-full w-full justify-center content-center text-center"
  >
    {errore}
  </div>
{/await}
