import axios from 'axios'
import { get } from 'svelte/store'
import { store_url_facilews3 } from ':stores/store_url_facilews3'
import { store_indirizzo_app } from ':stores/store_indirizzo_app'

/**
 * Invia un email con un link che punta alla pagine di firma dell'allegato in questione.
 * @param {{
 *  sessione: import(':types').Sessione,
 *  pratica: import(':types').Pratica,
 *  allegato_pratica: import(':types').AllegatoPratica,
 *  email_cliente: string,
 * }} payload
 */
export async function richiedi_firma_contratto({
  sessione,
  pratica,
  allegato_pratica,
  email_cliente,
}) {
  const $store_url_facilews3 = get(store_url_facilews3)
  const $store_indirizzo_app = get(store_indirizzo_app)

  try {
    /**
     * @type {import(':types').AxiosResponse<import(':types').RispostaFacileWS3<unknown>>}
     */
    await axios.get(
      `${$store_url_facilews3}/Api/Rest/${sessione.Utente.CodiceAgenzia}/Contratto/RichiediFirma/${pratica.PraticaID}/${allegato_pratica.AllegatoID}/${email_cliente}?Token=${sessione.Utente.TokenFacileWS}&IndirizzoAPP=${$store_indirizzo_app}`,
    )
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
