<script>
  import Accesso from ':components/accesso.svelte'
  import AppBarPrincipale from ':components/app-bar-principale.svelte'
  import Progresso from ':components/progresso.svelte'
  import Questionario from ':components/questionario.svelte'
  import Scrollabile from ':components/scrollabile.svelte'
  import { dominio } from ':scripts/dominio'
</script>

{#await dominio()}
  <Progresso globale />
{:then dominio}
  <Accesso {dominio}>
    <svelte:fragment let:sessione>
      <AppBarPrincipale titolo="Questionario utente" />
      <Scrollabile>
        <div class="pl-1 pr-1 pt-4 pb-4">
          <Questionario
            {sessione}
            opzioni={{
              QuestionarioTestataID: '',
              ProfilazioneTestataID: '',
              ParentType: 'ACC_Account',
              ParentID: sessione.Utente.AccountId,
            }}
          />
        </div>
      </Scrollabile>
    </svelte:fragment>
  </Accesso>
{:catch errore}
  <div
    class="p-4 grid fixed h-full w-full justify-center content-center text-center"
  >
    {errore}
  </div>
{/await}
