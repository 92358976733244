import Main from './main.svelte'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { IN_SVILUPPO, IN_TEST, IN_ANTEPRIMA } from ':constants'
import { store_url_facilews3 } from ':stores/store_url_facilews3'
import { store_url_sferanet } from ':stores/store_url_sferanet'
import { inizializza } from ':scripts/inizializza'

if (IN_SVILUPPO) {
  store_url_facilews3.set(`https://facilews3.partnersolution.it`)
  // store_url_facilews3.set(`https://facilews3ts.partnersolution.it`)
  // store_url_facilews3.set(`https://127.0.0.1:8000`)
  store_url_sferanet.set(`https://sferanetts.partnersolution.it`)
  // store_url_facilews3.set(`https://8cfb-217-198-129-9.eu.ngrok.io`)
  // store_url_sferanet.set(`${location.protocol}//${location.host}/Facile`)
  // store_url_sferanet.set(`http://192.168.100.205:1308`)
  window.dispatchEvent(new Event('beforeinstallprompt'))
}

if (IN_TEST) {
  store_url_facilews3.set(`https://facilews3.partnersolution.it`)
  // store_url_facilews3.set(`https://facilews3ts.partnersolution.it`)
  store_url_sferanet.set(`https://sferanetts.partnersolution.it`)
  // store_url_facilews3.set(`https://8cfb-217-198-129-9.eu.ngrok.io`)
  // store_url_sferanet.set(`${location.protocol}//${location.host}/Facile`)
  // store_url_sferanet.set(`http://192.168.100.205:1308`)
}

if (IN_ANTEPRIMA) {
  store_url_facilews3.set(`https://facilews3.partnersolution.it`)
  // store_url_facilews3.set(`https://facilews3ts.partnersolution.it`)
  store_url_sferanet.set(`https://sferanetts.partnersolution.it`)
  // store_url_facilews3.set(`https://8cfb-217-198-129-9.eu.ngrok.io`)
  // store_url_sferanet.set(`${location.protocol}//${location.host}/Facile`)
  // store_url_sferanet.set(`http://192.168.100.205:1308/Facile`)
}

inizializza()

const target = document.getElementById('main')

if (target) {
  new Main({
    target,
  })
} else {
  console.error('Could not find #main element in the document.')
}
