import axios from 'axios'
import { store_otp } from ':stores/store_otp'
import { store_url_facilews3 } from ':stores/store_url_facilews3'
import { get } from 'svelte/store'
import { IN_SVILUPPO } from ':constants'

/**
 * Ottiene un contratto per pratica e allegato.
 * @param {{
 *  sessione: import(':types').Sessione,
 *  pratica: import(':types').Pratica,
 *  allegato:import(':types').AllegatoPratica,
 * }} payload
 */
export async function contratto({ sessione, pratica, allegato }) {
  try {
    const $store_url_facilews3 = get(store_url_facilews3)
    const $store_otp = get(store_otp)
    /**
     * @type {import(':types').AxiosResponse<import(':types').RispostaFacileWS3<import(':types').ContrattoAllegatoPratica>>}
     */
    const risposta = await axios.get(
      `${$store_url_facilews3}/Api/Rest/${sessione.Utente.CodiceAgenzia}/Contratto/FindByPraticaIDAndAllegatoID/${pratica.PraticaID}/${allegato.AllegatoID}?OTP=${$store_otp}&Token=${sessione.Utente.TokenFacileWS}`,
    )
    return risposta.data.data || false
  } catch (e) {
    console.warn(e)
    return false
  }
}

/**
 * @param {{promessa_contratto: Promise<false | import(':types').ContrattoAllegatoPratica>}} payload
 */
export async function contratto_sviluppo({ promessa_contratto }) {
  const Contratto = await promessa_contratto
  if (Contratto) return Contratto
  if (IN_SVILUPPO) {
    return {
      AllegatoID: '',
      TitoloDocumento: location.host,
      TipoDocumento: location.host,
      TestoFirma1: 'testo 1 firma Sviluppo',
      TestoFirma2: 'testo 2 firma Sviluppo',
      TestoFirma3: 'testo 3 firma Sviluppo',
      TestoFirma4: 'testo 4 firma Sviluppo',
      TestoFirma5: 'testo 5 firma Sviluppo',
      TitoloFirma1: 'testo 1 firma Sviluppo',
      TitoloFirma2: 'testo 2 firma Sviluppo',
      TitoloFirma3: 'testo 3 firma Sviluppo',
      TitoloFirma4: 'testo 4 firma Sviluppo',
      TitoloFirma5: 'testo 5 firma Sviluppo',
    }
  }

  return false
}
