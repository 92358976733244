<style lang="scss">
  .Vista {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: hidden;
    overflow-x: hidden;
  }
</style>

<div class="Vista">
  <slot />
</div>
