/**
 *
 * @param {{valore:string}} payload
 */
export function hex({ valore }) {
  const RESULT = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(valore)
  /**
   * @type {[number, number, number]}
   */
  const RGB = RESULT
    ? [
        parseInt(RESULT[1], 16),
        parseInt(RESULT[2], 16),
        parseInt(RESULT[3], 16),
      ]
    : [-1, -1, -1]

  return {
    toRgb() {
      return RGB
    },
    /**
     * @param {{alpha:number}} payload
     */
    toRgba({ alpha }) {
      return [...RGB, alpha]
    },
    toRgbString() {
      return `rgb(${this.toRgb().join(',')})`
    },
    /**
     * @param {{alpha:number}} payload
     */
    toRgbaString({ alpha }) {
      return `rgba(${this.toRgba({ alpha }).join(',')})`
    },
  }
}
