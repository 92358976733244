import { store_registrazione_service_worker } from ':stores/store_registrazione_service_worker'

// const FILE_NAME = NomeFileServiceWorker;
const FILE_NAME = '/service-worker.js'

/**
 *
 * @param {{registrazioni: Array<ServiceWorkerRegistration>}} payload
 */
export async function trova_service_worker({ registrazioni }) {
  if (registrazioni.length > 0) {
    let reg = null
    for (let i = 0; i < registrazioni.length; i++) {
      if (
        registrazioni[i].active &&
        /** @ts-ignore */
        window.location.origin + FILE_NAME === registrazioni[i].active.scriptURL
      ) {
        reg = registrazioni[i]
        break
      }
    }
    if (reg === null) console.info(`Service worker '${FILE_NAME}' non trovato!`)
    else {
      console.info(`Service worker '${FILE_NAME}' trovato!`)
      store_registrazione_service_worker.set(reg)
    }
  } else {
    try {
      await navigator.serviceWorker.register(FILE_NAME)
      const wk = await navigator.serviceWorker.ready
      store_registrazione_service_worker.set(wk)
    } catch (e) {
      console.warn(
        `Non è stato possibile registrare il service worker '${FILE_NAME}'.`,
      )
      console.warn(e)
    }
  }
}
