<script>
  import { scale } from 'svelte/transition'
  export let attivo = false
  export let testo = ''
  export let piatto = false
  export let disabilitato = false
  export let descrizione = ''

  function interruttore() {
    if (disabilitato) {
      return
    }
    attivo = !attivo
  }
</script>

<div transition:scale={{ duration: 200 }}>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div
    class="relative cursor-pointer {piatto
      ? ''
      : 'bg-base-200'} p-4 rounded-3xl z-10"
    on:click={interruttore}
  >
    <div
      class="w-full grid content-start justify-start"
      style="grid-template-columns: auto 1fr;"
    >
      <div class="grid w-full h-full content-start justify-start">
        <input
          disabled={disabilitato}
          type="checkbox"
          bind:checked={attivo}
          on:change
          class="toggle toggle-info bg-base-300 pointer-events-none"
        />
      </div>
      <div
        class="pl-2 w-full label-text text-start"
        class:opacity-80={disabilitato}
      >
        <div class="pt-0.5" />
        <span>{testo}</span>
      </div>
    </div>
  </div>
  {#if descrizione}
    <div
      class="relative pl-1 pr-1 pt-12 cursor-pointer {piatto
        ? ''
        : 'bg-base-200'} rounded-3xl z-0 -top-10 bg-opacity-50"
    >
      <small>
        <span class="opacity-80">{descrizione}</span>
      </small>
    </div>
  {/if}
</div>
