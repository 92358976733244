import axios from 'axios'
import { get } from 'svelte/store'
import { store_url_sgp1 } from ':stores/store_url_sgp1'

export async function offerte() {
  try {
    const $store_url_sgp1 = get(store_url_sgp1)
    if ('https://' === $store_url_sgp1.trim()) {
      return false
    }

    /**
     * @type {import(':types').AxiosResponse<{ data: Array<import(':types').Offerta> }>}
     */
    const response = await axios.get(
      `${$store_url_sgp1}/?option=com_ajax&module=offerte_search2&format=json&method=getOfferte&id=null`,
    )

    /**
     * @type {import(':types').PacchettoOfferte}
     */
    const risultato = {
      Categorie: {},
      NomiCategorie: [],
      Offerte: [],
    }
    risultato.Offerte = response.data.data

    /**
     * @type {Record<string, boolean>}
     */
    const categorie = {}
    for (let i = 0; i < risultato.Offerte.length; i++) {
      if (!risultato.Offerte[i].imageso) continue
      categorie[risultato.Offerte[i].tipo_title ?? ''] = true
    }
    risultato.NomiCategorie = Object.keys(categorie)
    risultato.Categorie = categorie

    return risultato
  } catch (e) {
    return false
  }
}
