import axios from 'axios'
import { get } from 'svelte/store'
import { store_url_facilews3 } from ':stores/store_url_facilews3'

/**
 * @template T
 * @param {{otp:string}} payload
 * @returns {Promise<T>}
 */
export async function opzioni({ otp }) {
  const $store_url_facilews3 = get(store_url_facilews3)
  /**
   * @type {import(':types').RispostaFacileWS3<T>}
   */
  const risposta = await axios.get(
    `${$store_url_facilews3}/Api/Rest/OTP/FindOpzioni?OTP=${otp}`,
  )
  /** @ts-ignore */
  return risposta.data.data
}
/**
 * @template T
 * @param {{otp:string}} payload
 * @returns {Promise<T>}
 */
export async function metadati({ otp }) {
  const $store_url_facilews3 = get(store_url_facilews3)
  /**
   * @type {import(':types').RispostaFacileWS3<T>}
   */
  const risposta = await axios.get(
    `${$store_url_facilews3}/Api/Rest/OTP/FindMetadati?OTP=${otp}`,
  )
  /** @ts-ignore */
  return risposta.data.data
}
