import axios from 'axios'
import { get } from 'svelte/store'
import { sferanetqbe } from ':scripts/sferanetqbe'
import { IOTNULL } from ':constants'
import { store_messaggio } from ':stores/store_messaggio'
import { store_url_sferanet } from ':stores/store_url_sferanet'

/**
 * Ottiene i passeggeri di una pratica
 * @param {{ sessione:import(':types').Sessione, pratica:import(':types').Pratica }} payload
 */
export async function passeggeri({ sessione, pratica }) {
  try {
    const $store_url_sferanet = get(store_url_sferanet)

    const search = sferanetqbe()
      .uguale({ chiave: 'PraticaID', valore: pratica.PraticaID })
      .toString()

    /**
     * @type {import(':types').AxiosResponse<{PRTPraticapasseggero: Array<import(':types').PasseggeroPratica>}>}
     */
    const response = await axios.get(
      `${$store_url_sferanet}/CompFacileAccounts/PRTPraticapasseggero.json?${search}`,
      {
        headers: {
          codiceagenzia: sessione.Utente.CodiceAgenzia,
          accesstoken: sessione.Utente.AccessToken,
        },
      },
    )
    // @ts-ignore
    if (IOTNULL === response.data) {
      store_messaggio.set({ testo: IOTNULL })
      return []
    }
    return response.data.PRTPraticapasseggero || []
  } catch (e) {
    console.warn(e)
    return []
  }
}
