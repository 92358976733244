<style lang="scss">
  .Sfondo {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    position: relative;
    .Versione {
      position: absolute;
      left: 1rem;
      bottom: 1rem;
      color: #ffffff;
    }
  }

  @media only screen and (max-width: 900px) {
    .Sfondo {
      position: relative;
      justify-items: center;
      padding-left: 0;
      padding-right: 0;
      .grid {
        align-items: end;
        width: 100%;
      }
    }
  }
</style>

<script>
  import AppBarVisitatore from ':components/app-bar-visitatore.svelte'
  import FormAccesso from ':components/form-accesso.svelte'
  import FormImpostazionePassword from ':components/form-impostazione-password.svelte'
  import ImpostazioniPrivacy from ':components/impostazioni-privacy.svelte'
  import Scrollabile from ':components/scrollabile.svelte'
  import SfondoPaginaAccesso from ':components/sfondo-pagina-accesso.svelte'

  import { DURATA_SESSIONE } from ':constants'
  import { dayjs } from ':scripts/dayjs'
  import { store_otp } from ':stores/store_otp'
  import { store_token_facilews } from ':stores/store_token_facilews'

  import { accedi } from ':scripts/accedi'
  import { esci } from ':scripts/esci'

  import { store_accesso_in_corso } from ':stores/store_accesso_in_corso'
  import { store_accesso_terze_parti_in_corso } from ':stores/store_accesso_terze_parti_in_corso'
  import { store_impostazioni_privacy_prese_di_vista } from ':stores/store_impostazioni_privacy_prese_di_vista'
  import { store_mantieni_sessione_app } from ':stores/store_mantieni_sessione_app'
  import { store_password } from ':stores/store_password'
  import { store_sessione_app } from ':stores/store_sessione_app'
  import { store_sessione_app_scaduta } from ':stores/store_sessione_app_scaduta'
  import { store_username } from ':stores/store_username'
  import { store_versione_locale } from ':stores/store_versione_locale'

  import { trova_impostazioni_privacy } from ':scripts/trova_impostazioni_privacy'
  import { onDestroy, onMount } from 'svelte'

  import Icona from ':components/icona.svelte'
  import Progresso from ':components/progresso.svelte'
  import { mdiRefresh } from '@mdi/js'
  import { fade, fly } from 'svelte/transition'
  /**
   * @type {import(':types').MetadatiDominio}
   */
  export let dominio
  let larghezza = 0
  let altezza = 0
  /**
   * @type {import(':types').Sessione}
   */
  let sessione
  let accedi_automaticamente = false
  let pronto = false
  let aggiornamento_consensi_privacy = 0

  $: {
    accedi_automaticamente =
      $store_mantieni_sessione_app &&
      '' !== $store_username.trim() &&
      btoa('') !== $store_password &&
      !$store_sessione_app
  }

  $: if ($store_sessione_app) sessione = $store_sessione_app

  onMount(async function run() {
    pronto = true
    if (
      $store_otp ||
      (!$store_sessione_app && $store_token_facilews) ||
      ($store_sessione_app && $store_sessione_app.Tipo === 'otp')
    ) {
      accedi_automaticamente = true
      $store_accesso_in_corso = true
      $store_sessione_app = await accedi({ dominio })
      $store_accesso_in_corso = false
      accedi_automaticamente = false
    }
    sorveglia_sessione()
  })

  onDestroy(function start() {
    pronto = false
  })

  let controllo_sessione_in_corso = false
  async function sorveglia_sessione() {
    if (
      $store_mantieni_sessione_app &&
      $store_sessione_app &&
      !controllo_sessione_in_corso
    ) {
      controllo_sessione_in_corso = true
      const adesso = Date.now()
      const sessione_scaduta =
        adesso - $store_sessione_app.DataCreazione > DURATA_SESSIONE
      if (sessione_scaduta) {
        console.info(
          'La store_sessione_app sta per scadere, tentativo recupero nuova store_sessione_app...',
        )
        $store_sessione_app = await accedi({ dominio })
        if (!$store_sessione_app) {
          $store_sessione_app_scaduta = true
          esci()
        } else {
          console.info('Nuova store_sessione_app recuperata con successo.')
        }
      }
    }

    if (pronto) {
      setTimeout(sorveglia_sessione, 1000)
    }
    controllo_sessione_in_corso = false
  }

  /**
   * @param {{impostazioni:Array<import(':types').ImpostazionePrivacy>}} param0
   */
  function impostazioni_non_prese_di_vista({ impostazioni }) {
    const consensi_non_presi_di_vista = impostazioni.filter(function pass(
      impostazione,
    ) {
      return ![1, 0].includes(impostazione.ConsensoAccordato)
    })

    // const ConsensiNonPresiDiVista = Impostazioni

    $store_impostazioni_privacy_prese_di_vista =
      0 === consensi_non_presi_di_vista.length

    return consensi_non_presi_di_vista
  }
  $: console.log(
    'store_accesso_terze_parti_in_corso:',
    $store_accesso_terze_parti_in_corso,
  )
</script>

<svelte:window bind:innerWidth={larghezza} bind:innerHeight={altezza} />

{#if $store_sessione_app && !accedi_automaticamente}
  <div class="grid relative full-width full-height">
    {#if dayjs(sessione.Utente.DataScadenzaPassword).isBefore(Date.now())}
      <!--
        Se la password è scaduta, proponi il form per impostare una nuova password.
      -->
      <AppBarVisitatore
        titolo="Imposta nuova password"
        azione_indietro={esci}
      />
      <Scrollabile let:larghezza>
        <div class="mt-2" />
        <div class="p-1">
          <p>
            La password che hai appena utilizzato per eseguire l'accesso è
            scaduta.<br />
            E' necessario impostare una nuova password.
          </p>
          <FormImpostazionePassword
            access_token={sessione.Utente.AccessToken}
            account_login_id={sessione.Utente.AccountLoginId}
            {dominio}
          />
        </div>
      </Scrollabile>
    {:else}
      {#key aggiornamento_consensi_privacy}
        {#await trova_impostazioni_privacy()}
          <div class="mt-2" />
          <!-- <div
            class="p-4 grid w-full h-full justify-center content-center text-center"
            transition:scale
          > -->
          <Progresso globale />
          <!-- </div> -->
        {:then impostazioni}
          <!-- {#if !$store_otp && impostazioniNonPreseDiVista(Impostazioni).length > 0} -->
          {#if impostazioni_non_prese_di_vista({ impostazioni }).length > 0}
            <AppBarVisitatore
              titolo="Impostazioni privacy"
              azione_indietro={esci}
            />
            <Scrollabile let:larghezza>
              <div class="pt-4" />
              <div class="p-4 w-full grid justify-center">
                <ImpostazioniPrivacy
                  on:salvataggio={function run() {
                    aggiornamento_consensi_privacy = Date.now()
                  }}
                />
              </div>
            </Scrollabile>
          {:else}
            <slot {sessione} />
          {/if}
        {:catch errore}
          <div
            class="p-4 grid w-full h-full justify-center content-center text-center"
            transition:fade
          >
            {errore}
            <div class="pt-2" />
            <button class="btn btn-primary w-full" on:click={esci}>
              <Icona valore={mdiRefresh} />
              <span>Reset</span>
            </button>
          </div>
        {/await}
      {/key}
    {/if}
  </div>
{:else if $store_otp}
  <div
    class="fixed left-0 right-0 top-0 bottom-0 p-4 grid w-full h-full justify-center content-center text-center"
  >
    <Progresso />
  </div>
{:else}
  <div class="Sfondo">
    {#if $store_accesso_terze_parti_in_corso}
      <div
        class="p-4 grid w-full h-full justify-center content-center text-center"
        transition:fade
      >
        <Progresso />
      </div>
    {:else}
      <SfondoPaginaAccesso {dominio}>
        <FormAccesso mostra_versione {accedi_automaticamente} {dominio} />
        {#if accedi_automaticamente}
          <div
            class="absolute left-0 right-0 top-0 bottom-0 justify-center content-center"
            transition:fade
          >
            <Progresso />
          </div>
        {/if}
      </SfondoPaginaAccesso>
    {/if}

    {#if larghezza > 900}
      <div class="Versione" transition:fly={{ y: 100 }}>
        {$store_versione_locale}
      </div>
    {/if}
  </div>
{/if}
