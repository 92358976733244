<script>
  import { mdiAccount, mdiArrowLeft, mdiDotsVertical, mdiLogout } from '@mdi/js'
  import { esci } from ':scripts/esci'
  import { navigate } from 'svelte-routing'
  import { fly } from 'svelte/transition'
  import Icona from ':components/icona.svelte'
  export let nascondi_menu = false
  export let titolo = ''
  let cls = 'amber'
  export { cls as class }

  if ($$restProps.class) {
    cls += $$restProps.class
    delete $$restProps.class
  }
</script>

<div
  class="navbar fixed z-50 bg-primary text-primary-content w-full h-5 grid grid-cols-2"
  style="grid-template-columns: 1fr auto;"
  transition:fly={{ y: -30 }}
>
  <slot />
  <div class="justify-self-start w-full">
    {#if !nascondi_menu}
      <button
        class="btn btn-circle btn-ghost"
        on:click={function run() {
          history.back()
        }}
      >
        <Icona valore={mdiArrowLeft} />
      </button>
    {/if}
    <div class="pr-1" />
    {#if nascondi_menu}
      <!-- content here -->
      <div class="w-full text-sm text-center">{titolo}</div>
    {:else}
      <div class="w-full text-sm">{titolo}</div>
    {/if}
  </div>
  <div class="justify-self-end">
    <div class="dropdown dropdown-end">
      {#if !nascondi_menu}
        <button tabindex="0" class="btn btn-circle btn-ghost">
          <Icona valore={mdiDotsVertical} />
        </button>
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <ul
          tabindex="0"
          class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
        >
          <li>
            <button
              on:click={function run() {
                navigate('/profilo')
              }}
            >
              <Icona valore={mdiAccount} />
              <span>Profilo</span>
            </button>
          </li>
          <li>
            <button on:click={esci}>
              <Icona valore={mdiLogout} />
              <span>Logout</span>
            </button>
          </li>
        </ul>
      {/if}
    </div>
  </div>
</div>
