<script>
  import AccessoNonRichiesto from ':components/accesso-non-richiesto.svelte'
  import Icona from ':components/icona.svelte'
  import Progresso from ':components/progresso.svelte'
  import Scrollabile from ':components/scrollabile.svelte'
  import axios from 'axios'
  import { store_otp } from ':stores/store_otp'
  import { store_messaggio } from ':stores/store_messaggio'
  import { store_url_facilews3 } from ':stores/store_url_facilews3'
  import { mdiClose, mdiProgressCheck } from '@mdi/js'
  import { dominio } from ':scripts/dominio'
  import { metadati, opzioni } from ':scripts/otp'
  import { IN_SVILUPPO } from ':constants'

  const Operazioni = ['autorizza-firma']

  let esecuzione_azione_in_corso = false
  let azione_eseguita = false

  function operazione() {
    /**
     * @type {Promise<import(':types').OpzioniOperazione>}
     */
    return opzioni({ otp: $store_otp })
  }

  async function crea_metadati() {
    /**
     * @type {{
     *  DataScadenza: null | string
     *  DataUtilizzo: null | string
     * }}
     */
    const risposta = await metadati({ otp: $store_otp })

    return {
      DataScadenza: risposta.DataScadenza
        ? new Date(risposta.DataScadenza)
        : false,
      DataUtilizzo: risposta.DataUtilizzo
        ? new Date(risposta.DataUtilizzo)
        : false,
    }
  }

  /**
   *
   * @param {{operazione: import(':types').OpzioniOperazione}} payload
   */
  async function autorizza({ operazione }) {
    try {
      const URL = IN_SVILUPPO
        ? operazione.URL.replace(
            'https://facilews3.partnersolution.it',
            `${$store_url_facilews3}`,
          )
        : operazione.URL
      const Metodo = operazione.Method.toUpperCase()
      esecuzione_azione_in_corso = true
      if ('GET' === Metodo) {
        const risposta = await axios.get(`${URL}?otp=${$store_otp}`)
        console.log({ risposta })
      } else if ('DELETE' === Metodo) {
        const risposta = await axios.delete(`${URL}?otp=${$store_otp}`)
        console.log({ risposta })
      } else if ('POST' === Metodo) {
        const risposta = await axios.post(
          `${URL}?otp=${$store_otp}`,
          operazione.Body,
        )
        console.log({ risposta })
      } else if ('PUT' === Metodo) {
        const risposta = await axios.put(
          `${URL}?otp=${$store_otp}`,
          operazione.Body,
        )
        console.log({ risposta })
      } else {
        throw new Error(`Metodo '${Metodo}' sconosciuto`)
      }
      azione_eseguita = true
    } catch (e) {
      $store_messaggio = { testo: `${e}` }
      azione_eseguita = false
    }
    esecuzione_azione_in_corso = false
  }
</script>

{#await dominio()}
  <Progresso globale />
{:then dominio}
  {#if azione_eseguita}
    <AccessoNonRichiesto>
      <svelte:fragment>
        <Scrollabile>
          <div class="grid p-4 justify-center">
            <div class="card max-w-xl bg-base-100 shadow-xl">
              <div class="card-body">
                <h2 class="card-title text-green-700">
                  <Icona valore={mdiProgressCheck} />
                  <span>Operazione autorizzata con successo!</span>
                </h2>
                <div class="pt-2" />
                <p class="text-center">Puoi chiudere questa pagina.</p>
              </div>
            </div>
          </div>
        </Scrollabile>
      </svelte:fragment>
    </AccessoNonRichiesto>
  {:else if $store_otp}
    {#await Promise.all([operazione(), crea_metadati()])}
      <Progresso globale />
    {:then [operazione, MetadatiOperazione]}
      {#if MetadatiOperazione.DataUtilizzo}
        <AccessoNonRichiesto>
          <svelte:fragment>
            <Scrollabile>
              <div class="grid p-4 justify-center">
                <div class="card max-w-xl bg-base-100 shadow-xl">
                  <div class="card-body">
                    <h2 class="card-title text-green-700">
                      <Icona valore={mdiProgressCheck} />
                      <span>Autorizzazione già acquisita</span>
                    </h2>
                    <div class="pt-2" />
                    <p class="text-center">
                      La richiesta di autorizzazione è già stata acquisita, in
                      caso di dubbi o chiarimenti si prega di contattare
                      l'agenzia venditrice.<br />
                      Grazie, buon viaggio!
                    </p>
                  </div>
                </div>
              </div>
            </Scrollabile>
          </svelte:fragment>
        </AccessoNonRichiesto>
      {:else if Operazioni.includes(operazione.Operazione)}
        {#if MetadatiOperazione.DataScadenza < new Date(Date.now())}
          <AccessoNonRichiesto>
            <svelte:fragment>
              <Scrollabile>
                <div class="grid p-4 justify-center">
                  <div class="card max-w-xl bg-base-100 shadow-xl">
                    <div class="card-body">
                      <h2 class="card-title text-red-700">
                        <Icona valore={mdiClose} />
                        <span>Autorizzazione Non Valida</span>
                      </h2>
                      <div class="pt-2" />

                      {#if MetadatiOperazione.DataScadenza && true !== MetadatiOperazione.DataScadenza}
                        <span>
                          Autorizzazione scaduta
                          {MetadatiOperazione.DataScadenza.toLocaleDateString(
                            'it-IT',
                            {
                              weekday: 'long',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                            },
                          )}
                        </span>
                      {:else}
                        <span>Data Scadenza Sconosciuta</span>
                      {/if}
                    </div>
                  </div>
                </div>
              </Scrollabile>
            </svelte:fragment>
          </AccessoNonRichiesto>
        {:else}
          <AccessoNonRichiesto>
            <svelte:fragment>
              <Scrollabile>
                <div class="grid p-4 justify-center">
                  <div class="card max-w-xl bg-base-100 shadow-xl">
                    <div class="card-body">
                      <h2 class="card-title">
                        <Icona valore={mdiProgressCheck} />
                        <span>Autorizza operazione</span>
                      </h2>
                      <div class="pt-2" />
                      <p class="bg-slate-200 p-4 rounded-3xl">
                        {operazione.Descrizione}
                      </p>
                      <div class="pt-2" />
                      <div class="card-actions justify-center">
                        {#if esecuzione_azione_in_corso}
                          <Progresso />
                        {:else}
                          <button
                            class="btn btn-primary"
                            on:click={function run() {
                              autorizza({ operazione })
                            }}
                          >
                            <span>Autorizza</span>
                          </button>
                        {/if}
                      </div>
                    </div>
                  </div>
                </div>
              </Scrollabile>
            </svelte:fragment>
          </AccessoNonRichiesto>
        {/if}
      {:else}
        <div
          class="p-4 grid fixed h-full w-full justify-center content-center text-center"
        >
          <span>Operazione non riconosciuta</span>
        </div>
      {/if}
    {:catch errore}
      <div
        class="p-4 grid fixed h-full w-full justify-center content-center text-center"
      >
        <span>{errore}</span>
      </div>
    {/await}
  {:else}
    <div
      class="p-4 grid fixed h-full w-full justify-center content-center text-center"
    >
      <span>Operazione non riconosciuta</span>
    </div>
  {/if}
{:catch errore}
  <div
    class="p-4 grid fixed h-full w-full justify-center content-center text-center"
  >
    <span>{errore}</span>
  </div>
{/await}
