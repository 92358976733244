import { storable } from ':scripts/storable'

/**
 * @type {import('svelte/store').Writable<import(':types').FiltroElencoPratiche>}
 */
export const store_input_piu_recente_ricerca_pratiche = storable({
  name: 'store_input_piu_recente_ricerca_pratiche',
  default_value: {
    DataVendita: [false, false],
    DataPartenza: [false, false],
    DataRientro: [false, false],
    DataCreazione: [false, false],
    NumeroAllegati: [0, 99999],
    NumeroPratica: '',
    Destinazione: '',
    Descrizione: '',
    CognomeNomeCliente: '',
  },
})
