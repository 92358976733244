<style>
  .top {
    align-self: flex-start;
  }

  .mid {
    align-self: flex-end;
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .mid-right {
    align-self: flex-end;
  }

  .sfondo-pagina-accesso {
    color: #fff;
    overflow-y: auto;
    display: grid;
    grid-template-rows: 1fr 1fr 0;
    position: relative;
    width: 100%;
    height: 100%;
    background-position: center;
    position: absolute;
    background-size: cover !important;
  }

  @media only screen and (max-width: 900px) {
    .sfondo-pagina-accesso {
      grid-template-rows: auto auto 1fr;
    }
  }
</style>

<script>
  import { store_messaggio } from ':stores/store_messaggio'

  import Progresso from ':components/progresso.svelte'
  import axios from 'axios'

  /**
   * @type {import(':types').MetadatiDominio}
   */
  export let dominio
  let url_immagine = ''
  /**
   * @type {HTMLImageElement}
   */
  let immagine
  /**
   * @type {HTMLSpanElement}
   */
  let immagine_mancante
  let Pronto = false

  async function sfondo() {
    if (dominio.Nome === '') {
      url_immagine = `/static/assets/images/splash.jpg`
      return `/static/assets/images/splash.jpg`
    } else if (
      dominio.Immagine.startsWith('http') ||
      dominio.Immagine.startsWith('/')
    ) {
      await axios.get(dominio.Immagine)
      url_immagine = dominio.Immagine
      return dominio.Immagine
    } else if (dominio.Immagine.trim().startsWith('http')) {
      await axios.get(dominio.Immagine)
      url_immagine = dominio.Immagine
      return dominio.Immagine
    } else {
      url_immagine = `data:image/jpeg;base64,${dominio.Immagine}`
      return `data:image/jpeg;base64,${dominio.Immagine}`
    }
  }

  $: if (immagine) {
    if (immagine.complete) {
      Pronto = true
    } else {
      immagine.addEventListener('load', function run() {
        Pronto = true
      })
      immagine.addEventListener('error', function run(errore) {
        $store_messaggio = { testo: errore.message }
      })
    }
  }
  $: if (immagine_mancante) {
    Pronto = true
  }
</script>

{#await sfondo() then URL}
  <img
    bind:this={immagine}
    width="0"
    height="0"
    src={URL}
    alt="Immagine di sfondo"
  />
{:catch}
  <span bind:this={immagine_mancante} />
{/await}

{#if Pronto}
  <!-- '/static/assets/images/sfondo-pagina-accesso.jpg' -->
  <div
    class="sfondo-pagina-accesso"
    style="background: url('{url_immagine ?? ''}')"
  >
    <div class="top pl-1 pr-1">
      <h6>{dominio.Nome}</h6>
      <span>Accedi per continuare!</span>
    </div>
    <div class="mid pl-1 pr-1 pb-1">
      <div class="mid-left" />
      <div class="mid-right" />
    </div>
    <div class="bottom" />
  </div>
  <slot />
{:else}
  <Progresso globale />
{/if}
