import axios from 'axios'
import { get } from 'svelte/store'
import { store_messaggio } from ':stores/store_messaggio'
import { store_url_facilews3 } from ':stores/store_url_facilews3'
import { store_otp } from ':stores/store_otp'
import { store_sessione_app } from ':stores/store_sessione_app'

let SalvataggioInCorso = false

/**
 *
 * @param {{impostazione:import(':types').ImpostazionePrivacy}} payload
 * @returns
 */
export async function salva_impostazioni_privacy({ impostazione }) {
  try {
    const $store_url_facilews3 = get(store_url_facilews3)
    const $store_otp = get(store_otp)
    const $store_sessione_app = get(store_sessione_app)
    if (!$store_sessione_app) {
      throw new Error('Nessuna sessione trovata.')
    }

    if (SalvataggioInCorso) {
      store_messaggio.set({
        testo: `Attendi il salvataggio delle modifiche prima di salvare nuovamente.`,
      })
      return false
    }
    SalvataggioInCorso = true
    if (2 === impostazione.ConsensoAccordato) {
      store_messaggio.set({ testo: 'Completare modulo' })
      SalvataggioInCorso = false
      return false
    }
    if (!$store_sessione_app) {
      store_messaggio.set({
        testo: `Non è possibile salvare le impostazioni di privacy senza una sessione valida.`,
      })
      SalvataggioInCorso = false
      return false
    }

    /**
     * @type {import(':types').AxiosResponse<import(':types').RispostaFacileWS3<boolean>>}
     */
    await axios.post(
      `${$store_url_facilews3}/Api/Rest/${$store_sessione_app.Utente.CodiceAgenzia}/ImpostazioniPrivacyAccount/${$store_sessione_app.Utente.AccountId}/${impostazione.AccountConsensiGDPRID}/Salva?OTP=${$store_otp}&Token=${$store_sessione_app.Utente.TokenFacileWS}`,
      {
        ConsensoAccordato: impostazione.ConsensoAccordato,
      },
    )
    SalvataggioInCorso = false
    return true
  } catch (e) {
    console.error(e)
    SalvataggioInCorso = false
    return false
  }
}
