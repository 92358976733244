import axios from 'axios'
import { get } from 'svelte/store'
import { IOTNULL } from ':constants'
import { store_messaggio } from ':stores/store_messaggio'
import { store_url_sferanet } from ':stores/store_url_sferanet'

/**
 * Ottiene totale del cliente, saldo del cliente,
 * valore reddittività, percentuale reddittività,
 * numero totale dei biglietti, numero totale dei servizi della pratica indicata.
 * @param {{ sessione: import(':types').Sessione, pratica: import(':types').Pratica}} payload
 * @param fileNumber numero pratica
 */
export async function dettaglio_pratica({ sessione, pratica }) {
  try {
    const $store_url_sferanet = get(store_url_sferanet)

    /**
     * @type {import(':types').AxiosResponse<Array<import(':types').DettaglioPratica>>}
     */
    const response = await axios.post(
      `${$store_url_sferanet}/CompFacileLogin/DettaglioPraticaAPP.json`,
      JSON.stringify({
        PraticaID: pratica.PraticaID,
      }),
      {
        headers: {
          'codiceagenzia': sessione.Utente.CodiceAgenzia,
          'accesstoken': sessione.Utente.AccessToken,
          'x-http-method-override': 'GetDettaglioPratica',
          'content-type': 'application/json',
        },
      },
    )

    // @ts-ignore
    if (IOTNULL === response.data) {
      store_messaggio.set({ testo: IOTNULL })
      return false
    }

    if (
      typeof response.data === 'string' &&
      'nessuna pratica trovata' ===
        // @ts-ignore
        response.data.toLowerCase()
    )
      return false

    return response.data[0] || false
  } catch (e) {
    console.warn(e)
    return false
  }
}
