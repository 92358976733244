import { get } from 'svelte/store'
import { store_messaggio } from ':stores/store_messaggio'
import { store_versione_locale } from ':stores/store_versione_locale'
import { trova_service_worker } from './trova_service_worker'

export async function inizializza() {
  const $store_versione_locale = get(store_versione_locale)
  try {
    const risposta = await fetch('/version')
    const versione_remota = await risposta.text()
    aggiornamenti({
      versione_locale: $store_versione_locale,
      versione_remota: versione_remota,
    })
  } catch (e) {
    aggiornamenti({
      versione_locale: $store_versione_locale,
      versione_remota: $store_versione_locale,
    })
  }
}

/**
 * @param {{
 *  versione_locale:string,
 *  versione_remota:string,
 * }} payload
 */
function feedback({ versione_locale, versione_remota }) {
  if (versione_locale !== versione_remota) {
    //location.reload(true);
    store_messaggio.set({
      testo: `Nuova versione disponibile ${versione_remota}`,
      durata: 1000 * 60 * 30, //30 minuti,
      priorita: 9,
      bottoni: [
        {
          testo: 'Aggiorna',
          async azione() {
            store_messaggio.set(false)
            console.log(
              `Aggiornamento in corso dalla versione ${versione_locale} alla versione ${versione_remota}`,
            )
            if (window.caches) {
              const keyList = await caches.keys()
              await Promise.all(
                keyList.map(function pass(key) {
                  return caches.delete(key)
                }),
              )
              const registrazioni =
                await window.navigator.serviceWorker.getRegistrations()
              for (const registrazione of registrazioni) {
                void registrazione.unregister()
              }
            }
            store_versione_locale.set(versione_remota)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /** @ts-ignore */
            location.reload(true)
          },
        },
        {
          testo: 'Chiudi',
          azione() {
            store_messaggio.set(false)
          },
        },
      ],
    })
  }
}

/**
 * @param {{
 *  versione_locale:string,
 *  versione_remota:string,
 * }} payload
 */
function aggiornamenti({ versione_locale, versione_remota }) {
  try {
    console.log('versioni:', {
      versione_locale,
      versione_remota,
    })
    feedback({ versione_locale, versione_remota })

    if ('serviceWorker' in navigator) {
      void navigator.serviceWorker
        .getRegistrations()
        .then(function run(registrazioni) {
          // @ts-ignore
          return trova_service_worker({ registrazioni })
        })
    }
  } catch (e) {
    console.error(e)
  }
}
