<style lang="scss">
  // .IndizioFirma {
  //   height: 2px;
  //   width: 70vw;
  //   background-color: #ff0000;
  //   pointer-events: none;
  // }
</style>

<script>
  import Espansione from ':components/espansione.svelte'
  import Icona from ':components/icona.svelte'
  import LineaOrizzontale from ':components/linea-orizzontale.svelte'
  import Popup from ':components/popup.svelte'
  import { mdiEye, mdiSignatureFreehand } from '@mdi/js'

  /**
   * @type {import(':types').Sessione}
   */
  export let sessione
  export let utente_applicativo_puo_firmare = false
  /**
   * @type {boolean}
   */
  export let visualizza_app_bar_principale
  /**
   * @type {import(':types').ContrattoAllegatoPratica}
   */
  export let contratto
  /**
   * @type {function():void}
   */
  export let abilita_pull_to_refresh
  /**
   * @type {function():void}
   */
  export let disabilita_pull_to_refresh
  // /**
  //  * @type {[
  //  * false|import(':types').FirmaContratto,
  //  * false|import(':types').FirmaContratto,
  //  * false|import(':types').FirmaContratto,
  //  * false|import(':types').FirmaContratto,
  //  * false|import(':types').FirmaContratto,
  //  * ]}
  //  */
  // export let firme64 = [false, false, false, false, false] //massimo 5 firme, non inviare di più
  /**
   * @type {number}
   */
  export let numero_clausole_valide
  let lettura_in_corso = [false, false, false, false, false]
  // let firma_in_corso = [false, false, false, false, false]

  /**
   * @type {[boolean,boolean,boolean,boolean,boolean]}
   */
  export let clausole_accettate = [false, false, false, false, false]

  // let salvataggio_in_corso = false
  // let larghezza_corpo_firma = 1
  // let altezza_corpo_firma = 1

  let clausole_disponibili = clausole({ contratto })

  /**
   * @param {{contratto: import(':types').ContrattoAllegatoPratica}} payload
   */
  function clausole({ contratto }) {
    const clausole = [
      { titolo: contratto.TitoloFirma1, testo: contratto.TestoFirma1 },
      { titolo: contratto.TitoloFirma2, testo: contratto.TestoFirma2 },
      { titolo: contratto.TitoloFirma3, testo: contratto.TestoFirma3 },
      { titolo: contratto.TitoloFirma4, testo: contratto.TestoFirma4 },
      { titolo: contratto.TitoloFirma5, testo: contratto.TestoFirma5 },
    ]
    const clausole_valide = clausole.filter(function pass(clausola) {
      return clausola.testo.trim()
    })
    numero_clausole_valide = clausole_valide.length
    return clausole
  }
</script>

{#if numero_clausole_valide <= 0}
  <div class="p-4 w-full justify-center text-center">
    <span>Non ci sono clausole da firmare</span>
  </div>
{:else}
  {#each clausole_disponibili as clausola, i}
    {@const titolo = clausola.titolo ? clausola.titolo : '{Titolo mancante}'}
    {#if numero_clausole_valide > 0 && clausola.testo.trim()}
      <Espansione
        {titolo}
        sottotitolo={clausole_accettate[i] ? 'Accettata' : 'Da accettare'}
        colore_sottotitolo={clausole_accettate[i] ? 'success' : 'error'}
        attivita={!clausole_accettate[i]}
      >
        <div class="p-4 w-full justify-center text-center">
          <div class="pt-2" />
          <div class="p-4 bg-slate-300 rounded-xl text-start">{titolo}</div>
          <div class="pt-8" />
          <button
            class="btn btn-primary"
            on:click={function run() {
              lettura_in_corso[i] = true
            }}
          >
            <Icona valore={mdiEye} />
            <span class="pl-2">Leggi</span>
          </button>
          <!-- {#if utente_applicativo_puo_firmare || (!sessione.Utente.IsUtenteApplicativo && !firme64[i])}
            <button
              class="btn btn-primary"
              on:click={function run() {
                firma_in_corso[i] = true
              }}
            >
              <Icona valore={mdiSignatureFreehand} />
              <span class="pl-2">Firma</span>
            </button>
          {/if} -->
        </div>

        <Popup
          icona={mdiEye}
          titolo="Leggi"
          bind:visibile={lettura_in_corso[i]}
          on:intenzione-apertura={function run() {
            visualizza_app_bar_principale = false
            disabilita_pull_to_refresh()
          }}
          on:intenzione-chiusura={function run() {
            visualizza_app_bar_principale = true
            abilita_pull_to_refresh()
          }}
        >
          <h6>{titolo}</h6>
          <div class="pt-5" />
          <LineaOrizzontale />
          <div class="pt-5" />
          {clausola.testo}
          <div class="pt-5" />
          <!-- {#if utente_applicativo_puo_firmare || !sessione.Utente.IsUtenteApplicativo}
            <LineaOrizzontale />
            <div class="pt-5" />
            <div class="grid grid-x-center">
              <button
                class="btn btn-primary"
                on:click={function run() {
                  lettura_in_corso[i] = false
                  firma_in_corso[i] = true
                }}
              >
                <Icona valore={mdiSignatureFreehand} />
                <span class="pl-2">Firma</span>
              </button>
            </div>
          {/if} -->
          {#if utente_applicativo_puo_firmare || !sessione.Utente.IsUtenteApplicativo}
            <LineaOrizzontale />
            <div class="pt-5" />
            <div class="grid grid-x-center">
              <button
                class="btn btn-primary"
                on:click={function run() {
                  lettura_in_corso[i] = false
                  // firma_in_corso[i] = true
                  clausole_accettate[i] = true
                }}
              >
                <Icona valore={mdiSignatureFreehand} />
                <span class="pl-2">Accetto</span>
              </button>
            </div>
          {/if}
          <div class="pt-10" />
        </Popup>
      </Espansione>

      <div class="pt-2" />
    {/if}
  {:else}
    <div class="grid grid-x-center">Non ci sono clausole da firmare</div>
  {/each}
{/if}
