<style>
  :root {
    --or-left-line-color: #c1c1c1;
    --or-right-line-color: #c1c1c1;
  }
  .left {
    height: 1px;
    background-color: var(--or-left-line-color);
    align-self: center;
  }
  .Testo {
    align-self: center;
    justify-self: center;
  }
  .right {
    height: 1px;
    background-color: var(--or-right-line-color);
    align-self: center;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    column-gap: 3rem;
  }
</style>

<script>
  /**
   * @type {string}
   */
  export let testo
</script>

<div class="grid full-width">
  <div class="left m-0 p-0 ml-3" />
  <div class="Testo m-0 p-0">{testo}</div>
  <div class="right m-0 p-0 mr-3" />
</div>
