<style lang="scss">
  .wrapper {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    @media only screen and (max-width: 900px) {
      justify-content: center;
    }
    .offerta {
      display: flex;
      margin: 0.5rem;
      column-gap: 1rem;
      row-gap: 1rem;
      max-width: 400px;

      @media only screen and (max-width: 900px) {
        width: 100%;
      }

      .anteprima {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 5rem;
        width: 5rem;
        border-radius: 4px;
      }
    }
  }
</style>

<script>
  import DivisoreOrizzontaleConIntestazione from ':components/divisore-orizzontale-con-intestazione.svelte'
  import { valuta } from ':scripts/valuta'
  import { store_url_sgp1 } from ':stores/store_url_sgp1'

  /**
   * @type {string}
   */
  export let titolo
  /**
   * @type {string}
   */
  export let didascalia
  /**
   * @type {Array<import(':types').Offerta>}
   */
  export let offerte = []
</script>

{#if offerte.length > 0}
  <DivisoreOrizzontaleConIntestazione {titolo} {didascalia} />
  <div class="wrapper">
    {#each offerte as Offerta}
      {#if '1' === Offerta.featured}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div
          class="offerta bg-gray-200 rounded-xl p-4"
          on:click={function run() {
            window.open(`${$store_url_sgp1}/${Offerta.url}`)
          }}
        >
          <div
            class="anteprima grid-y-center mr-1"
            style="background-image:url('{Offerta.imageso.image_intro}');"
          />
          <div class="informazioni">
            <span>{Offerta.titolo}</span><br />
            <small>{valuta.euro({ valore: Offerta?.prezzo ?? 0 })}</small><br />
            <small>{Offerta.tipo_title}</small>
          </div>
          <!-- <div class="menu w-full relative self-end col-span-2">
            <button
              class="btn btn-ghost"
              on:click={() => window.open(`${$store_url_sgp1}/${Offerta.url}`)}
            >
              <Icona valore={mdiOpenInApp} />
              <span class="pl-2">Esplora</span>
            </button>
          </div> -->
        </div>
      {/if}
    {/each}
  </div>
{/if}
