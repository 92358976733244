<script>
  import Espansione from ':components/espansione.svelte'
  import Icona from ':components/icona.svelte'
  import Progresso from ':components/progresso.svelte'
  import axios from 'axios'
  import { store_url_facilews3 } from ':stores/store_url_facilews3'

  import { mdiFile, mdiPaperclip } from '@mdi/js'
  import { richiedi_firma_contratto } from ':scripts/richiedi_firma_contratto'

  import { createEventDispatcher } from 'svelte'
  import { navigate } from 'svelte-routing'

  /**
   * @type {import(':types').Pratica}
   */
  export let pratica
  /**
   * @type {Array<import(':types').AllegatoPratica>}
   */
  export let allegati
  /**
   * @type {import(':types').Sessione}
   */
  export let sessione
  export let otp_invio_proposta_firma = ''

  const emit = createEventDispatcher()

  let richiesta_firma_contratto = -1

  /**
   * @param {{id_allegato:string}} payload
   */
  function firma_allegato({ id_allegato }) {
    navigate(`/firma/${pratica.NumeroPratica}/${id_allegato}`)
  }

  async function account_pratica() {
    /**
     * @type {import(':types').AxiosResponse<import(':types').RispostaFacileWS3<import(':types').Account>>}
     */
    const risposta = await axios.get(
      `${$store_url_facilews3}/Api/Rest/${sessione.Utente.CodiceAgenzia}/Account/FindByPraticaID/${pratica.PraticaID}?OTP=${otp_invio_proposta_firma}&Token=${sessione.Utente.TokenFacileWS}`,
    )
    return risposta.data.data
  }
</script>

{#each allegati as allegato, indice}
  <Espansione
    icona={mdiFile}
    titolo="({allegato.DataInserimento}) {allegato.Descrizione}"
    sottotitolo={allegato.DataFirma === ''
      ? allegato.DataInserimento
      : allegato.DataFirma}
  >
    <span class="badge">{allegato.DataInserimento}</span>
    <div class="mt-2" />
    <p>{allegato.Descrizione}</p>
    <div class="pt-2" />

    {#await account_pratica()}
      <div class="grid justify-center">
        <Progresso />
      </div>
    {:then Account}
      <!--
          se l'utente di login non è applicativo, 
          oppure è l'utente a cui è intestata la pratica, 
          mostra il pulsante "Firma contratto" per la firma 
          diretta del contratto [...]
      -->
      {#if !sessione.Utente.IsUtenteApplicativo || (Account && Account.AccountLoginID === pratica.AccountLoginID)}
        <button
          class="w-full btn btn-primary"
          on:click={function run() {
            firma_allegato({ id_allegato: allegato.AllegatoID })
          }}
        >
          <Icona valore={mdiPaperclip} />
          <span class="pl-2">Firma contratto</span>
        </button>
      {:else if Account}
        <!--
            [...] altrimenti mostra il bottone "Richiedi firma contratto" che inizia 
            la procedura di firma tramite link & OTP
        -->
        <button
          disabled={richiesta_firma_contratto === 0}
          class="w-full btn btn-primary"
          on:click={async function run() {
            richiesta_firma_contratto = indice
            const FirmaRichiesta = await richiedi_firma_contratto({
              sessione,
              pratica,
              allegato_pratica: allegato,
              email_cliente: Account.Email,
            })
            if (FirmaRichiesta) {
              emit('firma-richiesta')
            }
            richiesta_firma_contratto = -1
          }}
        >
          {#if richiesta_firma_contratto === indice}
            <Progresso />
          {:else}
            <Icona valore={mdiPaperclip} />
            <span class="pl-2">Richiedi firma contratto</span>
          {/if}
        </button>
        <div class="pt-2" />
        <div class="grid justify-center">
          <div class="text-xs">a {Account.Email}</div>
        </div>
      {:else}
        <span>Account locale non trovato.</span>
      {/if}
    {/await}
  </Espansione>
  <div class="pt-2" />
{:else}
  <span class="text-center">Nessun allegato da firmare</span>
{/each}
