<script>
  import DivisoreOrizzontaleConIntestazione from ':components/divisore-orizzontale-con-intestazione.svelte'
  import FiltroSemplice from ':components/filtro-semplice.svelte'
  import ComponenteOfferta from ':components/offerta.svelte'

  import { tick } from 'svelte'
  import { Pagination } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/svelte'

  /**
   * @type {string}
   */
  export let titolo
  /**
   * @type {string}
   */
  export let didascalia
  /**
   * @type {number}
   */
  export let larghezza
  /**
   * @type {Array<import(':types').Offerta>}
   */
  export let offerte = []
  /**
   * @type {Record<string, boolean>}
   */
  export let categorie = {}
  export let categoria_selezionata = ''

  let Visibile = false
  let Filtro = true

  $: NomiCategorie = Object.keys(categorie)

  $: aggiornamento_categorie({ categorie })
  /**
   * @param {{categorie:Record<string, boolean>}} payload
   */
  async function aggiornamento_categorie({ categorie }) {
    Visibile = false
    Filtro = false
    await tick()
    Visibile = true
    Filtro = true
  }

  $: aggiornamento_categoria_selezionata({ categoria_selezionata })
  /**
   * @param {{categoria_selezionata:string}} payload
   */
  async function aggiornamento_categoria_selezionata({
    categoria_selezionata,
  }) {
    Visibile = false
    await tick()
    Visibile = true
  }
</script>

{#if offerte.length > 0}
  <DivisoreOrizzontaleConIntestazione {titolo} {didascalia} />
  <div class="pt-2" />
  {#if Filtro}
    <div class="ml-3 mr-3">
      <FiltroSemplice
        bind:selezione={categoria_selezionata}
        nomi={NomiCategorie}
      />
    </div>
  {/if}
  <div class="pt-2" />
  {#if Visibile}
    <Swiper
      modules={[Pagination]}
      pagination={{
        bulletActiveClass: 'swiper-pagination-bullet-active amber',
      }}
      spaceBetween={30}
      width={larghezza}
      slidesPerView={larghezza < 400 ? 1 : Math.floor(larghezza / 400)}
    >
      {#each offerte as Offerta}
        {#if categoria_selezionata === Offerta.tipo_title}
          <SwiperSlide>
            <ComponenteOfferta offerta={Offerta} />
          </SwiperSlide>
        {/if}
      {/each}
    </Swiper>
  {/if}
{/if}
