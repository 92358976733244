<script>
  import { mdiTicketConfirmation } from '@mdi/js'
  import { biglietti } from ':scripts/biglietti'
  import { scale } from 'svelte/transition'
  import Icona from ':components/icona.svelte'
  import Progresso from ':components/progresso.svelte'
  /**
   * @type {import(':types').Pratica}
   */
  export let pratica
  /**
   * @type {import(':types').Sessione}
   */
  export let sessione
</script>

<div class="grid relative w-full">
  {#await biglietti({ sessione, pratica })}
    <div class="p-4 grid w-full justify-center text-center" transition:scale>
      <Progresso />
    </div>
  {:then Biglietti}
    <div class="p-4 w-full" transition:scale>
      {#each Biglietti as Biglietto}
        <div class="grid" style="grid-template-columns: auto 1fr;">
          <Icona valore={mdiTicketConfirmation} />
          <p>
            <span>{Biglietto.NumeroBiglietto}</span>
            <span
              >{Biglietto.DataPartenzaBiglietto
                ? `Partenza: ${Biglietto.DataPartenzaBiglietto}`
                : ''}</span
            ><br />
            <span>{Biglietto.Passeggero}</span>
          </p>
        </div>
      {:else}
        <div
          class="p-4 grid w-full justify-center text-center"
          transition:scale
        >
          <span>Nessun biglietto trovato</span>
        </div>
      {/each}
    </div>
  {:catch errore}
    <div class="p-4 grid w-full justify-center text-center" transition:scale>
      {errore}
    </div>
  {/await}
</div>
