import { store_accesso_terze_parti_in_corso } from ':stores/store_accesso_terze_parti_in_corso'
import { store_mantieni_sessione_app } from ':stores/store_mantieni_sessione_app'
import { store_nome_utente_precedente } from ':stores/store_nome_utente_precedente'
import { store_sessione_app } from ':stores/store_sessione_app'
import { store_username } from ':stores/store_username'
import { get } from 'svelte/store'
import { store_accesso_in_corso } from ':stores/store_accesso_in_corso'
import { store_password } from ':stores/store_password'

export function esci() {
  // store_username.set('')
  const $store_username = get(store_username)
  store_accesso_in_corso.set(false)
  store_accesso_terze_parti_in_corso.set(false)
  store_mantieni_sessione_app.set(false)
  store_password.set('')
  store_sessione_app.set(false)
  // ElencoPratiche.set([])
  store_nome_utente_precedente.set($store_username)
  // navigate('/')
}
