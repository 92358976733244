import axios from 'axios'
import { dayjs } from ':scripts/dayjs'
import { uuid } from ':scripts/uuid'
import { store_otp } from ':stores/store_otp'
import { store_token_facilews } from ':stores/store_token_facilews'

import { IN_SVILUPPO, IOTNULL } from ':constants'
import { store_elenco_pratiche } from ':stores/store_elenco_pratiche'
import { store_sessione_app_scaduta } from ':stores/store_sessione_app_scaduta'
import { store_ultime_partenze } from ':stores/store_ultime_partenze'
import { store_url_facilews3 } from ':stores/store_url_facilews3'

import { store_accesso_terze_parti } from ':stores/store_accesso_terze_parti'
import { store_mantieni_sessione_app } from ':stores/store_mantieni_sessione_app'
import { store_nome_utente_precedente } from ':stores/store_nome_utente_precedente'
import { store_password } from ':stores/store_password'
import { store_username } from ':stores/store_username'

import { store_messaggio } from ':stores/store_messaggio'
import { store_url_sferanet } from ':stores/store_url_sferanet'
import { get } from 'svelte/store'

/**
 * @param {{dominio:import(':types').MetadatiDominio}} payload
 */
export async function accedi({ dominio }) {
  try {
    // ----------------------------------------------------
    // store_mantieni_sessione_app.set(true)
    // store_sessione_app_scaduta.set(false)
    // ElencoPratiche.set([])
    // UltimePartenze.set([])
    // NomeUtentePrecedente.set('mircomarri@gmail.com')

    // return {
    //   Utente: {
    //     CodiceAgenzia: 'DEMO1',
    //     store_username: 'mircomarri@gmail.com',
    //     store_password: btoa('ViaMartiriDeiLager,3'),
    //     AccessToken: '1',
    //     AccountId: '1',
    //     AccountLoginId: '1',
    //     TokenFacileWS: '1',
    //     IsUtenteApplicativo: true,
    //     VisualizzaHome: true,
    //     VisualizzaDashboard: true,
    //     VisualizzaPratiche: true,
    //     DataScadenzaPassword: dayjs('2099-01-01', 'YYYY-MM-DD').toDate(),
    //   },
    //   DataCreazione: Date.now(),
    //   Tipo: 'normale',
    // }
    // ----------------------------------------------------

    // store_accesso_in_corso.set(true)
    let $store_username = get(store_username)
    let $store_password = atob(get(store_password))
    const $store_accesso_terze_parti = get(store_accesso_terze_parti)
    const $store_messaggio = get(store_messaggio)
    const $store_url_sferanet = get(store_url_sferanet)
    const $store_url_facilews3 = get(store_url_facilews3)

    let $store_otp = get(store_otp)
    if ($store_otp) {
      try {
        /**
         * @type {import(':types').AxiosResponse<import(':types').RispostaFacileWS3<import(':types').RisultatoAccesso>>}
         */
        const risposta = await axios.get(
          `${$store_url_facilews3}/Api/Rest/OTP/FindOpzioni?otp=${$store_otp}`,
        )
        if (risposta.data.data) {
          if (dominio.Codice !== risposta.data.data.CodiceAgenzia) {
            store_messaggio.set({ testo: 'Il codice OTP non è valido.' })
            return false
          }

          // store_accesso_in_corso.set(false)
          return {
            Utente: {
              CodiceAgenzia: risposta.data.data.CodiceAgenzia,
              Username: risposta.data.data.Cliente.Email,
              Password: '',
              AccessToken: '',
              AccountId: risposta.data.data.Cliente.AccountID,
              AccountLoginId: risposta.data.data.Operatore.AccountLoginID,
              TokenFacileWS: '',
              IsUtenteApplicativo: false,
              VisualizzaHome: false,
              VisualizzaDashboard: false,
              VisualizzaPratiche: false,
              DataScadenzaPassword: new Date(Date.now() + 999999999),
            },
            DataCreazione: Date.now(),
            /**
             * @type {"normale"|"otp"}
             */
            Tipo: 'otp',
          }
        }
      } catch (e) {
        $store_otp = ''
        console.warn(e)
        /** @ts-ignore */
        if (e.response) {
          // OTP.set('')
          return false
        }
      }
    }

    let $store_token_facilews = get(store_token_facilews)
    if ($store_token_facilews) {
      try {
        /**
         * @type {{ data: import(':types').DatiJWT }}
         */
        const dati_facilews = JSON.parse(
          atob($store_token_facilews.split('.')[1]),
        )
        if (!dati_facilews) {
          $store_token_facilews = ''
        } else {
          $store_username = dati_facilews.data.userName
          $store_password = $store_token_facilews
        }
      } catch (e) {
        $store_token_facilews = ''
        console.warn(e)
      }
    }
    switch ('') {
      case $store_username.trim():
        if (!$store_messaggio || !$store_messaggio.priorita) {
          store_messaggio.set({ testo: 'Inserire Utente' })
        }
        // store_accesso_in_corso.set(false)
        return false
      case $store_password.trim():
        if (!$store_messaggio || !$store_messaggio.priorita) {
          store_messaggio.set({ testo: 'Inserire store_password' })
        }
        // store_accesso_in_corso.set(false)
        return false
    }
    const AccessKey = uuid({})
    /**
     * @type {import(':types').AxiosResponse<{
     *  Login: Array<import(':types').RispostaAccesso>
     * }>}
     */
    const risposta = await axios.post(
      `${$store_url_sferanet}/CompFacileLogin/Auth.json`,
      {
        codiceagenzia: dominio.Codice,
        user: $store_username,
        password: $store_password,
        accesskey: $store_accesso_terze_parti
          ? $store_accesso_terze_parti
          : $store_token_facilews
          ? 'facilews'
          : AccessKey,
        // `SferaNetPWA:${AccessKey}:${dayjs(new Date()).format('YYYY-MM-DD')}`,
      },
      {
        headers: {
          'x-http-method-override': 'LOGIN',
          'content-type': 'application/json',
        },
      },
    )

    // @ts-ignore
    if (IOTNULL === risposta.data) {
      store_messaggio.set({ testo: IOTNULL })
      return false
    }

    /**
     * @type {false | import(':types').RispostaAccesso}
     */
    const risultato =
      risposta &&
      risposta.data &&
      risposta.data.Login &&
      risposta.data.Login.length > 0
        ? risposta.data.Login[0]
        : false

    // store_accesso_in_corso.set(false)

    store_mantieni_sessione_app.set(false !== risultato)
    if (risultato) {
      const $store_nome_utente_precedente = get(store_nome_utente_precedente)
      if ($store_nome_utente_precedente !== $store_username) {
        store_elenco_pratiche.set([])
        store_ultime_partenze.set([])
      }

      store_nome_utente_precedente.set($store_username)

      if ('DataScadenzaPassword' in risultato) {
        if ('' === risultato.DataScadenzaPassword.trim()) {
          if (IN_SVILUPPO) {
            risultato.DataScadenzaPassword = '2099-01-01'
          } else {
            risultato.DataScadenzaPassword = '2099-01-01'
          }
        }
      } else {
        // @ts-ignore
        risultato.DataScadenzaPassword = '2099-01-01'
      }
      store_sessione_app_scaduta.set(false)

      return {
        Utente: {
          CodiceAgenzia: dominio.Codice,
          Username: $store_username,
          Password: $store_password,
          AccessToken: risultato.Accesstoken,
          AccountId: risultato.Accountid,
          AccountLoginId: risultato.Accountloginid,
          TokenFacileWS: risultato.TockenUtente,
          IsUtenteApplicativo: risultato.IsUtenteApplicativo === 1,
          // VisualizzaHome: risultato.VisualizzaHome === 1,
          // VisualizzaDashboard: risultato.VisualizzaDashboard === 1,
          // VisualizzaPratiche: risultato.VisualizzaPratiche === 1,
          VisualizzaHome: IN_SVILUPPO ? true : risultato.VisualizzaHome === 1,
          VisualizzaDashboard: IN_SVILUPPO
            ? true
            : risultato.VisualizzaDashboard === 1,
          VisualizzaPratiche: IN_SVILUPPO
            ? true
            : risultato.VisualizzaPratiche === 1,
          DataScadenzaPassword: dayjs(
            risultato.DataScadenzaPassword,
            'YYYY-MM-DD',
          ).toDate(),
        },
        DataCreazione: Date.now(),
        /**
         * @type {"normale"|"otp"}
         */
        Tipo: 'normale',
      }
    }
  } catch (errore) {
    store_messaggio.set({ testo: `${errore}` })
    console.warn(errore)
    return false
  }
  const $store_sessione_app_scaduta = get(store_sessione_app_scaduta)
  if ($store_sessione_app_scaduta) {
    store_messaggio.set({ testo: 'Sessione scaduta, rieseguil il login.' })
  } else {
    store_messaggio.set({ testo: 'Dati non corretti.' })
  }
  return false
}
