<script>
  import { mdiFilter } from '@mdi/js'
  import Icona from ':components/icona.svelte'
  /**
   * @type {Array<string>}
   */
  export let nomi = []
  export let selezione = nomi[0] ?? ''
</script>

<div class="w-full grid justify-center">
  <div class="dropdown w-full">
    <button
      tabindex="0"
      class="
        w-full
        btn
        bg-primary
        text-primary-content
        border-0
        hover:bg-primary
        focus:bg-primary
        active:bg-primary
        focus:text-primary-content
        active:text-primary-content
        "
    >
      <Icona valore={mdiFilter} />
      <span>{selezione ? selezione : 'Filtro'}</span>
    </button>
    <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
    <ul
      tabindex="0"
      class="dropdown-content menu p-2 shadow bg-primary rounded-box"
    >
      <li>
        {#each nomi as Nome}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <div
            class="flex flex-wrap"
            on:click={function run() {
              selezione = Nome
            }}
          >
            <input
              type="checkbox"
              class="toggle toggle-primary text-primary-content"
              checked={selezione === Nome}
            />
            <button class="text-primary-content">{Nome}</button>
          </div>
        {/each}
      </li>
    </ul>
  </div>
</div>
