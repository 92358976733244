<script>
  /**
   * @type {string}
   */
  export let valore
  export let dimensione = '1.6rem'
  export let colore_interno = 'currentColor'
  export let colore_esterno = 'none'
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  style="width:{dimensione};height:{dimensione}"
  fill={colore_interno}
  viewBox="0 0 24 24"
  stroke={colore_esterno}
  ><path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1"
    d={valore}
  /></svg
>
