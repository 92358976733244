import { prompt_installazione } from ':scripts/prompt_installazione'

export function installa() {
  const conf = prompt_installazione()
  try {
    // hide our user interface that shows our A2HS button
    // Show the prompt
    if (conf.PromptInstallazione) {
      void conf.PromptInstallazione.prompt()
      // Wait for the user to respond to the prompt
      /** @ts-ignore */
      void conf.PromptInstallazione.userChoice.then(function run(choiceResult) {
        if (choiceResult.outcome === 'accepted') {
          console.log("L'utente ha accettato il prompt.")
        } else {
          console.log("L'utente ha dismesso il prompt.")
        }
        conf.PromptInstallazione = false
      })
    }
  } catch (e) {
    console.warn(e)
  }
}
