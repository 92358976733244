<script>
  import { mdiNewspaper } from '@mdi/js'
  import { trova_service_worker } from ':scripts/trova_service_worker'
  import { store_registrazione_service_worker } from ':stores/store_registrazione_service_worker'
  import Icona from ':components/icona.svelte'
  let aggiornato = false
</script>

{#if aggiornato}
  <button
    class="p-8 content-center justify-start w-full btn btn-ghost rounded-none"
    style="grid-template-columns: 1fr auto;"
    on:click={function run() {
      /** @ts-ignore*/
      window.location.reload(true)
    }}
  >
    <Icona valore={mdiNewspaper} />
    <span class="pl-8 text-start">Ricarica app</span>
  </button>
{:else if !$store_registrazione_service_worker}
  <button
    class="p-8 content-center justify-start w-full btn btn-ghost rounded-none"
    on:click={function run() {
      if (window.navigator && window.navigator.serviceWorker)
        navigator.serviceWorker
          .getRegistrations()
          .then(async function run(registrazioni) {
            // @ts-ignore
            await trova_service_worker({ registrazioni })
            aggiornato = true
          })
    }}
  >
    <Icona valore={mdiNewspaper} />
    <span class="pl-8 text-start"> Aggiungi service worker </span>
  </button>
{:else}
  <button
    class="p-8 content-center justify-start w-full btn btn-ghost rounded-none"
    on:click={function run() {
      if (window.caches)
        window.caches.keys().then(function run(lista_chiavi) {
          console.log({ lista_chiavi })
          lista_chiavi.map(function pass(chiave) {
            window.caches.delete(chiave)
          })

          if (window.navigator && window.navigator.serviceWorker)
            window.navigator.serviceWorker
              .getRegistrations()
              .then(function run(registrazioni) {
                for (let registrazione of registrazioni) {
                  registrazione.unregister()
                }
                store_registrazione_service_worker.set(false)
                aggiornato = true
              })
        })
    }}
  >
    <Icona valore={mdiNewspaper} />
    <span class="pl-8 text-start"> Rimuovi service worker e cache </span>
  </button>
{/if}
