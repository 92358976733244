<script>
  import Accesso from ':components/accesso.svelte'
  import AppBarPrincipale from ':components/app-bar-principale.svelte'
  import CampoDiTesto from ':components/campo-di-testo.svelte'
  import ComponenteElencoPratiche from ':components/elenco-pratiche.svelte'
  import Espansione from ':components/espansione.svelte'
  import ComponenteFiltro from ':components/filtro-elenco-pratiche.svelte'
  import Icona from ':components/icona.svelte'
  import Progresso from ':components/progresso.svelte'
  import Scrollabile from ':components/scrollabile.svelte'
  import { is_number } from ':scripts/is_number'
  import { mdiFilter, mdiLightningBolt, mdiMagnify, mdiMore } from '@mdi/js'
  import { dominio } from ':scripts/dominio'
  import { pratiche } from ':scripts/pratiche'
  import { store_elenco_pratiche } from ':stores/store_elenco_pratiche'
  import { store_input_piu_recente_ricerca_pratiche } from ':stores/store_input_piu_recente_ricerca_pratiche'
  import { store_offset_ricerca_pratiche } from ':stores/store_offset_ricerca_pratiche'
  import { store_sessione_app } from ':stores/store_sessione_app'
  import { navigate } from 'svelte-routing'
  import { fade } from 'svelte/transition'
  import { store_messaggio } from ':stores/store_messaggio'

  const LUNGHEZZA_FETCH = 10

  let numero_filtri_attivi = 0
  let ricerca_in_corso = false
  let ricerca_avanzata_visibile = false
  let testo_ricerca = ''
  let visualizza_carica_di_piu =
    $store_elenco_pratiche.length >= LUNGHEZZA_FETCH

  /**
   *
   * @param {{
   *  sessione: import(':types').Sessione,
   *  filtro: import(':types').FiltroElencoPratiche,
   *  aggiungi_ad_elenco_pratiche?: boolean,
   * }} payload
   */
  export async function ricerca({
    sessione,
    filtro,
    aggiungi_ad_elenco_pratiche = false,
  }) {
    if (ricerca_in_corso) return

    ricerca_in_corso = true
    visualizza_carica_di_piu = false

    if (aggiungi_ad_elenco_pratiche) {
      $store_offset_ricerca_pratiche = $store_elenco_pratiche.length
      const [pratiche_locali, errore_pratiche] = await pratiche({
        sessione,
        filtro,
        numero_righe_totali: $store_elenco_pratiche.length,
        offset: $store_offset_ricerca_pratiche,
        limite_righe: LUNGHEZZA_FETCH,
      })

      if (errore_pratiche) {
        ricerca_in_corso = false
        visualizza_carica_di_piu = false
        $store_messaggio = { testo: errore_pratiche.message }
        return
      }

      if (pratiche_locali.length >= LUNGHEZZA_FETCH) {
        visualizza_carica_di_piu = true
      } else {
        visualizza_carica_di_piu = false
      }
      $store_elenco_pratiche = [...$store_elenco_pratiche, ...pratiche_locali]
    } else {
      const [pratiche_locali, errore_pratiche] = await pratiche({
        sessione,
        filtro,
        numero_righe_totali: $store_elenco_pratiche.length,
        offset: $store_offset_ricerca_pratiche,
        limite_righe: LUNGHEZZA_FETCH,
      })

      if (errore_pratiche) {
        ricerca_in_corso = false
        visualizza_carica_di_piu = false
        $store_messaggio = { testo: errore_pratiche.message }
        return
      }

      $store_elenco_pratiche = pratiche_locali

      if ($store_elenco_pratiche.length >= LUNGHEZZA_FETCH) {
        visualizza_carica_di_piu = true
      } else {
        visualizza_carica_di_piu = false
      }
    }
    ricerca_in_corso = false
  }

  $: if ($store_sessione_app)
    aggiornamento_testo_ricerca({
      sessione: $store_sessione_app,
      testo_ricerca,
    })
  /**
   * @param {{sessione:import(':types').Sessione,testo_ricerca:string}} payload
   */
  async function aggiornamento_testo_ricerca({ testo_ricerca }) {
    if (ricerca_in_corso) return

    if (!is_number({ valore: testo_ricerca })) {
      $store_input_piu_recente_ricerca_pratiche = {
        DataVendita: [false, false],
        DataPartenza: [false, false],
        DataRientro: [false, false],
        DataCreazione: [false, false],
        NumeroAllegati: [0, 99999],
        NumeroPratica: '',
        Destinazione: '',
        Descrizione: testo_ricerca,
        CognomeNomeCliente: testo_ricerca,
      }
    } else {
      $store_input_piu_recente_ricerca_pratiche = {
        DataVendita: [false, false],
        DataPartenza: [false, false],
        DataRientro: [false, false],
        DataCreazione: [false, false],
        NumeroAllegati: [0, 99999],
        NumeroPratica: testo_ricerca,
        Destinazione: '',
        Descrizione: '',
        CognomeNomeCliente: '',
      }
    }
    // Data vendita
    if ('' !== data_vendita[0])
      $store_input_piu_recente_ricerca_pratiche.DataVendita[0] = new Date(
        data_vendita[0],
      )
    if ('' !== data_vendita[1])
      $store_input_piu_recente_ricerca_pratiche.DataVendita[1] = new Date(
        data_vendita[1],
      )

    // Data partenza
    if ('' !== data_partenza[0])
      $store_input_piu_recente_ricerca_pratiche.DataPartenza[0] = new Date(
        data_partenza[0],
      )
    if ('' !== data_partenza[1])
      $store_input_piu_recente_ricerca_pratiche.DataPartenza[1] = new Date(
        data_partenza[1],
      )

    // Data rientro
    if ('' !== data_rientro[0])
      $store_input_piu_recente_ricerca_pratiche.DataRientro[0] = new Date(
        data_rientro[0],
      )
    if ('' !== data_rientro[1])
      $store_input_piu_recente_ricerca_pratiche.DataRientro[1] = new Date(
        data_rientro[1],
      )

    // Data creazione
    if ('' !== data_creazione[0])
      $store_input_piu_recente_ricerca_pratiche.DataCreazione[0] = new Date(
        data_creazione[0],
      )
    if ('' !== data_creazione[1])
      $store_input_piu_recente_ricerca_pratiche.DataCreazione[1] = new Date(
        data_creazione[1],
      )

    //Numero allegati
    /**
     * @type {[number,number]}
     */
    // @ts-ignore
    $store_input_piu_recente_ricerca_pratiche.NumeroAllegati = numero_allegati

    //Destinazione
    $store_input_piu_recente_ricerca_pratiche.Destinazione = destinazione

    //Descrizione
    $store_input_piu_recente_ricerca_pratiche.Descrizione = descrizione
  }

  let data_vendita = ['', '']
  let data_partenza = ['', '']
  let data_rientro = ['', '']
  let data_creazione = ['', '']
  let numero_allegati = [0, 100]
  let destinazione = ''
  let descrizione = ''

  let espansione_attiva = false
  let altezza_ombra = 0
  $: console.log({ altezza_ombra })
</script>

{#await dominio()}
  <!-- <div class="fixed w-full h-full grid justify-center content-center"> -->
  <Progresso globale />
  <!-- </div> -->
{:then dominio}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <Accesso {dominio}>
    <svelte:fragment let:sessione>
      <AppBarPrincipale
        titolo="{sessione.Utente.CodiceAgenzia.toLocaleUpperCase()} - {sessione
          .Utente.Username}"
      >
        {#if sessione.Utente.VisualizzaPratiche}
          <div class="absolute left-0 right-0 top-14">
            <Espansione
              titolo="Ricerca pratiche"
              icona={mdiMagnify}
              icona_secondaria={mdiFilter}
              testo_icona_secondaria="Filtri ({numero_filtri_attivi})"
              azzera_arrotondamento_in_alto={espansione_attiva}
              bind:attivita={espansione_attiva}
            >
              <form
                on:submit|preventDefault={async function run() {
                  $store_offset_ricerca_pratiche = 0
                  aggiornamento_testo_ricerca({ sessione, testo_ricerca })
                  await ricerca({
                    sessione,
                    filtro: $store_input_piu_recente_ricerca_pratiche,
                  })
                  espansione_attiva = false
                }}
              >
                <CampoDiTesto
                  focalizzato={espansione_attiva ? Date.now() : false}
                  indizio="Numero pratica o cliente"
                  bind:valore={testo_ricerca}
                />
                <div class="pt-2" />
                <div class="w-full overflow-y-auto">
                  {#if ricerca_avanzata_visibile && altezza_ombra > 400}
                    <div class="w-full max-h-64 overflow-y-auto">
                      <!-- #### Filtro Avanzati ### -->
                      <ComponenteFiltro
                        bind:data_vendita
                        bind:data_partenza
                        bind:data_rientro
                        bind:data_creazione
                        bind:numero_allegati
                        bind:destinazione
                        bind:descrizione
                        on:numero-filtri-attivi={function run(e) {
                          numero_filtri_attivi = e.detail
                        }}
                      />
                      <!-- #### Filtro Avanzati ### -->
                    </div>
                  {:else}
                    <span />
                  {/if}
                  {#if altezza_ombra > 400}
                    <div class="w-full grid justify-center">
                      <div class="pt-2" />
                      <span
                        class="btn btn-ghost"
                        on:click={function run() {
                          if (ricerca_avanzata_visibile) {
                            ricerca_avanzata_visibile = false
                          } else {
                            ricerca_avanzata_visibile = true
                          }
                        }}
                      >
                        <span class="pr-2"
                          >{ricerca_avanzata_visibile
                            ? 'Nascondi filtri'
                            : 'Mostra filtri'}</span
                        >
                        <Icona valore={mdiMore} />
                      </span>
                    </div>
                  {/if}
                  <div class="grid justify-center">
                    <div class="pt-4" />
                    <button
                      type="submit"
                      disabled={ricerca_in_corso}
                      class="btn btn-ghost"
                    >
                      {#if ricerca_in_corso}
                        <Progresso />
                      {:else}
                        <span>Cerca</span>
                      {/if}
                    </button>
                  </div>
                </div>
              </form>
            </Espansione>
          </div>
        {/if}
      </AppBarPrincipale>

      {#if espansione_attiva}
        <div
          class="fixed left-0 right-0 top-0 bottom-0 z-2"
          style="background:rgba(0,0,0,0.7)"
          bind:offsetHeight={altezza_ombra}
          on:click={function run() {
            espansione_attiva = false
          }}
          transition:fade={{}}
        />
      {/if}

      <Scrollabile
        altezza_leva_ricarica={150}
        on:click={function run() {
          espansione_attiva = false
        }}
      >
        {#if sessione.Utente.VisualizzaPratiche}
          <div class="pt-14" />
          <div class="p-2">
            <ComponenteElencoPratiche
              bind:elenco_pratiche={$store_elenco_pratiche}
              {sessione}
              on:apertura-pratica={function run(e) {
                navigate(`/pratica/${e.detail}`)
              }}
            />
          </div>
          {#if visualizza_carica_di_piu || ricerca_in_corso}
            <div class="grid justify-center mb-10">
              <button
                disabled={ricerca_in_corso}
                class="btn btn-ghost"
                on:click={function run() {
                  ricerca({
                    sessione,
                    filtro: $store_input_piu_recente_ricerca_pratiche,
                    aggiungi_ad_elenco_pratiche: true,
                  })
                }}
              >
                {#if ricerca_in_corso}
                  <Progresso />
                {:else}
                  <b>Carica di più</b><br />
                  <Icona valore={mdiLightningBolt} />
                {/if}
              </button>
            </div>
          {/if}

          <br />
          <br />
          <br />
          <br />
        {:else}
          <div class="grid grid-x-center grid-y-center">Pagina non trovata</div>
        {/if}
      </Scrollabile>
    </svelte:fragment>
  </Accesso>
{:catch errore}
  <div
    class="p-4 grid fixed h-full w-full justify-center content-center text-center"
  >
    {errore}
  </div>
{/await}
