<style>
  .ImmagineOperatore {
    width: 78px;
    height: 78px;
    display: inline-flex;
    align-self: flex-start;
    margin-top: 13px;
    margin-bottom: 13px;
    margin-right: 32px;
    background-size: cover;
    border-radius: 50%;
  }
</style>

<script>
  import LineaOrizzontale from ':components/linea-orizzontale.svelte'
  import { data } from ':scripts/data'
  import { valuta } from ':scripts/valuta'
  import { immagine_utente_base64 } from ':scripts/immagine_utente_base64'
  import {
    mdiAccountCircle,
    mdiCalendar,
    mdiChartBoxOutline,
    mdiCheckCircle,
    mdiCurrencyEur,
    mdiGlobeModel,
    mdiLeaf,
  } from '@mdi/js'

  import Icona from ':components/icona.svelte'
  import { scale } from 'svelte/transition'

  /**
   * @type {import(':types').Sessione}
   */
  export let sessione
  /**
   * @type {import(':types').Pratica}
   */
  export let pratica
  /**
   * @type {import(':types').DettaglioPratica}
   */
  export let dettaglio

  /**
   * Take the difference between the dates and divide by milliseconds per day.
   * Round to nearest whole number to deal with DST.
   * @param {{first:Date,second:Date}} payload
   */
  function datediff({ first, second }) {
    return Math.round(
      (second.getTime() - first.getTime()) / (1000 * 60 * 60 * 24),
    )
  }

  $: data_partenza = pratica.DataPartenzaCliente
    ? new Date(pratica.DataPartenzaCliente)
    : false
  $: data_rientro = pratica.DataRientroCliente
    ? new Date(pratica.DataRientroCliente)
    : false

  $: giorni =
    /** @ts-ignore */
    data_partenza && data_rientro
      ? // @ts-ignore
        Math.abs(datediff({ first: data_partenza, second: data_rientro }))
      : -1
  $: Notti = giorni === -1 ? -1 : giorni > 0 ? giorni - 1 : 0

  /**
   *
   * @param {{dettaglio:import(':types').DettaglioPratica}} payload
   */
  function colore_saldo({ dettaglio }) {
    if (
      dettaglio.SaldoCliente !== 0 &&
      dettaglio.SaldoCliente === dettaglio.TotaleCliente
    )
      return 'red'
    if (
      dettaglio.SaldoCliente !== 0 &&
      dettaglio.SaldoCliente !== dettaglio.TotaleCliente
    )
      return 'orange'
    if (dettaglio.SaldoCliente === 0) return 'green'

    return 'black'
  }
</script>

<br />
<div class="grid grid-cols-3 pl-3 pr-3 pb-3 justify-center content-center">
  <span class="text-gray-500">
    <div class="grid grid-cols-2-auto">
      <Icona valore={mdiGlobeModel} />
      <small style="padding-left:5px;"
        >{pratica.DescrizioneDestinazioneSintetica ||
          'Destinazione non disponibile'}</small
      >
    </div>
  </span>
  <span class="text-gray-500">
    <div class="grid grid-cols-2-auto">
      <Icona valore={mdiCalendar} />
      {#if giorni === -1 && Notti === -1}
        <small style="padding-left:5px;">Durata sconosciuta</small>
      {:else}
        <small style="padding-left:5px;">{giorni + 1}g / {Notti + 1}n</small>
      {/if}
    </div>
  </span>
  <span class="text-gray-500">
    <div class="grid grid-cols-2-auto">
      <Icona valore={mdiCheckCircle} />
      <small style="padding-left:5px;">Confermata</small>
    </div>
  </span>
</div>

<LineaOrizzontale />
<div
  class="grid gap-12 content-center p-4"
  style="grid-template-columns: auto 1fr;"
>
  {#await immagine_utente_base64( { sessione, account_login_id: dettaglio.AccountLoginID }, )}
    <div class="grid">
      <Icona dimensione="80px" valore={mdiAccountCircle} />
    </div>
  {:then ImmagineOperatore}
    {#if !ImmagineOperatore}
      <div class="grid">
        <Icona dimensione="80px" valore={mdiAccountCircle} />
      </div>
    {:else}
      <div
        transition:scale
        class="ImmagineOperatore"
        style="background-image: url('data:image/png;base64,{ImmagineOperatore}')"
      />
    {/if}
  {:catch errore}
    <div class="grid grid-x-center grid-y-center full-height full-width">
      {errore}
    </div>
  {/await}

  <div class="w-full h-full relative grid justify-start content-center">
    <b>Operatore {pratica.Utente || 'Utente non trovato'}</b>
    <div class="pt-2" />
    <small>
      Partenza il {pratica.DataPartenzaCliente
        ? data.italiana({ valore: pratica.DataPartenzaCliente })
        : 'Data partenza non disponibile'}
    </small>
    <div class="pt-2" />
    <small>
      Rientro il {pratica.DataRientroCliente
        ? data.italiana({ valore: pratica.DataRientroCliente })
        : 'Data rientro non disponibile'}
    </small>
  </div>
</div>

<LineaOrizzontale />

<div
  class="grid gap-12 content-center p-4"
  style="grid-template-columns: auto 1fr;"
>
  <div class="grid">
    <Icona dimensione="80px" valore={mdiAccountCircle} />
  </div>

  <div class="w-full h-full relative grid justify-start content-center">
    <b>
      <!---->
      {pratica.NomeCliente}
      {pratica.CognomeCliente || 'Cognome non trovato'}
      <!---->
    </b>
    <div class="pt-2" />
    <small>{pratica.Descrizione || 'Descrizione non trovata'}</small>
    <div class="pt-2" />
  </div>
</div>

<div class="pb-1" style="text-align: center">
  <div class="badge border-0 p-4 bg-blue-400 text-primary-content">
    <Icona valore={mdiCurrencyEur} />&nbsp;
    <span>
      Totale {valuta.euro({ valore: dettaglio.TotaleCliente }) ||
        '( Totale non disponibile )'}
    </span>
  </div>
  <div class="badge badge-warning p-4 bg-yellow-500 text-primary-content">
    <Icona valore={mdiLeaf} />&nbsp;
    <span>
      Saldo {valuta.euro({ valore: dettaglio.SaldoCliente }) ||
        '( Saldo cliente non disponibile )'}
    </span>
  </div>

  {#if sessione.Utente.IsUtenteApplicativo}
    <div
      class="badge badge-error p-4 {parseInt(dettaglio.Perc_redd) >= 0
        ? 'bg-green-500'
        : 'bg-red-500'} text-primary-content"
    >
      <Icona valore={mdiChartBoxOutline} />&nbsp;
      <span>
        {valuta.euro({ valore: dettaglio.Valore_redd })} (
        <b>{dettaglio.Perc_redd}</b> % )
      </span>
    </div>
  {/if}
</div>
