<script>
  import AccessoNonRichiesto from ':components/accesso-non-richiesto.svelte'
  import AppBarVisitatore from ':components/app-bar-visitatore.svelte'
  import CampoDiTesto from ':components/campo-di-testo.svelte'
  import Icona from ':components/icona.svelte'
  import Progresso from ':components/progresso.svelte'
  import Scrollabile from ':components/scrollabile.svelte'
  import { mdiEmail, mdiLinkBox, mdiLock } from '@mdi/js'
  import { dominio } from ':scripts/dominio'
  import { richeidi_email_recupero_password } from ':scripts/richeidi_email_recupero_password'
  import { store_messaggio } from ':stores/store_messaggio'

  import { onDestroy, onMount } from 'svelte'
  import { navigate } from 'svelte-routing'
  import { fly } from 'svelte/transition'

  let email = ''
  let link_inviato = false
  let invio_in_corso = false
  let caricato = false
  let tempo_rimasto_invia_di_nuovo = 30 * 1000

  onMount(function run() {
    caricato = true
  })
  onDestroy(function run() {
    caricato = false
  })

  const regole_email = [
    /**
     * @param {{valore:string}} payload
     */
    function pass({ valore }) {
      return !!valore || 'Richiesto'
    },
    /**
     * @param {{valore:string}} payload
     */
    function pass({ valore }) {
      return valore.length <= 256 || 'Massimo 256 caratteri'
    },
    /**
     * @param {{valore:string}} payload
     */
    function pass({ valore }) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(valore) || 'Indirizzo e-mail non valido'
    },
  ]

  /**
   * @param {{tempo_rimasto:number}} payload
   */
  function sorveglia_invio_di_nuovo({ tempo_rimasto }) {
    tempo_rimasto -= 1000
    tempo_rimasto_invia_di_nuovo = tempo_rimasto
    if (tempo_rimasto <= 0 || !caricato) return
    setTimeout(function run() {
      sorveglia_invio_di_nuovo({ tempo_rimasto })
    }, 1000)
  }

  /**
   *
   * @param {{dominio:import(':types').MetadatiDominio}} payload
   */
  async function invia({ dominio }) {
    invio_in_corso = true
    const messaggio_id = await richeidi_email_recupero_password({
      dominio,
      email,
    })
    link_inviato = messaggio_id !== false
    sorveglia_invio_di_nuovo({ tempo_rimasto: 30 * 1000 })
    invio_in_corso = false
    return messaggio_id
  }
</script>

{#await dominio()}
  <Progresso globale />
{:then dominio}
  <AccessoNonRichiesto>
    <svelte:fragment>
      <AppBarVisitatore titolo="Richiesta recupero password" />
      <Scrollabile>
        <!-- <DivisoreOrizzontaleConIntestazione
          titolo="Imposta Password"
          didascalia="Imposta la tua nuova password"
        />
        <br /> -->
        <div class="pt-6" />
        <div class="grid w-full">
          <div class="w-96 justify-self-center text-center">
            {#if link_inviato}
              <div in:fly={{ y: 100 }}>
                <h3>LINK INVIATO</h3>
                <div class="pt-10" />
                <div>
                  Abbiamo a cuore la tua privacy! Non possiamo dirti se la mail
                  che ci hai fornito è nel nostro database, ma se così riceverai
                  a breve un store_messaggio. Se non lo ricevi in pochi minuti,
                  puoi sempre riprovare.
                  <div class="pt-1" />
                  Non dimenticarti di controllare nella cartella spam!
                  <div class="pt-1" />
                </div>
                <div class="pt-10" />
                <button
                  class="btn btn-primary"
                  on:click={function run() {
                    navigate('/')
                  }}
                >
                  <span>Torna alla pagina di Login</span>
                  <Icona valore={mdiLock} />
                </button>
                <div class="pt-3" />
                {#if invio_in_corso}
                  <div class="grid">
                    <div class="justify-self-center">
                      <Progresso />
                    </div>
                  </div>
                {:else}
                  <button
                    disabled={tempo_rimasto_invia_di_nuovo > 0}
                    class="btn btn-primary"
                    on:click={async function run() {
                      if (await invia({ dominio })) {
                        $store_messaggio = {
                          testo: 'Email inviata nuovamente.',
                        }
                      }
                    }}
                  >
                    {#if tempo_rimasto_invia_di_nuovo > 0}
                      <span>({tempo_rimasto_invia_di_nuovo / 1000})</span>&nbsp;
                    {/if}
                    <span class="pr-2">Invia link di nuovo</span>
                    <Icona valore={mdiLinkBox} />
                  </button>
                {/if}
              </div>
            {:else}
              <div in:fly={{ y: 100 }}>
                Inserisci l'indirizzo email che hai utilizzato per la
                registrazione così potremo inviarti il link per impostare una
                nuova password.<br />
                <div class="pt-2" />
                <CampoDiTesto
                  indizio="Email SferaNet"
                  icona={mdiEmail}
                  bind:valore={email}
                />
                <div class="pt-2" />
                {#if invio_in_corso}
                  <div class="grid grid-x-center">
                    <Progresso />
                  </div>
                {:else}
                  <button
                    disabled={regole_email.filter(function pass(regola) {
                      return true === regola({ valore: email })
                    }).length !== regole_email.length}
                    class="btn btn-primary"
                    on:click={function run() {
                      invia({ dominio })
                    }}
                  >
                    <span class="pr-2">Inviami il link</span>
                    <Icona valore={mdiLinkBox} />
                  </button>
                {/if}
              </div>
            {/if}
          </div>
        </div>
      </Scrollabile>
    </svelte:fragment>
  </AccessoNonRichiesto>
{:catch errore}
  <div
    class="p-4 grid fixed h-full w-full justify-center content-center text-center"
  >
    {errore}
  </div>
{/await}
