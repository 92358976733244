<script>
  import { scale } from 'svelte/transition'

  export let attiva = false
  export let testo = ''
  export let piatta = false
  export let disabilitata = false
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="cursor-pointer {piatta ? '' : 'bg-base-200'} p-4 rounded-xl"
  transition:scale={{ duration: 200 }}
  on:click={function run() {
    attiva = !attiva
  }}
>
  <div
    class="w-full grid content-start justify-start"
    style="grid-template-columns: auto 1fr;"
  >
    <div class="grid w-full h-full content-start justify-start">
      <input
        disabled={disabilitata}
        type="checkbox"
        bind:checked={attiva}
        on:change
        class="checkbox checkbox-accent bg-base-300 pointer-events-none"
      />
    </div>
    <div class="pl-2 w-full label-text text-start">
      <div class="pt-0.5" />
      <slot>{testo}</slot>
    </div>
  </div>
</div>
