import axios from 'axios'
import { get } from 'svelte/store'
import { store_messaggio } from ':stores/store_messaggio'
import { IOTNULL } from ':constants'
import { store_url_sferanet } from ':stores/store_url_sferanet'

/**
 *
 * @param {{
 *  sessione: import(':types').Sessione,
 *  pratica: import(':types').Pratica,
 * }} payload
 * @returns
 */
export async function servizi({ sessione, pratica }) {
  try {
    const $store_url_sferanet = get(store_url_sferanet)
    /**
     * @type {import(':types').AxiosResponse<Array<import(':types').ServizioPratica>>}
     */
    const response = await axios.post(
      `${$store_url_sferanet}/CompFacileLogin/ServiziPraticaAPP.json`,
      JSON.stringify({
        PraticaID: pratica.PraticaID,
      }),
      {
        headers: {
          'codiceagenzia': sessione.Utente.CodiceAgenzia,
          'accesstoken': sessione.Utente.AccessToken,
          'x-http-method-override': 'GetServiziPratica',
          'content-type': 'application/json',
        },
      },
    )

    // @ts-ignore
    if (IOTNULL === response.data) {
      store_messaggio.set({ testo: IOTNULL })
      return []
    }

    return (
      response.data.map(function pass(item) {
        item.ImmagineTipoServizio = '/images/srv-hotl.png'
        return item
      }) || []
    )
  } catch (e) {
    console.warn(e)
    return []
  }
}
