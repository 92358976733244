/**
 *
 * @param {{sessione:import(':types').Sessione,allegato:import(':types').AllegatoPratica}} payload
 */
export function apri_allegato({ sessione, allegato }) {
  window.open(
    allegato.LinkAllegato.replace(
      '{tokenUtente}',
      sessione.Utente.TokenFacileWS,
    ),
  )
}
