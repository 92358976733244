<script>
  import { mdiLock, mdiSend } from '@mdi/js'
  import { imposta_password } from ':scripts/imposta_password'

  import { store_messaggio } from ':stores/store_messaggio'
  import { scale } from 'svelte/transition'
  import CampoDiTesto from ':components/campo-di-testo.svelte'
  import Icona from ':components/icona.svelte'
  import Progresso from ':components/progresso.svelte'
  import { createEventDispatcher } from 'svelte'
  import { tick } from 'svelte'

  /**
   * @type {import(':types').MetadatiDominio}
   */
  export let dominio
  /**
   * @type {string}
   */
  export let access_token
  /**
   * @type {string}
   */
  export let account_login_id
  export let access_token_scaduto = false

  const emit = createEventDispatcher()

  let invio_in_corso = false

  let password = ''
  let password2 = ''

  /** @ts-ignore */
  const regole_password_1 = [
    /**
     * @param {{valore: string}} valore
     */
    function run({ valore }) {
      return !!valore || 'La password non può essere vuota'
    },
  ]
  /** @ts-ignore */
  const regole_password_2 = [
    /**
     * @param {{valore: string}} valore
     */
    function run({ valore }) {
      return valore === password || 'Le due password devono coincidere'
    },
  ]

  /**
   *
   * @param {{dominio:import(':types').MetadatiDominio}} payload
   */
  async function invia({ dominio }) {
    invio_in_corso = true
    const { ok, access_token_valido } = await imposta_password({
      dominio,
      access_token: access_token,
      account_login_id: account_login_id,
      password,
    })
    access_token_scaduto = !access_token_valido
    invio_in_corso = false

    await tick()

    if (ok && access_token_valido) {
      $store_messaggio = { testo: 'Password cambiata.' }
      emit('password-cambiata')
    }
  }
</script>

<CampoDiTesto
  icona={mdiLock}
  indizio="Nuova password"
  bind:valore={password}
  password
/>
<div class="pt-2" />
<CampoDiTesto
  icona={mdiLock}
  indizio="Nuova password"
  bind:valore={password2}
  password
/>
<div class="pt-8" />

{#if invio_in_corso}
  <div
    class="p-4 grid w-full h-full justify-center content-center text-center"
    transition:scale
  >
    <Progresso />
  </div>
{:else}
  <button
    class="btn btn-primary w-full rounded-full"
    disabled={regole_password_1.filter(function pass(regola) {
      return true === regola({ valore: password })
    }).length !== regole_password_1.length ||
      regole_password_2.filter(function pass(regola) {
        return true === regola({ valore: password2 })
      }).length !== regole_password_2.length}
    on:click={function run() {
      invia({ dominio })
    }}
  >
    <span class="pr-2">Invia</span>
    <Icona valore={mdiSend} />
  </button>
{/if}
