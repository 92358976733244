import axios from 'axios'
import { store_messaggio } from ':stores/store_messaggio'
import { store_url_sferanet } from ':stores/store_url_sferanet'

let $store_url_sferanet = ''
store_url_sferanet.subscribe(function watch(valore) {
  $store_url_sferanet = valore
})

/**
 *
 * @param {{
 *  dominio: import(':types').MetadatiDominio,
 *  cliente_id: string,
 * }} payload
 * @returns
 */
async function crea_account_login({ dominio, cliente_id }) {
  /**
   * @type {import(':types').AxiosResponse<import(':types').RispostaSferaNet<any>>}
   */
  const risposta_creazione_login = await axios.post(
    `${$store_url_sferanet}/CompFacileLogin/CRMLeadService.json`,
    JSON.stringify({
      ClienteID: cliente_id,
    }),
    {
      headers: {
        'codiceagenzia': dominio.Codice,
        'x-http-method-override': 'CreaAccountLogin',
        'Content-Type': 'application/json',
      },
    },
  )
  if (risposta_creazione_login.status >= 400) {
    store_messaggio.set({
      testo: `Non è stato possibile registrarsi (${risposta_creazione_login.status}).`,
    })
    return false
  }

  if (risposta_creazione_login.data.code == 302) {
    store_messaggio.set({
      testo: risposta_creazione_login.data.message,
      durata: 10000,
    })
    return false
  }

  if (202 === risposta_creazione_login.data.code) {
    store_messaggio.set({
      testo: `la creazione del login è fallita`,
    })
    return false
  }
  return true
}

/**
 *
 * @param {{
 *  dominio: import(':types').MetadatiDominio,
 *  email: string,
 *  cognome: string,
 *  nome: string,
 *  stato_consenso_marketing_profilazione: 'ACC' | 'NOACC',
 *  stato_consenso_marketing_contatto: 'ACC' | 'NOACC',
 * }} payload
 * @returns
 */
export async function crea_lead({
  dominio,
  email,
  cognome,
  nome,
  stato_consenso_marketing_profilazione,
  stato_consenso_marketing_contatto,
}) {
  try {
    /**
     * @type {import(':types').Lead}
     */
    const Contenuto = {
      CATAccountID: '',
      FlagPersonaFisica: 1,
      Cognome: cognome,
      Nome: nome,
      DataNascita: '',
      NazioneNascita: '',
      ProvinciaNascita: '',
      ComuneNascita: '',
      LocalitaNascitaCitta: '',
      NazioneResidenza: '',
      ProvinciaResidenza: '',
      LocalitaResidenzaCitta: '',
      CodiceFiscale: '',
      PartitaIVA: '',
      CAP: '',
      Indirizzo1: '',
      NumeroCivico: '',
      Indirizzo2: '',
      Telefono: '',
      TelefonoCellulare1: '',
      TelefonoCellulare2: '',
      Fax: '',
      EmailComunicazioni: email,
      NoteInterne: '',
      Sesso: '',
      TipoCattura: 'WebForm_Lead',
      StatoConsensoMarketingProfilazione: stato_consenso_marketing_profilazione,
      StatoConsensoMarketingContatto: stato_consenso_marketing_contatto,
      IsLead: 1,
    }

    /**
     * @type {import(':types').AxiosResponse<import(':types').RispostaSferaNet<{CATAccountID: string,ClienteID: string}>>}
     */
    const risposta = await axios.post(
      `${$store_url_sferanet}/CompFacileLogin/CRMLeadService.json`,
      JSON.stringify(Contenuto),
      {
        headers: {
          'codiceagenzia': dominio.Codice,
          'x-http-method-override': 'SaveLead',
          'Content-Type': 'application/json',
        },
      },
    )

    if (risposta.status >= 400) {
      store_messaggio.set({
        testo: `Non è stato possibile registrarsi (${risposta.status}).`,
      })
      return false
    }

    if (risposta.data.code == 302 && risposta.data.data.ClienteID == '') {
      store_messaggio.set({ testo: risposta.data.message, durata: 10000 })
      return false
    }

    if (202 === risposta.data.code) {
      store_messaggio.set({
        testo: `Esiste già un account registrato con questo indirizzo email.`,
      })
      return false
    }

    if (risposta.data.data.ClienteID != '') {
      return crea_account_login({
        dominio,
        cliente_id: risposta.data.data.ClienteID,
      })
    }

    return true
  } catch (errore) {
    store_messaggio.set({ testo: `${errore}` })
    return false
  }
}
