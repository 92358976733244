import axios from 'axios'
import { get } from 'svelte/store'
import { IOTNULL } from ':constants'
import { store_messaggio } from ':stores/store_messaggio'
import { store_url_sferanet } from ':stores/store_url_sferanet'

/**
 * Ottiene immagine profilo account in base 64
 * @param {{sessione:import(':types').Sessione,account_login_id: string}} payload
 */
export async function immagine_utente_base64({ sessione, account_login_id }) {
  const $store_url_sferanet = get(store_url_sferanet)
  /**
   * @type {import(':types').AxiosResponse<string>}
   */
  const response = await axios.post(
    `${$store_url_sferanet}/CompFacileLogin/FotoUtente.json`,
    JSON.stringify({ AccountLoginID: account_login_id }),
    {
      headers: {
        'codiceagenzia': sessione.Utente.CodiceAgenzia,
        'accesstoken': sessione.Utente.AccessToken,
        'x-http-method-override': 'GetFotoUtente',
        'content-type': 'application/json',
      },
    },
  )

  if (IOTNULL === response.data) {
    store_messaggio.set({ testo: IOTNULL })
    return btoa('')
  }

  if ('' === response.data) return btoa('')
  return response.data
}
