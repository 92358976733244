<style lang="scss">
  input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 0.3rem;
    transition: border-color 200ms;
    &:focus {
      border-color: rgba(0, 0, 0, 0.9);
    }
  }
</style>

<script>
  import { mdiCalendar } from '@mdi/js'
  import CampoDiTesto from ':components/campo-di-testo.svelte'
  import Espansione from ':components/espansione.svelte'
  import { createEventDispatcher } from 'svelte'
  import { IN_SVILUPPO } from ':constants'

  export let data_vendita = ['', '']
  export let data_partenza = ['', '']
  export let data_rientro = ['', '']
  export let data_creazione = ['', '']
  export let numero_allegati = [1, 100]
  export let destinazione = ''
  export let descrizione = ''

  const emit = createEventDispatcher()

  let filtri_attivi = {
    data_vendita: false,
    data_partenza: false,
    data_rientro: false,
    data_creazione: false,
    numero_allegati: false,
    destinazione: false,
    descrizione: false,
  }

  $: if (data_vendita[0] !== '' || data_vendita[1] !== '') {
    filtri_attivi.data_vendita = true
  } else {
    filtri_attivi.data_vendita = false
  }

  $: if (data_partenza[0] !== '' || data_partenza[1] !== '') {
    filtri_attivi.data_partenza = true
  } else {
    filtri_attivi.data_partenza = false
  }

  $: if (data_rientro[0] !== '' || data_rientro[1] !== '') {
    filtri_attivi.data_rientro = true
  } else {
    filtri_attivi.data_rientro = false
  }

  $: if (data_creazione[0] !== '' || data_creazione[1] !== '') {
    filtri_attivi.data_creazione = true
  } else {
    filtri_attivi.data_creazione = false
  }

  $: if (destinazione !== '') {
    filtri_attivi.destinazione = true
  } else {
    filtri_attivi.destinazione = false
  }

  $: if (descrizione !== '') {
    filtri_attivi.descrizione = true
  } else {
    filtri_attivi.descrizione = false
  }

  $: emit(
    'numero-filtri-attivi',
    Object.keys(filtri_attivi).filter(function pass(chiave) {
      // @ts-ignore
      return filtri_attivi[chiave]
    }).length,
  )
</script>

{#if IN_SVILUPPO}
  <Espansione icona={mdiCalendar} titolo="Numero allegati">
    <div class="grid justify-center">
      <small>Minimo {numero_allegati[0]}</small>
    </div>
    <input
      type="range"
      min="0"
      max="100"
      bind:value={numero_allegati[0]}
      class="range range-accent"
    />
    <div class="grid justify-center">
      <small>Massimo {numero_allegati[1]}</small>
    </div>
    <input
      type="range"
      min="0"
      max="100"
      bind:value={numero_allegati[1]}
      class="range range-accent"
    />
  </Espansione>
  <div class="pt-4" />
{/if}

<Espansione
  icona={mdiCalendar}
  titolo="Data vendita"
  sottotitolo={data_vendita[0] !== ''
    ? 'Attivo'
    : '' || data_vendita[1] !== ''
    ? 'Attivo'
    : ''}
  colore_sottotitolo="error"
>
  <CampoDiTesto
    data
    icona={mdiCalendar}
    indizio="Data vendita inizio"
    bind:valore={data_vendita[0]}
  />
  <CampoDiTesto
    data
    icona={mdiCalendar}
    indizio="Data vendita fine"
    bind:valore={data_vendita[1]}
  />
</Espansione>
<div class="pt-4" />
<Espansione
  icona={mdiCalendar}
  titolo="Data partenza"
  sottotitolo={data_partenza[0] !== ''
    ? 'Attivo'
    : '' || data_partenza[1] !== ''
    ? 'Attivo'
    : ''}
  colore_sottotitolo="error"
>
  <CampoDiTesto
    data
    icona={mdiCalendar}
    indizio="Data partenza inizio"
    bind:valore={data_partenza[0]}
  />
  <CampoDiTesto
    data
    icona={mdiCalendar}
    indizio="Data partenza fine"
    bind:valore={data_partenza[1]}
  />
</Espansione>
<div class="pt-4" />
<Espansione
  icona={mdiCalendar}
  titolo="Data rientro"
  sottotitolo={data_rientro[0] !== ''
    ? 'Attivo'
    : '' || data_rientro[1] !== ''
    ? 'Attivo'
    : ''}
  colore_sottotitolo="error"
>
  <CampoDiTesto
    data
    icona={mdiCalendar}
    indizio="Data rientro inizio"
    bind:valore={data_rientro[0]}
  />
  <CampoDiTesto
    data
    icona={mdiCalendar}
    indizio="Data rientro fine"
    bind:valore={data_rientro[1]}
  />
</Espansione>
<div class="pt-4" />
<Espansione
  icona={mdiCalendar}
  titolo="Data creazione"
  sottotitolo={data_creazione[0] !== ''
    ? 'Attivo'
    : '' || data_creazione[1] !== ''
    ? 'Attivo'
    : ''}
  colore_sottotitolo="error"
>
  <CampoDiTesto
    data
    icona={mdiCalendar}
    indizio="Data creazione inizio"
    bind:valore={data_creazione[0]}
  />
  <CampoDiTesto
    data
    icona={mdiCalendar}
    indizio="Data creazione fine"
    bind:valore={data_creazione[1]}
  />
</Espansione>
<div class="pt-4" />
<Espansione
  icona={mdiCalendar}
  titolo="Destinazione"
  sottotitolo={destinazione !== '' ? 'Attivo' : ''}
  colore_sottotitolo="error"
>
  <CampoDiTesto
    icona={mdiCalendar}
    indizio="Destinazione"
    bind:valore={destinazione}
  />
</Espansione>
<div class="pt-4" />
<Espansione
  icona={mdiCalendar}
  titolo="Descrizione"
  sottotitolo={descrizione !== '' ? 'Attivo' : ''}
  colore_sottotitolo="error"
>
  <CampoDiTesto
    icona={mdiCalendar}
    indizio="Descrizione"
    bind:valore={descrizione}
  />
</Espansione>
<div class="pt-4" />
