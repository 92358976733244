<script>
  import Accesso from ':components/accesso.svelte'
  import AppBarPrincipale from ':components/app-bar-principale.svelte'
  import Progresso from ':components/progresso.svelte'
  import Questionario from ':components/questionario.svelte'
  import Scrollabile from ':components/scrollabile.svelte'
  import { dominio } from ':scripts/dominio'
  /**
   * @type {string}
   */
  export let pratica_id
</script>

{#await dominio()}
  <Progresso globale />
{:then dominio}
  <Accesso {dominio}>
    <svelte:fragment let:sessione>
      <AppBarPrincipale titolo="Questionario utente" />
      <Scrollabile>
        <div style="padding-left:0.5rem;padding-right:0.5rem;">
          <Questionario
            {sessione}
            opzioni={{
              QuestionarioTestataID: '',
              ProfilazioneTestataID: '',
              ParentType: 'PRT_PraticaTestata',
              ParentID: pratica_id,
            }}
          />
        </div>
      </Scrollabile>
    </svelte:fragment>
  </Accesso>
{:catch errore}
  <div
    class="p-4 grid fixed h-full w-full justify-center content-center text-center"
  >
    {errore}
  </div>
{/await}
