import { get } from 'svelte/store'
import { store_url_facilews3 } from ':stores/store_url_facilews3'
import { store_otp } from ':stores/store_otp'
import { post } from ':scripts/http'
import { ok } from ':scripts/ok'
import { error } from ':scripts/error'

/**
 *
 * @param {import(':types').PayloadFirmaContratto} payload
 */
export async function firma_contratto({
  sessione,
  allegato,
  firme,
  mini_otp: miniOtp,
  cognome,
  nome,
}) {
  const $store_url_facilews3 = get(store_url_facilews3)
  const otp = get(store_otp)

  const URL = `${$store_url_facilews3}/Api/Rest/${sessione.Utente.CodiceAgenzia}/Contratto/FirmaMini?Token=${sessione.Utente.TokenFacileWS}&otp=${otp}&MiniOTP=${miniOtp}`

  /**
   * @type {import(':types').AxiosUnsafe<string>}
   */
  return await post({
    indirizzo: URL,
    dati: JSON.stringify({
      allegatoid: allegato.AllegatoID,
      firma1base64: firme[0] ? firme[0].ImmagineBase64 : '',
      firma2base64: firme[1] ? firme[1].ImmagineBase64 : '',
      firma3base64: firme[2] ? firme[2].ImmagineBase64 : '',
      firma4base64: firme[3] ? firme[3].ImmagineBase64 : '',
      firma5base64: firme[4] ? firme[4].ImmagineBase64 : '',
      firma1width: firme[0] ? firme[0].Larghezza : 0,
      firma2width: firme[1] ? firme[1].Larghezza : 0,
      firma3width: firme[2] ? firme[2].Larghezza : 0,
      firma4width: firme[3] ? firme[3].Larghezza : 0,
      firma5width: firme[4] ? firme[4].Larghezza : 0,
      firma1height: firme[0] ? firme[0].Altezza : 0,
      firma2height: firme[1] ? firme[1].Altezza : 0,
      firma3height: firme[2] ? firme[2].Altezza : 0,
      firma4height: firme[3] ? firme[3].Altezza : 0,
      firma5height: firme[4] ? firme[4].Altezza : 0,
      cognomefirma: cognome,
      nomefirma: nome,
    }),
    configurazione: {
      headers: {
        'content-type': 'application/json',
      },
    },
  })
}
