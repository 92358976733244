import { get } from 'svelte/store'
import { dayjs } from ':scripts/dayjs'
import { store_otp } from ':stores/store_otp'
import { store_url_facilews3 } from ':stores/store_url_facilews3'
import { error } from ':scripts/error'
import { ok } from ':scripts/ok'
import { post } from './http'

/**
 * Ottiene pratiche in base ad un Filtro.
 * @param {import(':types').PayloadPratiche} payload
 * @returns {Promise<import(':types').Unsafe<Array<import(':types').Pratica>>>}
 */
export async function pratiche({
  sessione,
  filtro,
  numero_righe_totali = 0,
  offset = 0,
  limite_righe = 10,
  recenti = false,
}) {
  const $store_url_facilews3 = get(store_url_facilews3)
  const $store_otp = get(store_otp)

  const [risposta, errore] = await post({
    indirizzo: `${$store_url_facilews3}/Api/Rest/${sessione.Utente.CodiceAgenzia}/Pratica/Find?OTP=${$store_otp}&Token=${sessione.Utente.TokenFacileWS}`,
    dati: {
      AccountLoginID: sessione?.Utente?.AccountLoginId ?? '',
      // AccountLoginID: '',
      NumeroRigheTotali: numero_righe_totali,
      Offset: offset,
      RowLimit: limite_righe,
      NumeroPratica: filtro.NumeroPratica,
      DataVenditaInizio: !filtro.DataVendita[0]
        ? ''
        : dayjs(filtro.DataVendita[0]).format('YYYYMMDD'),
      DataVenditaFine: !filtro.DataVendita[1]
        ? ''
        : dayjs(filtro.DataVendita[1]).format('YYYYMMDD'),
      DataPartenzaInizio: !filtro.DataPartenza[0]
        ? ''
        : dayjs(filtro.DataPartenza[0]).format('YYYYMMDD'),
      DataPartenzaFine: !filtro.DataPartenza[1]
        ? ''
        : dayjs(filtro.DataPartenza[1]).format('YYYYMMDD'),
      DataRientroInizio: !filtro.DataRientro[0]
        ? ''
        : dayjs(filtro.DataRientro[0]).format('YYYYMMDD'),
      DataRientroFine: !filtro.DataRientro[1]
        ? ''
        : dayjs(filtro.DataRientro[1]).format('YYYYMMDD'),
      DataCreazioneInizio: !filtro.DataCreazione[0]
        ? ''
        : dayjs(filtro.DataCreazione[0]).format('YYYYMMDD'),
      DataCreazioneFine: !filtro.DataCreazione[1]
        ? ''
        : dayjs(filtro.DataCreazione[1]).format('YYYYMMDD'),
      NumeroMinimoAllegati: filtro.NumeroAllegati[0],
      NumeroMassimoAllegati: filtro.NumeroAllegati[1],
      Destinazione: filtro.Destinazione,
      Descrizione: filtro.Descrizione,
      CognomeNomeCliente: filtro.CognomeNomeCliente,
      Recenti: recenti ? 1 : 0,
    },
  })

  if (errore) {
    if (404 === errore.status) {
      return error('Pratica non trovata.')
    }

    let link_temporaneo = $store_otp.length > 0
    if (risposta.status >= 400 && link_temporaneo) {
      return error(
        'Il Link di firma del contratto che si sta utilizzando è scaduto, richiederne uno nuovo.',
      )
    }

    return error('Errore sconosciuto.')
  }

  if (risposta.data.data) {
    for (var i in risposta.data.data) {
      if (risposta.data.data[i].ImmagineDiCopertina) {
        risposta.data.data[i].ImmagineDiCopertina = risposta.data.data[
          i
        ].ImmagineDiCopertina.replace(
          '{tokenUtente}',
          sessione.Utente.TokenFacileWS,
        )
      }
    }
  }

  return ok(risposta.data.data || [])
}
