<script>
  import Accesso from ':components/accesso.svelte'
  import AppBarPrincipale from ':components/app-bar-principale.svelte'
  import DestinazioniOfferte from ':components/destinazioni-offerte.svelte'
  import InEvidenza from ':components/in-evidenza.svelte'
  import ComponenteOfferte from ':components/offerte.svelte'
  import OfferteStagionali from ':components/offerte-stagionali.svelte'
  import PreventiviInEvidenzaComponente from ':components/preventivi-in-evidenza.svelte'
  import Progresso from ':components/progresso.svelte'
  import Scrollabile from ':components/scrollabile.svelte'
  import TagOfferte from ':components/tag-offerte.svelte'
  import UltimePartenze from ':components/ultime-partenze.svelte'
  import { dominio } from ':scripts/dominio'
  import { offerte } from ':scripts/offerte'
  import { preventivi } from ':scripts/preventivi'
</script>

{#await dominio()}
  <Progresso globale />
{:then dominio}
  <Accesso {dominio}>
    <svelte:fragment let:sessione>
      <AppBarPrincipale
        titolo="{sessione.Utente.CodiceAgenzia.toLocaleUpperCase()} - {sessione
          .Utente.Username}"
      />
      <Scrollabile let:larghezza>
        <div class="pt-4" />
        {#if sessione.Utente.VisualizzaHome}
          {#await Promise.all([preventivi({ sessione }), offerte()])}
            <!-- {#await new Promise(()=>{})} -->
            <Progresso globale />
          {:then [preventivi, pacchetto_offerte]}
            {#if preventivi.length !== 0}
              <div class="pt-8" />

              <PreventiviInEvidenzaComponente
                {larghezza}
                titolo="I tuoi preventivi"
                didascalia="Le proposte più adatte alle tue esigenze"
                {preventivi}
              />
            {/if}
            <div class="pt-8" />
            <UltimePartenze
              {sessione}
              titolo="Le tue vacanze"
              didascalia="I tuoi ultimi viaggi... pronti a ripartire?"
              {larghezza}
            />
            <div class="pt-8" />
            {#if pacchetto_offerte}
              <ComponenteOfferte
                titolo="Le nostre idee di viaggio"
                didascalia="Scopri le nostre idee di viaggio consigliate per te"
                offerte={pacchetto_offerte.Offerte}
                categoria_selezionata={pacchetto_offerte.NomiCategorie[0] ?? ''}
                categorie={pacchetto_offerte.Categorie}
                {larghezza}
              />
              <div class="pt-8" />
              <InEvidenza
                titolo="Offerte in evidenza"
                didascalia="Scelte per te!"
                offerte={pacchetto_offerte.Offerte}
              />
              <div class="pt-8" />
              <DestinazioniOfferte
                titolo="Le nostre destinazioni"
                didascalia="Scopri tutte le Offerte della tua destinazione preferita"
                offerte={pacchetto_offerte.Offerte}
                {larghezza}
              />
              <div class="pt-8" />
              <TagOfferte
                titolo="Cerca"
                didascalia="per area tematica"
                offerte={pacchetto_offerte.Offerte}
                {larghezza}
              />
              <div class="pt-8" />
              <OfferteStagionali
                titolo="Viaggia quando vuoi"
                didascalia="Esplora le nostre Offerte stagionali"
                offerte={pacchetto_offerte.Offerte}
                {larghezza}
              />
            {/if}
          {:catch error}
            <!-- Promise.all was rejected -->
          {/await}
        {:else}
          <div class="grid grid-x-center grid-y-center">Pagina non trovata</div>
        {/if}
      </Scrollabile>
    </svelte:fragment>
  </Accesso>
{:catch errore}
  <div
    class="p-4 grid fixed h-full w-full justify-center content-center text-center"
  >
    {errore}
  </div>
{/await}
